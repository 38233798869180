import { Injectable, Injector } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService, UserDetail } from './auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, filter } from 'rxjs/operators';

@Injectable()
export class AppInitializer {

    constructor(private injector: Injector) { }
    public Init() {
        const auth = this.injector.get(AngularFireAuth);
        const authService = this.injector.get(AuthService);
        const afs = this.injector.get(AngularFirestore);
        return new Promise<void>((resolve, _) => {
            auth.authState.subscribe(v => {
                if (v && v.uid) {
                    void afs.collection<UserDetail>('users')
                        .doc<UserDetail>(v.uid)
                        .get()
                        .pipe(
                            map(doc => doc.data() as UserDetail),
                            filter(info => !!info)
                        )
                        .toPromise()
                        .then(u => {
                            authService.userInfo$.next(u);
                            resolve();
                        });
                } else {
                    authService.userInfo$.next(undefined);
                    resolve();
                }
            });
        });
    }
}
