import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { publishReplay, refCount, map } from 'rxjs/operators';
import { Bondcoin } from '../models/Bondcoin';
import { Country } from '../models/Country';

@Injectable()
export class RefDataService {
    constructor(private httpClient: HttpClient) { }
    public getRefData(field: string) {
        return this.httpClient.get<(string | Bondcoin | Country)[]>(`api/reference/${field}`).pipe(
            map(res => res && res.sort(this.sortFunction)),
            publishReplay(1),
            refCount()
        );
    }
    private sortFunction = (a: string | Bondcoin | Country, b: string | Bondcoin | Country) => {
        const aVal = (typeof a === 'string') ? a : (!!a['Code'] ? a['Code'] : a['name']);
        const bVal = (typeof b === 'string') ? b : (!!b['Code'] ? b['Code'] : b['name']);
        if (aVal < bVal) {
            return -1;
        }
        if (aVal > bVal) {
            return 1;
        }
        return 0;
    };
}
