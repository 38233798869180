<div class="heading" fxLayout fxLayoutGap="16px">
    <img src="assets/image/svg/ilustration/feedback-boy.svg" fxShow.xs fxHide>
    <h1>Help Bonds without Borders® become your daily cash management app</h1>
</div>
<p class="lead">We need your precious feedback. Kindly answer or ask burning questions in less than 5 minutes.</p>
<p class="lead">Thank you!</p>
<div class="controls" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
    <a target="_blank" mat-raised-button color="accent" [href]="feedbackUrl">
        <mat-icon>assignment</mat-icon> Burning questions
    </a>
    <a *ngIf="showSkip" (click)="skip.next()" class="primary-color">Skip</a>
</div>