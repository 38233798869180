<div class="mb-20" [ngClass]="mainSpace()">
    <h1 class="tc mb-50">Settings</h1>
    <mat-tab-group [animationDuration]="0">
        <mat-tab>
            <ng-template mat-tab-label>
                Authorization info
            </ng-template>

            <div class="container--xs authorization-info tab-content">
                <!--<form [formGroup]="userTypeFormGroup" (ngSubmit)="onChangeUserType(userTypeFormGroup.value)">-->
                <!--<div class="mb-10"></div>-->
                <!--<div class="mb-10">-->
                <!--<mat-radio-group fxLayoutGap="30px" fxLayout="row wrap" formControlName="userType">-->
                <!--<mat-radio-button color="primary" value="Individuals">Individuals</mat-radio-button>-->
                <!--<mat-radio-button color="primary" value="Institutions">Institutions</mat-radio-button>-->
                <!--</mat-radio-group>-->
                <!--</div>-->
                <!--<button mat-button color="primary" type="submit">Save</button>-->

                <!--</form>-->
                <form [formGroup]="avatarFormGroup">
                    <mat-card class="mat-elevation-z0">

                        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" fxLayoutGap.gt-xs="30px">
                            <app-loader-foto *ngIf="avatarFormGroup.controls['avatar'].value" imageType="avatar"
                                nameDoc="avatar" (changeImageFile)="onChangeImage($event)"
                                [imageFile]="avatarFormGroup.controls['avatar'].value"></app-loader-foto>
                            <app-loader-foto *ngIf="!avatarFormGroup.controls['avatar'].value" imageType="avatar"
                                nameDoc="avatar" (changeImageFile)="onChangeImage($event)"></app-loader-foto>
                            <div class="mb-20" fxFlex="grow">
                                <p><strong>Tax ID 1 (where you reside)</strong></p>
                                <p>Take a picture of your tax ID proof (e.g. SS card or driver's license) and load it
                                    here.
                                </p>
                            </div>
                        </div>
                    </mat-card>
                </form>
                <form [formGroup]="userDetailsFormGroup">
                    <mat-card [ngClass]="{'card': !editBwbTag}">
                        <div *ngIf="!editBwbTag" class="mb-20">
                            <p class="txxs">$BwBTag</p>
                            <p><strong>{{userDetailsFormGroup.controls['bwbTag'].value}}</strong></p>
                            <div>
                                <a *ngIf="userDetailsFormGroup.controls['bwbTag'].value === ''"
                                    (click)="onChangeBwbTag()" class="primary-color" fxLayout="row"
                                    fxLayoutAlign="start center" fxFlexFill>
                                    <mat-icon matSuffix class="md-16 mr-5 fwb">add</mat-icon>
                                    <strong>Add</strong>
                                </a>
                                <!--                        <a *ngIf="settingsBwbTag !== ''"
                               (click)="onChangeBwbTag()"
                               class="primary-color">
                                <strong>Edit</strong>
                            </a>-->

                            </div>
                        </div>
                        <div *ngIf="editBwbTag">
                            <div class="mb-10">
                                <mat-form-field>
                                    <input matInput placeholder="Create $BwBTag " type="text" formControlName="bwbTag">
                                    <mat-error *ngIf="userDetailsFormGroup.controls['bwbTag'].hasError('pattern')">
                                        $BwBTag must contain letters and numbers
                                    </mat-error>
                                    <mat-error
                                        *ngIf="!userDetailsFormGroup.controls['bwbTag'].hasError('pattern') && userDetailsFormGroup.controls['bwbTag'].hasError('minlength')">
                                        Enter $BwBTag with combination of at least 3 characters
                                    </mat-error>
                                    <mat-error
                                        *ngIf="userDetailsFormGroup.controls['bwbTag'].hasError('bwbTagIsForbidden')">
                                        This $BwBTag is already existed
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutGap="4px">
                                <button mat-raised-button color="primary" type="button" (click)="changeBwbTag()"
                                    [disabled]="!userDetailsFormGroup.valid" class="mr-4">Change</button>
                                <button mat-button color="primary" type="button"
                                    (click)="onChangeBwbTag()">Cancel</button>
                            </div>
                        </div>

                        <div class="mb-20">
                            <mat-checkbox class="detail-checkbox mat-checkbox--sm" color="primary"
                                formControlName="showCountriesToFriends"
                                (change)="changeUserDetail('showCountriesToFriends')">
                                Show countries of investment to my paired friends
                            </mat-checkbox>
                            <mat-checkbox class="detail-checkbox mat-checkbox--sm" color="primary"
                                formControlName="showSurveyResultsToFriends"
                                (change)="changeUserDetail('showSurveyResultsToFriends')">
                                Show my answers to the monthly survey to my paired friends
                            </mat-checkbox>
                        </div>
                        <div class="mb-50" *ngIf="!editCurrency">
                            <p class="txxs">Base currency</p>
                            <p><strong>{{userDetailsFormGroup.controls['currency'].value}}</strong></p>
                            <div>
                                <a *ngIf="userDetailsFormGroup.controls['currency'].value !== ''"
                                    (click)="onChangeCurrency()" class="primary-color">
                                    <strong>Edit</strong>
                                </a>
                            </div>
                        </div>
                        <div class="mb-50" *ngIf="editCurrency">
                            <mat-form-field>
                                <mat-label>Base currency</mat-label>
                                <mat-select formControlName="currency" (selectionChange)="changeCurrency()">
                                    <mat-option *ngFor="let curr of currencies" [value]="curr">
                                        {{curr}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </mat-card>
                </form>
                <!--<button mat-button color="primary" type="button" (click)="onDelete()" >Delete</button>-->
            </div>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                1-Click Settings
            </ng-template>
            <div class="container--xs one-click-settings tab-content">
                <form [formGroup]="oneClickSettingsFormGroup">
                    <mat-slide-toggle color="primary" formControlName="tripleSInOneClick"
                        (change)="changeTripleSInOneClick()">
                        Activate Triple-S Solution in 1-Click
                    </mat-slide-toggle>
                </form>
            </div>
        </mat-tab>
    </mat-tab-group>

    <form [formGroup]="settingsFormGroup" *ngIf="!isProd" (ngSubmit)="onSubmit(settingsFormGroup.value)" class="hidden">
        <div class="container--xs">
            <div class="mb-10">
                <mat-form-field>
                    <input matInput placeholder="Email" formControlName="email">
                </mat-form-field>
                <button mat-raised-button color="primary" type="button" (click)="onUpdateEmail()">
                    Add email
                </button>
            </div>
            <div class="mb-10">
                <mat-form-field>
                    <input matInput placeholder="Phone" formControlName="phone">
                </mat-form-field>
                <button mat-raised-button color="primary" type="button" (click)="onUpdatePhoneNumber()">
                    Add Phone
                </button>
            </div>

            <div class="tc mb-10">
                <button mat-raised-button color="primary">
                    Create & save
                </button>
            </div>
        </div>
    </form>
    <div class="container--xs" class="hidden">
        <h1 class="tc">Add sin up Providers </h1>
        <form [formGroup]="emailProviderFormGroup" (ngSubmit)="onSubmitEmailProvider(emailProviderFormGroup.value)">
            <div class="mb-10">
                <mat-form-field>
                    <input matInput placeholder="Email" formControlName="email">
                </mat-form-field>
            </div>
            <div class="mb-10">
                <mat-form-field>
                    <input matInput placeholder="Password" formControlName="password">
                </mat-form-field>
            </div>
            <div class="tc mb-10">
                <button mat-raised-button color="primary" type="submit">
                    Add email Provider
                </button>
            </div>
        </form>
        <form [formGroup]="phoneProviderFormGroup"
            (ngSubmit)="tryRegister(phoneProviderFormGroup.controls['phone'].value)">
            <div class="mb-10">
                <mat-form-field>
                    <input matInput placeholder="Phone" formControlName="phone">
                    <mat-error *ngIf="phoneProviderFormGroup.controls['phone'].hasError('phone-error')">
                        {{errorMessage}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div #containerOfRecapcha class="mb-10"></div>

            <div class="tc mb-10">
                <button mat-raised-button color="primary" type="submit">
                    Add phone Provider
                </button>
            </div>
        </form>

        <form [formGroup]="addPhoneProviderFormGroup"
            (ngSubmit)="onSubmitPhoneProvider(addPhoneProviderFormGroup.value)">
            <mat-form-field>
                <input matInput placeholder="Code" type="text" formControlName="code">
                <!--<mat-error ngxErrors="firstFormGroup.code">-->
                <!--Enter a combination of at least 4 numbers ?????-->
                <!--</mat-error>-->
                <!--<mat-error *ngIf="addPhoneProviderFormGroup.controls['email'].hasError('email-error')">-->
                <!--{{errorMessage}}-->
                <!--</mat-error>-->
            </mat-form-field>
            <p class="mb-30 text-muted">
                <small>In a few seconds you will receive a unique code to your chosen identifier
                    (email
                    or phone number).
                </small>
            </p>
            <div class="tc mb-10">
                <button mat-raised-button color="primary" type="submit" [disabled]="!addPhoneProviderFormGroup.valid">
                    Activate account
                </button>
            </div>

        </form>
    </div>
</div>