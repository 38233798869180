<mat-sidenav-container>
    <mat-sidenav over autosize>
        <app-sidenav (toggle)="onToggle($event)"></app-sidenav>
    </mat-sidenav>

    <mat-sidenav-content>
        <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
        <div class="body-container" cdkScrollable [ngClass]="{'fxClass-gt-xs': !mobileQuery.matches}">
            <app-nav-bar (toggle)="onToggle($event)" (toggleSearch)="onToggleSearch($event)"
                [ngClass]="{'nav-sticky mat-elevation-z2': shrinkToolbar, 'fxClass-gt-xs': !mobileQuery.matches, 'nav-up': navUp}"
                [class.mat-elevation-header]="scrolled"></app-nav-bar>
            <app-header-search *ngIf="showSearch" class="mat-elevation-z2" (toggleSearch)="onToggleSearch($event)"
                [ngClass]="{'nav-sticky': shrinkToolbar, 'fxClass-gt-xs': !mobileQuery.matches, 'nav-up': navUp}"
                [@toggleState]></app-header-search>
            <div class="app-content">
                <router-outlet></router-outlet>
            </div>
            <app-footer></app-footer>
        </div>
        <feedback-button *ngIf="authService.allowedToView | async"></feedback-button>
    </mat-sidenav-content>
</mat-sidenav-container>