<div class="container">
    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="space-between center" fxFlexFill>
        <a class="logo" (click)="navigateToBasePage()">
            <img class="logo__img" src="assets/image/svg/bwb.svg" alt="">
            <span class="logo__text-holder">
                <span class="logo__text">BwB</span>
                <span class="logo__slogan">Bonds without Borders® Bondcoins®</span>
            </span>
        </a>

        <div fxFlex="auto">
            <div fxFlexFill fxLayout="row" fxLayoutAlign="end center">
                <nav *ngIf="authService.allowedToView | async" class="header-nav" fxFlex="auto" fxFlexFill
                    fxLayout="row" fxLayoutAlign="end center" fxHide fxShow.gt-sm>
                    <li class="header-nav__item">
                        <div routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                            class="header-nav__wrap">
                            <a class="header-nav__link" routerLink="/home">Home</a>
                        </div>
                    </li>
                    <li class="header-nav__item">
                        <div routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                            class="header-nav__wrap">
                            <a class="header-nav__link" routerLink="/trade-blotter">Trade Blotter</a>
                        </div>
                    </li>

                    <li class="header-nav__item">
                        <div routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                            class="header-nav__wrap">
                            <a class="header-nav__link" [matMenuTriggerFor]="cashManagementMenu">
                                <span>USD Concierge Cash <span class="no-wrap">Management <mat-icon class="md-16">
                                            keyboard_arrow_down
                                        </mat-icon></span></span>
                            </a>
                            <mat-menu #cashManagementMenu="matMenu" class="header-menu complex-menu"
                                [overlapTrigger]="false" xPosition="before">
                                <a mat-menu-item routerLink="/cash-management/standing-instructions"
                                    routerLinkActive="active">My Standing
                                    Instructions</a>
                                <a mat-menu-item routerLink="/cash-management/cash-and-more"
                                    routerLinkActive="active">My Cash and more</a>
                            </mat-menu>
                        </div>
                    </li>
                    <li class="header-nav__item">
                        <div routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                            class="header-nav__wrap">
                            <a class="header-nav__link" [matMenuTriggerFor]="solutionMenu">
                                <span>Triple-S <span class="no-wrap">Solution<mat-icon class="md-16">
                                            keyboard_arrow_down</mat-icon></span></span>
                            </a>
                            <mat-menu #solutionMenu="matMenu" class="header-menu complex-menu" [overlapTrigger]="false"
                                xPosition="before">
                                <div class="inner-menu" fxLayout="row" fxLayoutAlign="space-around start">
                                    <div class="sub-menu">
                                        <div>Saving</div>
                                        <ul class="sub-menu__items">
                                            <li *ngFor="let country of countries"><a mat-menu-item
                                                    routerLink="/trade-blotter"
                                                    [queryParams]="{ country: country.code }">{{country.name}}</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="sub-menu">
                                        <div>Sending</div>
                                        <a mat-menu-item routerLink="/triples/sending"
                                            routerLinkActive="active">$BwBTag</a>
                                    </div>
                                    <div class="sub-menu">
                                        <div>Spending</div>
                                        <a mat-menu-item routerLink="/triples/spending" routerLinkActive="active">Pay My
                                            Utility Bill</a>
                                        <a mat-menu-item routerLink="/triples/spending">Pay My Phone Bill</a>
                                        <a mat-menu-item routerLink="/triples/spending">Transfer to Amazon Cash</a>
                                        <a mat-menu-item routerLink="/triples/spending">Buy Frequent Flyers Miles</a>
                                    </div>
                                </div>
                            </mat-menu>
                        </div>
                    </li>
                    <li class="header-nav__item">

                        <!-- <a class="header-nav__link" routerLink="/friends" routerLinkActive="active">
                            Invite, search, scan Friends
                        </a> -->
                        <div routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                            class="header-nav__wrap">
                            <a class="header-nav__link" [matMenuTriggerFor]="friendsMenu">
                                <span>Invite, Search, Scan <span class="no-wrap">Friends<mat-icon class="md-16">
                                            keyboard_arrow_down</mat-icon></span></span>
                            </a>
                            <mat-menu #friendsMenu="matMenu" class="header-menu complex-menu" [overlapTrigger]="false"
                                xPosition="after">
                                <div class="inner-menu" fxLayout="row" fxLayoutAlign="space-around start">
                                    <div class="sub-menu">
                                        <div>Invite</div>
                                        <a mat-menu-item routerLink="/friends/invite"
                                            routerLinkActive="active">E-mail</a>
                                        <a mat-menu-item routerLink="/friends/invite">Phone
                                            Number</a>
                                    </div>
                                    <div class="sub-menu">
                                        <div>Search</div>
                                        <a mat-menu-item routerLink="/friends/search"
                                            routerLinkActive="active">E-mail</a>
                                        <a mat-menu-item routerLink="/friends/search">Phone Number</a>
                                        <a mat-menu-item routerLink="/friends/search">$BwBTag</a>
                                    </div>
                                    <div class="sub-menu">
                                        <div>Scan</div>
                                        <a mat-menu-item routerLink="/friends/scan" routerLinkActive="active">Pair Your
                                            Friend</a>
                                        <a mat-menu-item routerLink="/friends/scan">Message Your Friend</a>
                                    </div>
                                </div>
                            </mat-menu>
                        </div>
                    </li>
                    <li class="header-nav__item">
                        <div routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                            class="header-nav__wrap">
                            <a class="header-nav__link" [matMenuTriggerFor]="newsMenu">
                                <span>Global <span class="no-wrap">News<mat-icon class="md-16">keyboard_arrow_down
                                        </mat-icon></span></span>
                            </a>
                            <mat-menu #newsMenu="matMenu" class="header-menu" [overlapTrigger]="false"
                                xPosition="after">
                                <!-- <a mat-menu-item routerLink="/news/topeconomic" routerLinkActive="active">Top Global
                                    Economic
                                    News</a>
                                <a mat-menu-item routerLink="/news/topfinancial" routerLinkActive="active">Top Global
                                    Financial
                                    News</a>
                                <a mat-menu-item routerLink="/news/toppolitical" routerLinkActive="active">Top Global
                                    Political
                                    News</a> -->
                                <a mat-menu-item routerLink="/news/mostread" routerLinkActive="active">Most Read Global
                                    News</a>
                                <a mat-menu-item routerLink="/news/country/us" routerLinkActive="active">News By
                                    Country</a>
                            </mat-menu>
                        </div>
                    </li>
                    <li class="header-nav__item">
                        <div routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                            class="header-nav__wrap">
                            <a class="header-nav__link" [matMenuTriggerFor]="learnMenu">
                                <span>Learn <span class="no-wrap">More<mat-icon class="md-16">keyboard_arrow_down
                                        </mat-icon></span></span>
                            </a>
                            <mat-menu #learnMenu="matMenu" class="header-menu" [overlapTrigger]="false"
                                xPosition="before">
                                <a mat-menu-item routerLink="/pages/under-construction/Bonds 101"
                                    routerLinkActive="active">Bonds 101</a>
                                <a mat-menu-item [href]="surveyResultsUrl" target="_blank">What Other $BwBtags Think</a>
                                <a mat-menu-item routerLink="/pages/what-governments-do" routerLinkActive="active">What
                                    Governments Do with my Cash</a>
                            </mat-menu>
                        </div>
                    </li>
                    <li class="header-nav__item" fxHide fxShow.gt-md>
                        <div routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"
                            class="header-nav__wrap">
                            <a class="header-nav__link" routerLink="/pages/help">Get Help</a>
                        </div>
                    </li>
                </nav>

                <div *ngIf="isLoggedIn" fxHide fxShow.gt-sm>
                    <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between center">
                        <a [matMenuTriggerFor]="menu1" fxFlexFill fxLayout="row" fxLayoutAlign="start center">
                            <div *ngIf="(authService.getCurrentUser() | async)?.photoUrl" class="avatar mr-8"
                                fxFlex="32px">
                                <img [src]="(authService.getCurrentUser() | async)?.photoUrl">
                            </div>
                            <mat-icon *ngIf="!(authService.getCurrentUser() | async)?.photoUrl"
                                class="md-36 text-muted mr-8">account_circle</mat-icon>
                            <div class="primary-color nik">{{(authService.getCurrentUser() | async)?.name}}</div>
                            <mat-icon class="md-16 primary-color">keyboard_arrow_down</mat-icon>
                        </a>

                        <mat-menu #menu1="matMenu" class="header-menu" [overlapTrigger]="false" xPosition="after">
                            <a *ngIf="authService.allowedToView | async" mat-menu-item routerLink="/passport"
                                routerLinkActive="active">Your BwB Passport</a>
                            <a *ngIf="authService.allowedToView | async" mat-menu-item routerLink="/settings"
                                routerLinkActive="active">Settings</a>
                            <a mat-menu-item (click)="onLogOut()">Log Out</a>
                            <mat-divider *ngIf="authService.adminState | async"></mat-divider>
                            <a *ngIf="authService.adminState | async" mat-menu-item routerLink="/admin"
                                routerLinkActive="active">Manage Access</a>
                            <a *ngIf="authService.adminState | async" mat-menu-item routerLink="/trade-ticket"
                                routerLinkActive="active">Manage Trade Tickets</a>
                        </mat-menu>

                    </div>
                </div>
                <button *ngIf="!isLoggedIn" mat-raised-button routerLink="log-in" color="primary" class="btn-log_in"
                    fxHide fxShow.gt-sm>Log
                    In
                </button>

                <button fxHide.gt-sm mat-icon-button color="primary" (click)="onToggle()">
                    <mat-icon>menu</mat-icon>
                </button>
                <button *ngIf="authService.allowedToView | async" mat-icon-button color="primary"
                    (click)="onToggleSearch()">
                    <mat-icon>search</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>