import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import 'hammerjs';

if (environment.production) {
    enableProdMode();
    document.write('<script async src="https://www.googletagmanager.com/gtag/js?id=G-V6LLN4F1Wy"></script>');
}
if (window.location.pathname !== '/') {
    platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.log(err));
} else if (!document.body.classList.contains('coming-soon')) {
    document.body.classList.add('coming-soon');
}
