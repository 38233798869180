import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'standing-boy',
    templateUrl: './standing-boy.component.html',
    styleUrls: ['./standing-boy.component.scss']
})
export class StandingBoyComponent implements OnInit {
    public path: string;
    constructor() { }

    public ngOnInit() {
        this.path = window.location.pathname;
    }

}
