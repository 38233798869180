<div class="container" [ngClass]="mainSpace()">
    <!--*ngIf="!isLoggedIn"-->
    <div class="container--md">
        <div class="tc">
            <h1>Sign Up</h1>
            <p *ngIf="!isLoggedIn" class="lead mb-50">Already have an account?
                <strong><a routerLink="/log-in" class="primary-color">Log In</a></strong>
            </p>
        </div>

        <mat-horizontal-stepper #stepper class="mat-stepper-horizontal--lg" ngClass.gt-md="howSteps">

            <!-- 1 step ----------------------------------------------------------------------------->

            <mat-step>
                <ng-template matStepLabel>Create account</ng-template>
            </mat-step>

            <!-- 2 step ----------------------------------------------------------------------------->

            <mat-step>

                <ng-template matStepLabel>Verify electronic ID</ng-template>
                <div class="mb-20" *ngIf="isLoggedIn && !(authService.getCurrentUser() | async)?.emailVerified">
                    <div class="container--xs">
                        <h3 class="mb-30">Confirm your email address</h3>
                        <p class="mb-30"> Please confirm your email address by clicking the link which we
                            send to <strong>{{(authService.getCurrentUser() | async)?.email}}</strong></p>
                        <p class="mb-30">
                            If you have not received the confirmation email, check your spam folder or send
                            verification email again:
                        </p>
                        <mat-form-field>
                            <input matInput placeholder="Email" [value]="(authService.getCurrentUser() | async)?.email"
                                disabled>
                        </mat-form-field>
                        <div class="mb-10 tc">
                            <button mat-raised-button color="primary" (click)="onSendEmailVerification()">
                                Send verification email again
                            </button>
                        </div>
                    </div>
                </div>

                <div *ngIf="(authService.getCurrentUser() | async)?.emailVerified || !isLoggedIn">
                    <div class="container--xs">
                        <div *ngIf="isLoggedIn" ngClass.xs="tc">
                            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="start center"
                                fxLayoutGap="10px" class="mb-40">
                                <mat-icon class="success-color">check</mat-icon>
                                <h3 class="success-color mb-0"> Sign Up is completed!</h3>
                            </div>
                            <div fxLayout="column" fxLayout.gt-xs="row" class="mb-10">
                                <div fxFlex="50" class="mb-20">
                                    <p class="mb-20">See what BwB offers</p>
                                    <button mat-raised-button color="primary" routerLink="/trade-blotter">All
                                        bonds
                                    </button>
                                </div>
                                <div fxFlex="50" class="mb-20">
                                    <p class="mb-20">Start operation with bonds</p>
                                    <button mat-stroked-button color="primary" routerLink="/passport">Fill
                                        your BwB passport
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!isLoggedIn">
                            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="mb-40">
                                <mat-icon class="success-color">check</mat-icon>
                                <h3 class="success-color mb-0"> Sign Up is completed!</h3>
                            </div>
                            <div class="tc mb-10">
                                <button mat-raised-button color="primary" routerLink="/log-in"> Log In </button>
                            </div>
                        </div>

                    </div>
                </div>
            </mat-step>
            <ng-template matStepperIcon="edit" let-index="index"> {{index + 1}}</ng-template>
        </mat-horizontal-stepper>
    </div>
</div>