<div class="header ">
    <div fxFlexFill fxLayout="row" fxLayoutAlign="space-between center">
        <a class="logo" (click)="navigateToBasePage()">
            <img class="logo__img" src="assets/image/svg/bwb.svg" alt="">
            <span class="logo__text-holder">
                <span class="logo__text">BwB</span>
                <span class="logo__slogan">Bonds without Borders® Bondcoins®.</span>
            </span>
        </a>
        <button mat-icon-button color="primary" (click)="onToggle()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
</div>
<div class="user-block">
    <div *ngIf="isLoggedIn">
        <div fxLayout="row" fxLayoutGap="10px">
            <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="space-between center">
                <div *ngIf="(authService.getCurrentUser() | async)?.photoUrl" class="avatar mr-8" fxFlex="32px">
                    <img [src]="(authService.getCurrentUser() | async)?.photoUrl">
                </div>
                <mat-icon *ngIf="!(authService.getCurrentUser() | async)?.photoUrl" class="md-36 text-muted mr-8">
                    account_circle</mat-icon>
                <div class="primary-color">{{(authService.getCurrentUser() | async)?.name}}</div>
            </div>
            <div class="log_out">
                <a class="primary-color" (click)="onLogOut()"><strong>Log Out</strong></a>
            </div>
        </div>
    </div>
    <button *ngIf="!isLoggedIn" mat-raised-button routerLink="/log-in" (click)="onToggle()" color="primary">Log In
    </button>
</div>

<mat-nav-list *ngIf="authService.allowedToView | async">
    <a mat-list-item routerLink="/passport" class="header-nav__link" (click)="onToggle()">Your BwB Passport</a>
    <a mat-list-item routerLink="/settings" class="header-nav__link" (click)="onToggle()">Settings</a>
</mat-nav-list>
<mat-divider *ngIf="authService.adminState | async"></mat-divider>
<mat-nav-list *ngIf="authService.adminState | async">
    <a mat-list-item routerLink="/admin" (click)="onToggle()">Manage Access</a>
    <a mat-list-item routerLink="/trade-ticket" routerLinkActive="active" (click)="onToggle()">Manage Trade
        Tickets</a>
</mat-nav-list>
<mat-divider *ngIf="authService.allowedToView | async"></mat-divider>
<mat-nav-list *ngIf="authService.allowedToView | async">
    <a mat-list-item class="header-nav__link" routerLink="/home" routerLinkActive="active" (click)="onToggle()">Home</a>
    <a mat-list-item class="header-nav__link" routerLink="/trade-blotter" routerLinkActive="active"
        (click)="onToggle()">Trade
        Blotter</a>
    <a mat-list-item class="header-nav__link" (click)="onShowCCM()">
        USD Concierge Cash Management <mat-icon>{{showCCM ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
    </a>
    <div class="pl-20" *ngIf="showCCM">
        <a mat-list-item class="header-nav__link" routerLink="/cash-management/standing-instructions"
            routerLinkActive="active" (click)="onToggle()">My Standing Instructions</a>
        <a mat-list-item class="header-nav__link" routerLink="/cash-management/cash-and-more" routerLinkActive="active"
            (click)="onToggle()">My Cash and more</a>
    </div>
    <a mat-list-item class="header-nav__link" (click)="showSolutionMenu = !showSolutionMenu">Triple-S Solution
        <mat-icon>{{showSolutionMenu ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
    </a>
    <div class="pl-20" style="max-height: 250px; overflow: auto;" *ngIf="showSolutionMenu">
        <div class="sub-heading" (click)="showSolutionSavingMenu = !showSolutionSavingMenu">Saving
            <mat-icon>{{showSolutionSavingMenu ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
        </div>
        <div class="sub-list" *ngIf="showSolutionSavingMenu">
            <a *ngFor="let country of countries" mat-list-item class="header-nav__link" routerLink="/trade-blotter"
                [queryParams]="{ country: country.code }" (click)="onToggle()">{{country.name}}</a>
        </div>
        <div class="sub-heading" (click)="showSolutionSendingMenu = !showSolutionSendingMenu">Sending
            <mat-icon>{{showSolutionSendingMenu ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
        </div>
        <div class="sub-list" *ngIf="showSolutionSendingMenu">
            <a mat-list-item class="header-nav__link" routerLink="/triples/sending" class="header-nav__link"
                routerLinkActive="active" (click)="onToggle()">$BwBTag</a>
        </div>
        <div class="sub-heading" (click)="showSolutionSpendingMenu = !showSolutionSpendingMenu">Spending
            <mat-icon>{{showSolutionSpendingMenu ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
        </div>
        <div class="sub-list" *ngIf="showSolutionSpendingMenu">
            <a mat-list-item class="header-nav__link" routerLink="/triples/spending" routerLinkActive="active"
                (click)="onToggle()">Pay My
                Utility Bill</a>
            <a mat-list-item class="header-nav__link" routerLink="/triples/spending" (click)="onToggle()">Pay My
                Phone
                Bill</a>
            <a mat-list-item class="header-nav__link" routerLink="/triples/spending" (click)="onToggle()">Transfer
                to
                Amazon
                Cash</a>
            <a mat-list-item class="header-nav__link" routerLink="/triples/spending" (click)="onToggle()">Buy
                Frequent Flyers Miles</a>
        </div>
    </div>
    <!-- <a mat-list-item class="header-nav__link" routerLink="/friends" routerLinkActive="active"
        (click)="onToggle()">Invite, search, scan Friends
    </a> -->
    <a mat-list-item class="header-nav__link" (click)="showFriendMenu = !showFriendMenu">Invite, Search, Scan
        Friends
        <mat-icon>{{showFriendMenu ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
    </a>
    <div class="pl-20" *ngIf="showFriendMenu">
        <!-- <div class="pl-20" *ngIf="false"> -->
        <div class="sub-heading" (click)="showFriendInviteMenu = !showFriendInviteMenu">Invite
            <mat-icon>{{showFriendInviteMenu ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
        </div>
        <div class="sub-list" *ngIf="showFriendInviteMenu">
            <a mat-list-item class="header-nav__link" routerLink="/friends/invite" routerLinkActive="active"
                (click)="onToggle()">E-mail</a>
            <a mat-list-item class="header-nav__link" routerLink="/friends/invite" routerLinkActive="active"
                (click)="onToggle()">Phone Number</a>
        </div>

        <div class="sub-heading" (click)="showFriendSearchMenu = !showFriendSearchMenu">Search
            <mat-icon>{{showFriendSearchMenu ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
        </div>
        <div class="sub-list" *ngIf="showFriendSearchMenu">
            <a mat-list-item class="header-nav__link" routerLink="/friends/search" routerLinkActive="active"
                (click)="onToggle()">E-mail</a>
            <a mat-list-item class="header-nav__link" routerLink="/friends/search" routerLinkActive="active"
                (click)="onToggle()">Phone Number</a>
            <a mat-list-item class="header-nav__link" routerLink="/friends/search" routerLinkActive="active"
                (click)="onToggle()">$BwBTag</a>
        </div>
        <div class="sub-heading" (click)="showFriendScanMenu = !showFriendScanMenu">Scan
            <mat-icon>{{showFriendScanMenu ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
        </div>
        <div class="sub-list" *ngIf="showFriendScanMenu">
            <a mat-list-item class="header-nav__link" routerLink="/friends/scan" routerLinkActive="active"
                (click)="onToggle()">Pair Your Friend</a>
            <a mat-list-item class="header-nav__link" routerLink="/friends/scan" routerLinkActive="active"
                (click)="onToggle()">Message Your Friend</a>
        </div>
    </div>
    <a mat-list-item class="header-nav__link" (click)="onShowNews()">
        Global News <mat-icon>{{showNews ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
    </a>
    <div class="pl-20" *ngIf="showNews">
        <!-- <a mat-list-item class="header-nav__link" routerLink="/news/topeconomic" routerLinkActive="active"
            (click)="onToggle()">Top
            Global Economic News</a>
        <a mat-list-item class="header-nav__link" routerLink="/news/topfinancial" routerLinkActive="active"
            (click)="onToggle()">Top
            Global Financial News</a>
        <a mat-list-item class="header-nav__link" routerLink="/news/toppolitical" routerLinkActive="active"
            (click)="onToggle()">Top
            Global Political News</a> -->
        <a mat-list-item class="header-nav__link" routerLink="/news/mostread" routerLinkActive="active"
            (click)="onToggle()">Most Read Global
            News</a>
        <a mat-list-item class="header-nav__link" routerLink="/news/country/us" routerLinkActive="active"
            (click)="onToggle()">News By Country</a>
    </div>
    <a mat-list-item class="header-nav__link" (click)="onShowLearn()">
        Learn More <mat-icon>{{showLearn ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}</mat-icon>
    </a>
    <div class="pl-20" *ngIf="showLearn">
        <a mat-list-item class="header-nav__link" routerLink="/pages/under-construction/Bonds 101"
            routerLinkActive="active" (click)="onToggle()">Bonds 101</a>
        <a mat-list-item class="header-nav__link" [href]="surveyResultsUrl" target="_blank" (click)="onToggle()">
            What Other $BwBtaggers Think</a>
        <a mat-list-item class="header-nav__link" routerLink="/pages/what-governments-do" routerLinkActive="active"
            (click)="onToggle()">What Governments Do with my Cash</a>
    </div>
    <a mat-list-item class="header-nav__link" routerLink="/pages/help" routerLinkActive="active"
        (click)="onToggle()">Get Help</a>
    <a mat-list-item class="header-nav__link" routerLink="/pages/risks-warning" routerLinkActive="active"
        (click)="onToggle()">Risks Warning</a>
</mat-nav-list>
<div class="powered-by">
    <span>Powered by</span>
    <a href="https://www.compatibl.com/" target="_blank"><img src="assets/image/svg/logo-compatibl-colored.svg"
            alt="Compatibl" title="Compatibl" /></a>
</div>