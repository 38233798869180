import { Observable, Subject } from 'rxjs';
import { OverlayRef } from '@angular/cdk/overlay';
import { PopoverComponent } from './popover/popover.component';

export class PopoverOverlayRef {
    public componentInstance: PopoverComponent;

    private beforeCloseSubject = new Subject<any>();
    private afterClosedSubject = new Subject<any>();


    constructor(private overlayRef: OverlayRef) {
    }

    public close(data?: any): void {
        this.overlayRef.dispose();
        this.afterClosedSubject.next(data);
        this.afterClosedSubject.complete();

        this.componentInstance = null;
    }

    public afterClosed(): Observable<any> {
        return this.afterClosedSubject.asObservable();
    }

    public beforeClose(): Observable<any> {
        return this.beforeCloseSubject.asObservable();
    }

}
