<div class="container--md" [ngClass]="mainSpace()">
    <div class="container--xs">

        <div class="tc">
            <h1> Log In</h1>
            <p class="lead">Don’t have an account? <strong><a routerLink="/sign-up" class="primary-color">Sign Up
                    </a></strong></p>
        </div>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm.value)" [class.hide]="showCode">
            <div class="pt-30 mb-10">
                <mat-form-field>
                    <input formControlName="email" matInput placeholder="Enter email or phone (+112345678901)"
                        (keydown)="onValueChange()">
                    <mat-error
                        *ngIf="loginForm.controls['email'].hasError('required') || loginForm.controls['email'].hasError('minlength')">
                        Enter email or phone number
                    </mat-error>
                    <mat-error *ngIf="loginForm.controls['email'].hasError('email')">
                        Correct email address: email@example.com
                    </mat-error>
                    <mat-error *ngIf="loginForm.controls['email'].hasError('forgot-password')">
                        You should provide your email to reset the password
                    </mat-error>
                    <mat-error *ngIf="loginForm.controls['email'].hasError('email-error')">
                        {{errorMessage}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div #containerOfRecapcha class="mb-20"></div>

            <div class="mb-10" *ngIf="!isPhone">
                <mat-form-field class="mat-form-field-flex-24">
                    <input formControlName="password" matInput placeholder="Password"
                        [type]="hide ? 'password' : 'text'">
                    <mat-icon matSuffix (click)="hide = !hide" color="primary" class="cp">
                        {{hide ? 'visibility' : 'visibility_off'}}
                    </mat-icon>
                    <mat-error *ngIf="loginForm.controls['password'].hasError('required')">
                        Enter a password
                    </mat-error>
                    <mat-error *ngIf="loginForm.controls['password'].hasError('email-password')">
                        {{errorMessage}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="mb-20">
                <mat-checkbox color="primary" formControlName="remember"><strong class="primary-color">Remember
                        me</strong>
                </mat-checkbox>
            </div>
            <div fxLayoutGap="30px" fxLayout="column" fxFlexFill fxLayoutAlign="center center">
                <button [disabled]="!loginForm.valid || !(recaptchaValid | async)" mat-raised-button color="primary"
                    type="submit">
                    Log In
                </button>
                <p><strong><a class="primary-color" [attr.disabled]="resetLinkSent ? true : null"
                            (click)="forgotPassword()">Forgot
                            password</a></strong></p>
                <p class="success-color" *ngIf="resetLinkSent">The reset link was sent to the provided email address.
                </p>
            </div>
        </form>
        <form *ngIf="showCode" [formGroup]="codeForm" (ngSubmit)="onSubmitCode(codeForm.value)">
            <p class="tc text-muted mb-30">The code was sent to the number {{loginForm.controls['email'].value}} </p>
            <mat-form-field class="mb-30">
                <input formControlName="code" matInput placeholder="Code">
                <mat-error
                    *ngIf="codeForm.controls['code'].hasError('required') || codeForm.controls['code'].hasError('minlength')">
                    Enter code
                </mat-error>
                <mat-error *ngIf="codeForm.controls['code'].hasError('code-error')">
                    {{errorMessage}}
                </mat-error>
                <mat-hint>
                    In a few seconds you will receive a unique code to your chosen identifier (email or phone number).
                </mat-hint>
            </mat-form-field>
            <div class="tc mb-30">
                <button mat-raised-button color="primary" type="submit" [disabled]="!codeForm.valid">
                    Log In
                </button>
            </div>
            <div class="tc">
                <a class="primary-color" (click)="onToggleShowCode()">
                    <strong>Back and try again</strong>
                </a>
            </div>
        </form>
    </div>
</div>