import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../services/auth.service';
import { Country } from '../../../trade-blotter/models/Country';
import { RefDataLookupService } from '../../../trade-blotter/services/ref-data-lookup.service';
import { Subject } from 'rxjs';
import { debounceTime, withLatestFrom } from 'rxjs/operators';

@Component({
    selector: 'app-nav-bar',
    templateUrl: './nav-bar.component.html',
    styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit {
    @Output() public readonly toggle: EventEmitter<any> = new EventEmitter();
    @Output() public readonly toggleSearch: EventEmitter<any> = new EventEmitter();
    public surveyResultsUrl = environment.surveyResultsUrl;
    public active: boolean;
    public isLoggedIn = false;
    public isProd: boolean;
    public countries: Country[];
    private navigateToBasePage$ = new Subject<void>();
    constructor(public authService: AuthService, private router: Router, private refDataService: RefDataLookupService) { }

    public ngOnInit() {
        this.authService.loginState.subscribe(v => (this.isLoggedIn = v));

        this.isProd = environment.production;

        if (this.isProd) {
            console.log('Prod');
        } else {
            console.log('Dev');
        }
        this.refDataService.getDataSource('CountryOfRisk').subscribe(countries => this.countries = countries);

        this.navigateToBasePage$
            .pipe(
                debounceTime(300),
                withLatestFrom(this.authService.allowedToView)
            ).subscribe(([_, v]) => {
                window.location.href = '/';
                if (v) {
                    // this.router.navigate(['/']);
                } else {
                    // window.location.href = '/';
                }
            });
    }

    public onToggle() {
        this.toggle.emit();
    }

    public onToggleSearch() {
        this.toggleSearch.emit();
    }

    public onLogOut() {
        this.authService.logOut().then(() => {
            setTimeout(() => {
                this.router.navigate(['/log-in']);
            });
        });
    }
    public navigateToBasePage() {
        this.navigateToBasePage$.next();
    }
}
