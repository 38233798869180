import { Component, NgZone, ViewChild, AfterViewInit, OnInit, ViewChildren } from '@angular/core';
import { CdkScrollable, ScrollDispatcher } from '@angular/cdk/scrolling';
import { WindowService } from './services/window.service';

import { NavigationEnd, Router, NavigationStart, NavigationCancel } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SharedService } from './modules/shared/shared.service';
import { PreloadFont } from './modules/shared/components/font-preload/PreloadFont';
import { RouterOutletService } from './services/router-outlet.service';
import { AuthService } from './services/auth.service';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        trigger('toggleState', [
            state('true', style({ transform: 'translateY(0)' })),
            state('false', style({ transform: 'translateY(-100%)' })),
            transition('void => *', [style({ transform: 'translateY(-100%)' }), animate(400)]),
            transition('* => void', animate(400, style({ transform: 'translateY(-100%)' })))
        ])
    ]
})
export class AppComponent implements AfterViewInit, OnInit {
    @ViewChild(MatSidenav) public snav: MatSidenav;
    @ViewChildren('header') public header;
    @ViewChild(CdkScrollable) public scrollable: CdkScrollable;

    public loading = false;
    public navUp = false;
    public SHRINK_TOP_SCROLL_POSITION = 10;
    public momentTop;
    public shrinkToolbar = false;
    public scrolled = false;
    public showSearch = false;
    public showHeader = true;
    public mobileQuery;
    public fonts: PreloadFont[] = [
        {
            fontFamily: 'Lato',
            fontWeight: 400
        },
        {
            fontFamily: 'Lato',
            fontWeight: 700
        },
        {
            fontFamily: 'Yantramanav',
            fontWeight: 300
        },
        {
            fontFamily: 'Yantramanav',
            fontWeight: 400
        },
        {
            fontFamily: 'Yantramanav',
            fontWeight: 600
        },
        {
            fontFamily: 'Yantramanav',
            fontWeight: 700
        },
        {
            fontFamily: 'Material Icons',
            fontWeight: 400
        }
    ];

    constructor(
        private sharedService: SharedService,
        private scrollDispatcher: ScrollDispatcher,
        private ngZone: NgZone,
        private router: Router,
        public authService: AuthService,
        private win: WindowService,
        routingState: RouterOutletService
    ) {
        routingState.loadRouting();
    }

    public ngOnInit() {
        this.mobileQuery = this.sharedService.mobileQuery;

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.win.windowRef.scrollTo(0, 0);
            }
        });

        this.momentTop = this.win.windowRef.pageYOffset;

        this.scrollDispatcher.scrolled().subscribe(() => {
            this.shrinkToolbar = this.win.windowRef.pageYOffset > this.SHRINK_TOP_SCROLL_POSITION;
            if (this.mobileQuery.matches) {
                if (
                    this.momentTop < this.win.windowRef.pageYOffset &&
                    this.win.windowRef.pageYOffset > 60
                ) {
                    this.navUp = true;
                } else if (this.momentTop >= this.win.windowRef.pageYOffset) {
                    this.navUp = false;
                }
                this.momentTop = this.win.windowRef.pageYOffset;
            }
        });

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.scrolled = event.urlAfterRedirects !== '/home';
            }
        });
    }

    public onToggle(_) {
        this.snav.toggle();
    }

    public ngAfterViewInit() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.loading = true;
            } else if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
                this.loading = false;
            }
        });
    }

    public onToggleSearch(_) {
        if (this.showHeader) {
            this.showHeader = !this.showHeader;
            setTimeout(() => {
                this.showSearch = !this.showSearch;
            }, 400);
        } else {
            this.showSearch = !this.showSearch;
            setTimeout(() => {
                this.showHeader = !this.showHeader;
            }, 400);
        }
    }
}
