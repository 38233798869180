import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';
import { SharedService } from '../../shared.service';

@Component({
    selector: 'app-detail-info',
    templateUrl: './detail-info.component.html',
    styleUrls: ['./detail-info.component.scss']
})
export class DetailInfoComponent implements OnInit {
    public selectedUserType;
    public isLoggedIn = false;

    constructor(
        private authService: AuthService,
        private sharedService: SharedService,
    ) { }

    public ngOnInit() {
        this.authService.loginState.subscribe(v => {
            this.isLoggedIn = v;

            if (v) {
                this.authService.getCurrentUserDetails().subscribe(userInfo => {
                    this.selectedUserType = userInfo.userType;
                });
            }
        });
    }

    public mainSpace() {
        return this.sharedService.mainSpace();
    }
}
