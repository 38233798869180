import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Country } from 'src/app/modules/trade-blotter/models/Country';
import { RefDataLookupService } from 'src/app/modules/trade-blotter/services/ref-data-lookup.service';
import { debounceTime, withLatestFrom } from 'rxjs/operators';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
    @Output() public readonly toggle: EventEmitter<any> = new EventEmitter();
    public surveyResultsUrl = environment.surveyResultsUrl;
    public myAccountLinkActive = false;
    public showDetail = false;
    public showNews = false;
    public showCCM = false;
    public showLearn = false;
    public showFriendMenu = false;
    public showFriendScanMenu = false;
    public showFriendSearchMenu = false;
    public showFriendInviteMenu = false;
    public showSolutionMenu = false;
    public showSolutionSavingMenu = false;
    public showSolutionSendingMenu = false;
    public showSolutionSpendingMenu = false;
    public isLoggedIn = false;
    public countries: Country[];
    private navigateToBasePage$ = new Subject<void>();
    constructor(public authService: AuthService, private router: Router, private refDataService: RefDataLookupService) { }

    public ngOnInit() {
        this.authService.loginState.subscribe(v => (this.isLoggedIn = v));
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.myAccountLinkActive = event.urlAfterRedirects === '/sign-up' || event.urlAfterRedirects === '/passport';
            }
        });
        this.refDataService.getDataSource('CountryOfRisk').subscribe(countries => this.countries = countries);
        this.navigateToBasePage$
            .pipe(
                debounceTime(300),
                withLatestFrom(this.authService.allowedToView)
            ).subscribe(([_, v]) => {
                if (v) {
                    this.router.navigate(['/']);
                } else {
                    window.location.href = '/';
                }
            });
    }

    public onToggle() {
        this.toggle.emit();
    }

    public onLogOut() {
        this.authService.logOut().then(() => {
            this.router.navigate(['/log-in']);
        });
    }

    public onShowNews() {
        this.showNews = !this.showNews;
    }

    public onShowDetail() {
        this.showDetail = !this.showDetail;
    }
    public onShowLearn() {
        this.showLearn = !this.showLearn;
    }
    public onShowCCM() {
        this.showCCM = !this.showCCM;
    }

    public navigateToBasePage() {
        this.navigateToBasePage$.next();
        this.onToggle();
    }
    /*  onRouterLink(item) {
      this.toggle.emit();
      this.router.navigate([item]);
    }*/
}
