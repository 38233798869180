import { NgModule, APP_INITIALIZER } from '@angular/core';
import { RouterModule, Routes, UrlSerializer } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { LowerCaseUrlSerializer } from './services/lower-case-url-serializer ';

// own resources
import { AppComponent } from './app.component';
import { SharedModule } from './modules/shared/shared.module';
import { RepositoryService } from './services/repository.service';
import { RefDataService } from './modules/trade-blotter/services/ref-data.service';
import { RefDataLookupService } from './modules/trade-blotter/services/ref-data-lookup.service';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AppInitializer } from './services/app.initializer';
import { AuthService } from './services/auth.service';
import { CommonModule } from '@angular/common';
import { IsUserApprovedGuard } from './services/is-user-approved.guard';
import { DisActiveteLoginGuard } from './services/dis-activete-login.guard';
import { IsUserAdminGuard } from './services/is-user-admin.guard';
import { CanActivateNotAllowedGuard } from './services/can-activate-not-allowed.guard';
export function initializeApp(appInitService: AppInitializer) {
    return (): Promise<any> => {
        return appInitService.Init();
    };
}

const routes: Routes = [
    {
        path: '',
        canActivateChild: [IsUserApprovedGuard, DisActiveteLoginGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: '/home'
            },
            {
                path: 'trade-blotter',
                loadChildren: () => import('./modules/trade-blotter/trade-blotter.module').then(m => m.TradeBlotterModule)
            },
            {
                path: 'friends',
                loadChildren: () => import('./modules/friends/friends.module').then(m => m.FriendsModule)
            },
            {
                path: 'triples',
                loadChildren: () => import('./modules/triples/triples.module').then(m => m.TriplesModule)
            },
            { path: 'passport', loadChildren: () => import('./modules/shared/shared.module').then(m => m.SharedModule) },
            { path: 'cash-management', loadChildren: () => import('./modules/cash-management/cash-management.module').then(m => m.CashManagementModule) },
            { path: 'settings', loadChildren: () => import('./modules/shared/shared.module').then(m => m.SharedModule) },

            { path: 'ui-kit', loadChildren: () => import('./modules/ui-kit/ui-kit.module').then(m => m.UiKitModule) },
            {
                path: 'news',
                loadChildren: () => import('./modules/news/news.module').then(m => m.NewsModule)
            },
            { path: 'home', loadChildren: () => import('./modules/home-page/home-page.module').then(m => m.HomePageModule) },
            { path: 'email-verified', loadChildren: () => import('./modules/shared/shared.module').then(m => m.SharedModule) },
            { path: 'log-in', loadChildren: () => import('./modules/shared/shared.module').then(m => m.SharedModule) },
            { path: 'trade-ticket', loadChildren: () => import('./modules/trade-ticket/trade-ticket.module').then(m => m.TradeTicketModule) },
            { path: 'sign-up', loadChildren: () => import('./modules/shared/shared.module').then(m => m.SharedModule) },
        ]
    },
    {
        path: 'not-allowed',
        loadChildren: () => import('./modules/not-allowed/not-allowed.module').then(m => m.NotAllowedModule),
        canActivate: [CanActivateNotAllowedGuard, DisActiveteLoginGuard]
    },
    { path: 'admin', loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule), canActivate: [IsUserAdminGuard] },
    {
        path: 'pages',
        loadChildren: () => import('./modules/pages/pages.module').then(m => m.PagesModule)
    },
    { path: 'errors', loadChildren: () => import('./modules/errors/errors.module').then(m => m.ErrorsModule) },
    { path: '**', redirectTo: '/errors', pathMatch: 'full' }
];

@NgModule({
    declarations: [AppComponent],
    imports: [
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireDatabaseModule,
        AngularFireStorageModule,
        AngularFireAuthModule,
        CommonModule,
        HttpClientModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(routes),
        SharedModule,
        ScrollingModule,
        ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production })
    ],
    exports: [ScrollingModule],
    providers: [
        AuthService,
        AppInitializer,
        RepositoryService, RefDataService, RefDataLookupService,
        {
            provide: UrlSerializer,
            useClass: LowerCaseUrlSerializer
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppInitializer],
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

}
