import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'feedback-dialog',
    templateUrl: './feedback-dialog.component.html',
    styleUrls: ['./feedback-dialog.component.scss']
})
export class FeedbackDialogComponent implements OnInit {

    constructor(
        private dialogRef: MatDialogRef<FeedbackDialogComponent>
    ) { }

    public ngOnInit() {
    }
    public skip() {
        this.dialogRef.close();
    }
}
