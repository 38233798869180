import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CanActiveDetailInfoGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }

    public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
        return this.authService.getCurrentUser()
            .pipe(
                map(user => {
                    if (!user.emailVerified && !user.phoneNumber) {
                        this.router.navigate(['/email-verified']);
                        return false;
                    } else {
                        return true;
                    }
                })
            );
    }
}
