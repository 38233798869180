import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild, CanActivate } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class IsUserApprovedGuard implements CanActivateChild, CanActivate {
    constructor(private authService: AuthService, private router: Router) { }
    public canActivate(
        _: ActivatedRouteSnapshot,
        _1: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.handleAllowedTrigger();
    }
    public canActivateChild(
        _: ActivatedRouteSnapshot,
        _1: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.handleAllowedTrigger();
    }
    private handleAllowedTrigger() {
        return combineLatest([this.authService.approvedState, this.authService.partialAccessState])
            .pipe(
                map(([approved, partialAccess]) => {
                    if (!approved && !partialAccess) {
                        void this.router.navigate(['/not-allowed']);
                        return false;
                    } else {
                        return true;
                    }
                })
            );
    }
}
