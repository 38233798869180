<div class="container--lg">
    <div class="container--sm" ngClass.gt-sm="tc">
        <h2>Your BwB Passport</h2>
        <p class="lead primary-color" ngClass="mb-20" ngClass.gt-sm="mb-10">
            To start playing with BwB, fill in Personal and Financial info
        </p>
    </div>
</div>
<div fxShow fxHide.gt-sm class="pt-20 bg-white-smoke mb-20">
    <div class="container--sm">
        <h6>Step {{stepper.selectedIndex+1}} of 2</h6>
        <h3 *ngIf="stepper.selectedIndex === 0">Personal info</h3>
        <h3 *ngIf="stepper.selectedIndex === 1">Financial info</h3>
    </div>
</div>
<div class="container">
    <div fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex.gt-md="550px">
            <mat-horizontal-stepper #stepper class="mat-stepper-horizontal--lg" ngClass.gt-sm="howSteps">
                <!-- 1 step ----------------------------------------------------------------------------->
                <mat-step [stepControl]="firstFormGroup">
                    <form [formGroup]="firstFormGroup">
                        <ng-template matStepLabel>Personal info</ng-template>
                        <div class="container--sm">
                            <div class="mb-10">
                                <mat-form-field>
                                    <input matInput placeholder="First name" formControlName="name">
                                    <mat-error ngxErrors="firstFormGroup.name">
                                        Enter First name
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="mb-10">
                                <mat-form-field>
                                    <input matInput placeholder="Last name" formControlName="nameLast">
                                    <mat-error ngxErrors="firstFormGroup.nameLast">
                                        Enter Last name
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="mb-10">
                                <datepicker-overview formControlName="dateBirth"
                                    (changeDateBirth)="onChangeDateBirth($event)"
                                    [dataError]="firstFormGroup.controls['dateBirth'].hasError('required')">
                                </datepicker-overview>
                            </div>
                            <div class="mb-50">
                                <mat-checkbox color="primary" formControlName="citizenUS"><strong>I am a US
                                        citizen</strong>
                                </mat-checkbox>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" fxLayoutGap.gt-xs="30px"
                                class="mb-30">
                                <app-loader-foto *ngIf="firstFormGroup.controls['passport'].value" imageType="doc"
                                    nameDoc="doc2" (changeImageFile)="onChangePassport($event)"
                                    [imageFile]="firstFormGroup.controls['passport'].value"></app-loader-foto>
                                <app-loader-foto *ngIf="!firstFormGroup.controls['passport'].value" imageType="doc"
                                    nameDoc="doc2" (changeImageFile)="onChangePassport($event)"></app-loader-foto>
                                <div class="mb-20" fxFlex="grow">
                                    <p><strong>Passport</strong></p>
                                    <p>Take a picture or a scan of the main passport page, including the signature.</p>
                                </div>
                            </div>
                            <div class="mb-30">
                                <div fxLayout="row" fxLayoutGap="10px" fxFlexFill>
                                    <h5>Tax ID</h5>
                                    <div #popoverAncer (mouseleave)="closePopover($event)"
                                        (mouseenter)="openPopoverPanel('BwB needs your tax ID to offer you the most comprehensive triple S-Solution (Save, Send or Spend), apply the appropriate bilateral tax treaties on your global savings and protect all $BwBTags\' cash. E.g. Tax ID 1 for a US-resident saver is his or her Social Security Number', popoverAncer, $event)"
                                        class="cp">
                                        <mat-icon class="light-gray-color">info</mat-icon>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" fxLayoutGap.gt-xs="30px">
                                <app-loader-foto *ngIf="firstFormGroup.controls['imageFile1'].value" imageType="doc"
                                    nameDoc="doc1" (changeImageFile)="onChangeImage1($event)"
                                    [imageFile]="firstFormGroup.controls['imageFile1'].value"></app-loader-foto>
                                <app-loader-foto *ngIf="!firstFormGroup.controls['imageFile1'].value" imageType="doc"
                                    nameDoc="doc1" (changeImageFile)="onChangeImage1($event)"></app-loader-foto>
                                <div class="mb-10" fxFlex="grow">
                                    <div class="mb-10">
                                        <p><strong>Tax ID 1 (where you reside)</strong></p>
                                        <p>Take a picture of your tax ID proof (e.g. SS card or driver's license) and
                                            load
                                            it
                                            here.
                                            If residing in the UAE, add EID card front and back scans.
                                        </p>
                                        <mat-form-field>
                                            <input matInput placeholder="Tax ID number"
                                                formControlName="imageFile1TaxId">
                                        </mat-form-field>
                                        <mat-form-field>
                                            <mat-select placeholder="Country of tax residence"
                                                formControlName="imageFile1Country">
                                                <mat-option *ngFor="let country of countries" [value]="country.name">
                                                    {{ country.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" fxLayoutGap.gt-xs="30px">
                                <app-loader-foto *ngIf="firstFormGroup.controls['imageFile2'].value" imageType="doc"
                                    nameDoc="doc2" (changeImageFile)="onChangeImage2($event)"
                                    [imageFile]="firstFormGroup.controls['imageFile2'].value"></app-loader-foto>
                                <app-loader-foto *ngIf="!firstFormGroup.controls['imageFile2'].value" imageType="doc"
                                    nameDoc="doc2" (changeImageFile)="onChangeImage2($event)"></app-loader-foto>
                                <div class="mb-20" fxFlex="grow">
                                    <p class="secondary-color">Optional</p>
                                    <p><strong>Tax ID 2 (in case you have another nationality, which might give you even
                                            more
                                            access
                                            to BwB cash capabilities)</strong></p>
                                    <p>Take a picture of your second tax ID proof and load it here.
                                        If residing in the UAE, add EID card front and back scans.</p>
                                    <mat-form-field>
                                        <input matInput placeholder="Tax ID number" formControlName="imageFile2TaxId">
                                    </mat-form-field>
                                    <mat-form-field>
                                        <mat-select placeholder="Country of tax residence"
                                            formControlName="imageFile2Country">
                                            <mat-option *ngFor="let country of countries" [value]="country.name">
                                                {{ country.name }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <h5>Mailing address</h5>

                            <div class="mb-10">
                                <mat-form-field>
                                    <input matInput placeholder="Street address" formControlName="street">
                                    <mat-error ngxErrors="firstFormGroup.street">
                                        Enter Street address
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="mb-10">
                                <mat-form-field>
                                    <input matInput placeholder="City" formControlName="city">
                                    <mat-error ngxErrors="firstFormGroup.city">
                                        Enter City
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="mb-50">
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexFill>
                                    <mat-form-field fxFlex>
                                        <mat-select placeholder="State" formControlName="state">
                                            <mat-option *ngFor="let state of states" [value]="state.value">
                                                {{ state.value }}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error ngxErrors="firstFormGroup.state">
                                            Choose State
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="70px">
                                        <input matInput placeholder="Zip Code" type="text" formControlName="zipCode">
                                        <mat-error ngxErrors="firstFormGroup.zipCode">
                                            Enter Zip Code
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <p class="secondary-color">Optional</p>
                            <div class="mb-50">
                                <div>
                                    <mat-checkbox color="primary" formControlName="addSaver"
                                        (change)="toggleAddSaver(firstFormGroup.controls['addSaver'].value)"><strong>I
                                            would like to add a joint BwB saver to my
                                            account
                                        </strong></mat-checkbox>
                                </div>
                                <div fxLayout="row wrap" fxFlexFill>
                                    <mat-form-field fxFlexOffset="28px">
                                        <input matInput placeholder="Email or phone" type="text"
                                            formControlName="addPhoneOrEmail">
                                        <mat-error
                                            *ngIf="firstFormGroup.controls['addPhoneOrEmail'].hasError('validAddPhoneOrEmail')">
                                            Enter email or phone
                                        </mat-error>
                                    </mat-form-field>
                                    <div fxFlexOffset="28px" class="text-muted">
                                        <small>Provide email or phone number of the joint BwB saver and (s)he will
                                            receive a
                                            link to create or confirm the BwB saver joint account.
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="tc mb-10">
                                <button mat-raised-button color="primary" [disabled]="!firstFormGroup.valid"
                                    (click)="addDetail(firstFormGroup.value)">Go to Financial
                                    Info
                                </button>
                            </div>
                        </div>
                    </form>
                </mat-step>

                <!-- 2 step ----------------------------------------------------------------------------->

                <mat-step [stepControl]="secondFormGroup">
                    <form [formGroup]="secondFormGroup">
                        <ng-template matStepLabel>Financial info</ng-template>
                        <div class="container--sm">
                            <div class="mb-10">
                                <mat-radio-group fxLayout="row" fxLayoutGap="20px" formControlName="isBank">
                                    <mat-radio-button color="primary" [value]="true">Specify your bank
                                    </mat-radio-button>
                                    <mat-radio-button color="primary" [value]="false">
                                        <div fxLayoutGap="5px" fxLayout="row"><span style="white-space: nowrap;">Let BwB
                                                create an account for
                                                you</span><span #popoverAncerBank (mouseleave)="closePopover($event)"
                                                (mouseenter)="openPopoverPanel('BwB create a set of such twin (cash and securities custody) accounts in your base currency', popoverAncerBank, $event)"
                                                class="cp">
                                                <mat-icon class="light-gray-color">info</mat-icon>
                                            </span>
                                        </div>

                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <ng-container *ngIf="IsBank">
                                <div class="mb-50">
                                    <mat-form-field>
                                        <input matInput placeholder="Bank name" formControlName="bankName">
                                        <mat-error ngxErrors="secondFormGroup.bankName">
                                            Enter Bank name
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <h5>Bank physical adress</h5>

                                <div class="mb-10">
                                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexFill>
                                        <mat-form-field>
                                            <input matInput placeholder="Street" formControlName="bankStreet">
                                            <mat-error ngxErrors="secondFormGroup.bankStreet">
                                                Enter Street address
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field fxFlex="75px">
                                            <input matInput placeholder="Number" type="text"
                                                formControlName="bankStreetNumber">
                                            <mat-error ngxErrors="secondFormGroup.bankStreetNumber">
                                                Enter Street Number
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="mb-10">
                                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexFill>
                                        <mat-form-field>
                                            <input matInput placeholder="City" formControlName="bankCity">
                                            <mat-error ngxErrors="secondFormGroup.bankCity">
                                                Enter City
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field fxFlex="75px">
                                            <input matInput placeholder="Post Code" type="text"
                                                formControlName="bankPostCode">
                                            <mat-error ngxErrors="secondFormGroup.bankPostCode">
                                                Enter Post Code
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="mb-50">
                                    <mat-form-field>
                                        <mat-select placeholder="Country" formControlName="bankCountry">
                                            <mat-option *ngFor="let country of countries" [value]="country.name">
                                                {{ country.name }}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error ngxErrors="secondFormGroup.bankCountry">
                                            Choose Country
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="mb-10">
                                    <mat-form-field>
                                        <input matInput placeholder="IBAN cash account number"
                                            formControlName="bankIBANCash">
                                        <mat-error ngxErrors="secondFormGroup.bankIBANCash">
                                            Enter IBAN cash account number
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="mb-50">
                                    <mat-form-field>
                                        <input matInput placeholder="IBAN securities custody account number"
                                            formControlName="bankIBANCustody">
                                        <mat-error ngxErrors="secondFormGroup.bankIBANCustody">
                                            Enter IBAN securities custody account number
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </ng-container>
                            <mat-form-field>
                                <mat-select placeholder="What is your Base Currency" formControlName="currency">
                                    <mat-option *ngFor="let currency of currencies" [value]="currency">
                                        {{ currency }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="text-muted mb-40">
                                <small>
                                    Your Base Currency is the original currency associated with the means of payment
                                    specified above. For example, if you entered a US credit card info above, your Base
                                    Currency is the USD, that is the US Dollar.
                                </small>
                            </div>
                            <div class=" mb-30">
                                <mat-checkbox color="primary" formControlName="agreement"><strong>
                                        I declare that all the information I have provided on all pages of this
                                        application is true,
                                        complete and accurate to the best of my knowledge. I understand that
                                        misrepresentations or incorrect information provided would result in a rejection
                                        of my BwB user
                                        creation application or would expose myself to potential legal proceedings in
                                        the future. I date and sign here accordingly.
                                    </strong></mat-checkbox>
                            </div>
                            <div fxLayout.xs="column" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign.xs="center"
                                fxLayoutAlign="space-between center" class="mb-10">
                                <button mat-stroked-button color="primary" (click)="onStep(0)">Back to Personal
                                    info
                                </button>
                                <button mat-raised-button color="primary" [disabled]="!secondFormGroup.valid"
                                    (click)="onSubmitStep2(secondFormGroup.value)">Start Trading</button>
                            </div>
                        </div>
                    </form>
                </mat-step>
                <ng-template matStepperIcon="edit" let-index="index"> {{index + 1}}</ng-template>
            </mat-horizontal-stepper>
        </div>
    </div>
</div>