/* eslint-disable @typescript-eslint/camelcase */
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from '../../shared.service';
import { AuthService, Institutions } from '../../../../services/auth.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { STATES } from '../../models/State';
import { PopoverComponent } from '../popover-view/popover/popover.component';
import { PopoverOverlayRef } from '../popover-view/popover-view-ref';
import { PopoverViewService } from '../popover-view/popover-view.service';
import { StartTradingDialogComponent } from '../start-trading-dialog/start-trading-dialog.component';
import { RefDataLookupService } from '../../../trade-blotter/services/ref-data-lookup.service';
import { requiredIfValidator, updateTreeValidity } from '../individuals/individuals.component';
import { Country } from '../../../trade-blotter/models/Country';
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
    selector: 'app-institutions',
    templateUrl: './institutions.component.html',
    styleUrls: ['./institutions.component.scss']
})
export class InstitutionsComponent implements OnInit {
    @ViewChild('stepper') public stepper: MatStepper;
    @ViewChild('steppersm') public stepperSm: MatStepper;
    public currencies: string[];
    public countries: Country[];

    public firstFormGroup: FormGroup;
    public submitAddSaver: BehaviorSubject<string> = new BehaviorSubject('1');

    public secondFormGroup: FormGroup;

    public thirdFormGroup: FormGroup;

    public fourthFormGroup: FormGroup;

    public submitIndSaver: BehaviorSubject<string> = new BehaviorSubject('1');

    public fifthFormGroup: FormGroup;
    public states = STATES;

    public dialogRef: PopoverOverlayRef = null;
    public get IsBank() {
        return this.fifthFormGroup && this.fifthFormGroup.get('isBank').value;
    }
    private previewState = true;

    constructor(
        private formBuilder: FormBuilder,
        private sharedService: SharedService,
        private authService: AuthService,
        private refDataService: RefDataLookupService,
        private router: Router,
        private popoverService: PopoverViewService,
        private angularFireFunctions: AngularFireFunctions,
        private dialog: MatDialog
    ) { }

    public ngOnInit() {
        this.refDataService.getDataSource('Currency').subscribe(currencies => this.currencies = currencies);
        this.refDataService.getDataSource('CountryOfRisk')
            .subscribe(countries => this.countries = countries);
        this.firstFormGroup = this.formBuilder.group({
            name: ['', Validators.required],
            street: ['', Validators.required],
            state: ['', Validators.required],
            city: ['', Validators.required],
            zipCode: ['', [Validators.required, Validators.minLength(4)]],
            tax: ['', Validators.required],
            key: ['', [Validators.pattern(/^[0-9]*$/), Validators.minLength(8)],
                this.publickeyIsForbidden.bind(this)]
        });
        this.secondFormGroup = this.formBuilder.group({
            options: ['0'],
            addOptions: ['0'],
            addPhoneOrEmail: ['', Validators.required]
        });
        this.thirdFormGroup = this.formBuilder.group({
            options3: ['1'],
            addOptions1: false,
            addOptions2: false,
            addOptions3: false,
            imageFile: ['']
        });
        this.fourthFormGroup = this.formBuilder.group({
            indNameFirst: ['', Validators.required],
            indNameLast: ['', Validators.required],
            indTitle: ['', Validators.required],
            indDateBirth: [null, Validators.required],
            indStreet: ['', Validators.required],
            indState: ['', Validators.required],
            indCity: ['', Validators.required],
            indZipCode: ['', [Validators.required, Validators.minLength(4)]],
            indImageFile: [''],
            indOptions: ['1'],
            extraRepresentative: [false],
            extraNameFirst: ['', [this.validateRepresentative.bind(this)]],
            extraNameLast: ['', [this.validateRepresentative.bind(this)]],
            extraFunction: ['', [this.validateRepresentative.bind(this)]],
            extraPhoneOrEmail: ['', [this.validateRepresentative.bind(this)]]
        });
        this.fifthFormGroup = this.formBuilder.group({
            isBank: [true],
            bankName: ['', [requiredIfValidator(() => this.fifthFormGroup.get('isBank').value)]],
            bankStreet: ['', [requiredIfValidator(() => this.fifthFormGroup.get('isBank').value)]],
            bankStreetNumber: ['', [requiredIfValidator(() => this.fifthFormGroup.get('isBank').value)]],
            bankCity: ['', [requiredIfValidator(() => this.fifthFormGroup.get('isBank').value)]],
            bankCountry: ['', [requiredIfValidator(() => this.fifthFormGroup.get('isBank').value)]],
            bankPostCode: ['', [requiredIfValidator(() => this.fifthFormGroup.get('isBank').value)]],
            bankIBANCash: ['', [requiredIfValidator(() => this.fifthFormGroup.get('isBank').value)]],
            bankIBANCustody: ['', [requiredIfValidator(() => this.fifthFormGroup.get('isBank').value)]],
            currency: ['']
        });
        this.authService.loginState
            .pipe(
                filter(v => v),
                switchMap(_ => this.authService.getCurrentUserDetails()),
                map(userInfo => userInfo as Institutions)
            )
            .subscribe(userInfo => {
                this.firstFormGroup.controls['name'].setValue(userInfo.name);
                this.firstFormGroup.controls['street'].setValue(userInfo.street);
                this.firstFormGroup.controls['state'].setValue(userInfo.state);
                this.firstFormGroup.controls['city'].setValue(userInfo.city);
                this.firstFormGroup.controls['zipCode'].setValue(userInfo.zipCode);
                this.firstFormGroup.controls['tax'].setValue(userInfo.tax);
                if (userInfo.key) {
                    this.firstFormGroup.controls['key'].setValue(userInfo.key);
                }

                this.secondFormGroup.controls['options'].setValue(userInfo.options);
                this.secondFormGroup.controls['addOptions'].setValue(userInfo.addOptions);
                this.secondFormGroup.controls['addPhoneOrEmail'].setValue(userInfo.addPhoneOrEmail);
                this.submitAddSaver.next(userInfo.addOptions);

                this.thirdFormGroup.controls['options3'].setValue(userInfo.options3);
                this.thirdFormGroup.controls['addOptions1'].setValue(userInfo.addOptions1);
                this.thirdFormGroup.controls['addOptions2'].setValue(userInfo.addOptions2);
                this.thirdFormGroup.controls['addOptions3'].setValue(userInfo.addOptions3);
                this.thirdFormGroup.controls['imageFile'].setValue(userInfo.imageFile);

                this.fourthFormGroup.controls['indNameFirst'].setValue(userInfo.indNameFirst);
                this.fourthFormGroup.controls['indNameLast'].setValue(userInfo.indNameLast);
                this.fourthFormGroup.controls['indTitle'].setValue(userInfo.indTitle);

                if (userInfo.indDateBirth) {
                    this.fourthFormGroup.controls['indDateBirth'].setValue(
                        userInfo.indDateBirth.toDate()
                    );
                }

                this.fourthFormGroup.controls['indStreet'].setValue(userInfo.indStreet);
                this.fourthFormGroup.controls['indState'].setValue(userInfo.indState);
                this.fourthFormGroup.controls['indCity'].setValue(userInfo.indCity);
                this.fourthFormGroup.controls['indZipCode'].setValue(userInfo.indZipCode);
                this.fourthFormGroup.controls['indImageFile'].setValue(userInfo.indImageFile);
                this.fourthFormGroup.controls['indOptions'].setValue(userInfo.indOptions);
                this.fourthFormGroup.controls['extraRepresentative'].setValue(userInfo.extraRepresentative);
                this.fourthFormGroup.controls['extraNameFirst'].setValue(userInfo.extraNameFirst);
                this.fourthFormGroup.controls['extraNameLast'].setValue(userInfo.extraNameLast);
                this.fourthFormGroup.controls['extraFunction'].setValue(userInfo.extraFunction);
                this.fourthFormGroup.controls['extraPhoneOrEmail'].setValue(
                    userInfo.extraPhoneOrEmail
                );
                this.fifthFormGroup.controls['currency'].setValue(userInfo.currency);
                this.fifthFormGroup.controls['isBank'].setValue(userInfo.isBank === undefined ? true : userInfo.isBank);
                if (userInfo.bankName) {
                    this.fifthFormGroup.controls['bankName'].setValue(userInfo.bankName);
                }
                if (userInfo.bankStreet) {
                    this.fifthFormGroup.controls['bankStreet'].setValue(userInfo.bankStreet);
                }
                if (userInfo.bankStreetNumber) {
                    this.fifthFormGroup.controls['bankStreetNumber'].setValue(userInfo.bankStreetNumber);
                }
                if (userInfo.bankCity) {
                    this.fifthFormGroup.controls['bankCity'].setValue(userInfo.bankCity);
                }
                if (userInfo.bankCountry) {
                    this.fifthFormGroup.controls['bankCountry'].setValue(userInfo.bankCountry);
                }
                if (userInfo.bankPostCode) {
                    this.fifthFormGroup.controls['bankPostCode'].setValue(userInfo.bankPostCode);
                }
                if (userInfo.bankIBANCash) {
                    this.fifthFormGroup.controls['bankIBANCash'].setValue(userInfo.bankIBANCash);
                }
                if (userInfo.bankIBANCustody) {
                    this.fifthFormGroup.controls['bankIBANCustody'].setValue(userInfo.bankIBANCustody);
                }
                this.previewState = userInfo.firstPreview;
            });

        this.submitAddSaver.subscribe(v => {
            if (v === '0') {
                this.secondFormGroup.controls['addPhoneOrEmail'].disable();
            } else {
                this.secondFormGroup.controls['addPhoneOrEmail'].enable();
            }
        });

        this.fifthFormGroup.get('isBank').valueChanges
            .subscribe(_ => {
                updateTreeValidity(this.fifthFormGroup);
            });
    }
    public publickeyIsForbidden(control: FormControl): Promise<any> | Observable<any> {
        const promise = new Promise<any>((resolve, _) => {
            // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
            const keyLength = control.value + '';
            if (keyLength.length === 9 || keyLength.length > 10) {
                resolve({ 'keyIsForbidden': true });
            } else {
                resolve(null);
            }
        });
        return promise;
    }

    public onStepSm(item) {
        this.sharedService.scrollToTop();
        this.stepperSm.selectedIndex = item;
    }

    public onStep(item) {
        this.sharedService.scrollToTop();
        this.stepper.selectedIndex = item;
    }

    public onSubmitStep1(data) {
        this.onStepSm(1);
        const item = {
            name: data.name,
            street: data.street,
            state: data.state,
            city: data.city,
            zipCode: data.zipCode,
            tax: data.tax,
            key: data.key || '',
            partialAccess: true
        };
        this.authService.changeUserDetail(item);
    }

    public onSubmitStep1_2(data, step) {
        this.onStepSm(step);
        const item = {
            options: data.options,
            addOptions: data.addOptions,
            addPhoneOrEmail: data.addPhoneOrEmail || '',
            partialAccess: true
        };
        this.authService.changeUserDetail(item);
    }

    public onSubmitStep1_3(data, step) {
        if (step === 1) {
            this.onStep(1);
        } else {
            this.onStepSm(1);
        }
        const item = {
            options3: data.options3 || '',
            addOptions1: data.addOptions1 || false,
            addOptions2: data.addOptions2 || false,
            addOptions3: data.addOptions3 || false,
            imageFile: data.imageFile || '',
            partialAccess: true
        };
        this.authService.changeUserDetail(item);
    }

    public onSubmitStep2(data, step) {
        this.onStepSm(1);
        if (step === 2) {
            this.onStep(2);
        } else {
            this.onStep(0);
            this.onStepSm(2);
        }

        if (this.fourthFormGroup.valid) {
            const item = {
                indNameFirst: data.indNameFirst,
                indNameLast: data.indNameLast,
                indTitle: data.indTitle,
                indDateBirth: data.indDateBirth,
                indStreet: data.indStreet,
                indState: data.indState,
                indCity: data.indCity,
                indZipCode: data.indZipCode,
                indImageFile: data.indImageFile || '',
                indOptions: data.indOptions || '',
                extraRepresentative: data.extraRepresentative || false,
                extraNameFirst: data.extraNameFirst || '',
                extraNameLast: data.extraNameLast || '',
                extraFunction: data.extraFunction || '',
                extraPhoneOrEmail: data.extraPhoneOrEmail || '',
                partialAccess: true
            };
            this.authService.changeUserDetail(item);
        }
    }

    public onSubmitStep3(data) {
        if (!this.previewState) {
            const dialogRef = this.dialog.open(StartTradingDialogComponent, { width: '700px', disableClose: true });
            dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
                if (result) {
                    this.submitUserData(data);
                }
            });
        } else {
            this.submitUserData(data);
        }
    }

    public onChangeIndDoc(item) {
        this.thirdFormGroup.controls['imageFile'].setValue(item);
    }

    public onChangeIndImage(item) {
        this.fourthFormGroup.controls['indImageFile'].setValue(item);
    }

    public onChangeDateBirth(item) {
        this.fourthFormGroup.controls['indDateBirth'].setValue(item);
    }

    public toggleAddOptions(i) {
        this.submitAddSaver.next(i);
    }

    public toggleIndOptions(i) {
        this.submitIndSaver.next(i);
    }

    public openPopoverPanel(data, popoverAnker, _) {
        if (!this.dialogRef) {
            this.dialogRef = this.popoverService.openPopoverPanel(popoverAnker,
                {
                    text: data
                }, PopoverComponent);
        }
    }

    public closePopover(_) {
        if (this.dialogRef) {
            this.dialogRef.close();
            this.dialogRef = null;
        }
    }

    private submitUserData(data: any) {
        const detail = {
            firstPreview: true,
            currency: data.currency,
            isBank: data.isBank,
            bankName: data.bankName,
            bankStreet: data.bankStreet,
            bankStreetNumber: data.bankStreetNumber,
            bankCity: data.bankCity,
            bankCountry: data.bankCountry,
            bankPostCode: data.bankPostCode,
            bankIBANCash: data.bankIBANCash,
            bankIBANCustody: data.bankIBANCustody,
            partialAccess: true
        };
        this.authService.changeUserDetail(detail);
        this.router.navigate(['/trade-blotter']);
        const userUid = this.authService.currentUser?.value?.uid;
        if (userUid) {
            const func = this.angularFireFunctions.httpsCallable('sendNotificationAboutUserPassport');
            func({ userId: this.authService.currentUser.value.uid }).subscribe(r => console.log(r));
        }
    }
    private validateRepresentative(_1: FormGroup) {
        return (_: AbstractControl): { [key: string]: any } | null => {
            const extraRepresentative = this.fourthFormGroup.get('extraRepresentative').value;
            return extraRepresentative ? { isRequired: true } : null;
        };
    }
}
