import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from '../material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TextMaskModule } from 'angular2-text-mask';
import { ChartModule, SparklineModule, StockChartModule } from '@progress/kendo-angular-charts';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { GridModule } from '@progress/kendo-angular-grid';


// own resources
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { LogInComponent } from './components/log-in/log-in.component';
import { IndividualsComponent } from './components/individuals/individuals.component';
import { InstitutionsComponent } from './components/institutions/institutions.component';
import { UserNavComponent } from './components/user-nav/user-nav.component';
import { DetailInfoComponent } from './components/detail-info/detail-info.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { HeaderSearchComponent } from './components/header-search/header-search.component';
import { SearchComponent } from './components/search/search.component';
import { LoaderFotoComponent } from './components/loader-foto/loader-foto.component';
import { FontPreloadComponent } from './components/font-preload/font-preload.component';
import { SpinnerDirective } from './components/spinner/spinner.directive';
import { AngularFirestore } from '@angular/fire/firestore';
import { SettingsComponent } from './components/settings/settings.component';
import { PrelooderPhotoComponent } from './components/loader-foto/prelooder-photo/prelooder-photo.component';
import { EmailVerifiedComponent } from './components/email-verified/email-verified.component';
import { DatepickerOverviewComponent } from './components/datepicker-overview/datepicker-overview.component';
import { CanActivateLoginGuard } from '../../services/can-activate-login.guard';
import { DisActiveteLoginGuard } from '../../services/dis-activete-login.guard';
import { CanActiveDetailInfoGuard } from '../../services/can-active-detail-info.guard';
import { BlockNewUsersComponent } from './components/dialog/block-new-users/block-new-users.component';
import { PopoverComponent } from './components/popover-view/popover/popover.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { FeedbackButtonComponent } from './components/feedback/feedback-button/feedback-button.component';
import { FeedbackDialogComponent } from './components/feedback/feedback-dialog/feedback-dialog.component';
import { StandingBoyComponent } from './components/feedback/illustration/standing-boy/standing-boy.component';
import { SittingBoyComponent } from './components/feedback/illustration/sitting-boy/sitting-boy.component';
import { SearchResultsComponent } from './components/friends/search-results/search-results.component';
import { FriendsListComponent } from './components/friends/friends-list/friends-list.component';
import { NewsCategoryItemComponent } from './components/news-category-item/news-category-item.component';
import { BwbPagerComponent } from './components/bwb-pager/bwb-pager.component';
import { IsUserApprovedGuard } from 'src/app/services/is-user-approved.guard';
import { TradeGridComponent } from './components/trade-grid/trade-grid.component';
import {
    CellTemplateRendererComponent
} from './components/trade-grid/cell-template/cell-template-renderer/cell-template-renderer.component';
import { CellDateTemplateComponent } from './components/trade-grid/cell-template/cell-date/cell-date.component';
import { CellPriceTemplateComponent } from './components/trade-grid/cell-template/cell-price/cell-price.component';
import { CellIssuerNameTemplateComponent } from './components/trade-grid/cell-template/cell-issuer-name/cell-issuer-name.component';
import { CellSolutionTemplateComponent } from './components/trade-grid/cell-template/cell-solution/cell-solution.component';
import { MiltiselectFilterComponent } from './components/trade-grid/filter/multiselect-filter/multiselect-filter.component';
import { DateRangeFilterComponent } from './components/trade-grid/filter/date-range-filter/date-range-filter.component';
import { NumberFilterComponent } from './components/trade-grid/filter/number-filter/number-filter.component';
import { CellBondcoinComponent } from './components/trade-grid/cell-template/cell-bondcoin/cell-bondcoin.component';
import { TradeItemDetailComponent } from './components/trade-grid/trade-item-detail/trade-item-detail.component';
import { CandleStickComponent } from './components/chart/candle-stick/candle-stick.component';
import { AriaChartComponent } from './components/chart/aria-chart/aria-chart.component';
import { DisableControlDirective } from './directives/disable-control.directive';
import { SpeedometerComponent } from './components/speedometer/speedometer.component';
import { CircleGradientComponent } from './components/speedometer/circle-gradient/circle-gradient.component';
import { TradeItemTileComponent } from './components/trade-grid/trade-item-tile/trade-item-tile.component';
import { MatInputCommifiedDirective } from './directives/mat-input-commified.directive';
import { StartTradingDialogComponent } from './components/start-trading-dialog/start-trading-dialog.component';
import { BlurredContainerComponent } from './components/blurred-container/blurred-container.component';


const routes: Routes = [
    { path: 'email-verified', component: EmailVerifiedComponent, canActivate: [DisActiveteLoginGuard, IsUserApprovedGuard] },
    { path: 'log-in', component: LogInComponent, canActivate: [CanActivateLoginGuard] },
    { path: 'sign-up', component: SignUpComponent, canActivate: [CanActivateLoginGuard] },
    { path: 'settings', component: SettingsComponent, canActivate: [DisActiveteLoginGuard, IsUserApprovedGuard] },
    {
        path: 'passport', component: DetailInfoComponent,
        canActivate: [DisActiveteLoginGuard, CanActiveDetailInfoGuard, IsUserApprovedGuard]
    },
];

@NgModule({
    imports: [
        RouterModule.forChild(routes),
        CommonModule,
        FlexLayoutModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        RouterModule,
        HttpClientModule,
        TextMaskModule,

        // Kendo

        GridModule,
        ChartModule,
        SparklineModule,
        StockChartModule,
        DateInputsModule,

        // end Kendo
    ],
    declarations: [
        DisableControlDirective,
        MatInputCommifiedDirective,
        NewsCategoryItemComponent,
        DatepickerOverviewComponent,
        NavBarComponent,
        FooterComponent,
        SignUpComponent,
        LogInComponent,
        IndividualsComponent,
        InstitutionsComponent,
        UserNavComponent,
        DetailInfoComponent,
        CircleGradientComponent,
        SidenavComponent,
        HeaderSearchComponent,
        SearchComponent,
        LoaderFotoComponent,
        FontPreloadComponent,
        SpinnerDirective,
        SettingsComponent,
        PrelooderPhotoComponent,
        EmailVerifiedComponent,
        BlockNewUsersComponent,
        PopoverComponent,
        FeedbackComponent,
        FeedbackButtonComponent,
        FeedbackDialogComponent,
        StandingBoyComponent,
        SittingBoyComponent,
        SearchResultsComponent,
        FriendsListComponent,
        BwbPagerComponent,
        SpeedometerComponent,
        // Trade Grid

        TradeGridComponent,
        TradeItemDetailComponent,
        CellTemplateRendererComponent,
        CellDateTemplateComponent,
        CellPriceTemplateComponent,
        CellIssuerNameTemplateComponent,
        CellSolutionTemplateComponent,
        MiltiselectFilterComponent,
        DateRangeFilterComponent,
        NumberFilterComponent,
        CellBondcoinComponent,
        CandleStickComponent,
        AriaChartComponent,
        TradeItemTileComponent,
        StartTradingDialogComponent,
        BlurredContainerComponent
    ],
    exports: [
        BlurredContainerComponent,
        DisableControlDirective,
        MatInputCommifiedDirective,
        NewsCategoryItemComponent,
        NavBarComponent,
        FooterComponent,
        SignUpComponent,
        CommonModule,
        FlexLayoutModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        HttpClientModule,
        CircleGradientComponent,
        SidenavComponent,
        HeaderSearchComponent,
        SearchComponent,
        IndividualsComponent,
        TextMaskModule,
        FontPreloadComponent,
        SpinnerDirective,
        FeedbackButtonComponent,
        SittingBoyComponent,
        FeedbackComponent,
        SearchResultsComponent,
        FriendsListComponent,
        BwbPagerComponent,
        SpeedometerComponent,
        // Trade Grid

        TradeGridComponent,
        TradeItemDetailComponent,
        CellTemplateRendererComponent,
        CellDateTemplateComponent,
        CellPriceTemplateComponent,
        CellIssuerNameTemplateComponent,
        CellSolutionTemplateComponent,
        MiltiselectFilterComponent,
        DateRangeFilterComponent,
        NumberFilterComponent,
        CellBondcoinComponent,
        CandleStickComponent,
        AriaChartComponent
    ],
    providers: [LogInComponent, AngularFirestore],
    entryComponents: [BlockNewUsersComponent, PopoverComponent, FeedbackDialogComponent, StartTradingDialogComponent]
})
export class SharedModule {
}
