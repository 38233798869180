import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { map, take } from 'rxjs/operators';
import { Observable, Subscription, BehaviorSubject, combineLatest, Subject } from 'rxjs';

import * as firebase from 'firebase/app';
import 'firebase/auth';

import { Router } from '@angular/router';
import { TradeFilterModel } from '../modules/trade-blotter/models/TradeFilterModel';
import { StandingInstructions } from '../modules/cash-management/my-standing-instructions/StandingInstructions';

export class User {
    public name: string;
    public email: string;
    public photoUrl: string;
    public uid: string;
    public emailVerified: boolean;
    public phoneNumber: string;

    constructor(
        name: string,
        email: string,
        photoUrl: string,
        uid: string,
        emailVerified: boolean,
        phoneNumber: string
    ) {
        this.name = name;
        this.email = email;
        this.photoUrl = photoUrl;
        this.uid = uid;
        this.emailVerified = emailVerified;
        this.phoneNumber = phoneNumber;
    }
}

export class UserDetail {
    public id: string;
    public userType: string;
    public bwbTag: string;
    public currency: string;
    public tripleSInOneClick: boolean;
    public showCountriesToFriends: boolean;
    public showSurveyResultsToFriends: boolean;
    public firstPreview = false;
    public verified: boolean;
    public partialAccess: boolean;
    public isAdmin: boolean;
    public email: string;
    public phoneNumber: string;
    public creationDate: Date;
    public isBank: boolean;
    public bankName: string;
    public bankStreet: string;
    public bankStreetNumber: string;
    public bankCity: string;
    public bankCountry: string;
    public bankPostCode: string;
    public bankIBANCash: string;
    public bankIBANCustody: string;
    public disable: boolean;
    constructor(fields: any) {
        Object.assign(this, fields);
    }
}

export class Individuals extends UserDetail {
    public name = '';
    public nameLast = '';
    public dateBirth = null;
    public citizenUS = true;
    public state = '';
    public street = '';
    public city = '';
    public zipCode = '';
    public addSaver = false;
    public addPhoneOrEmail = '';
    public imageFile1 = '';
    public imageFile1TaxId = '';
    public imageFile1Country = '';
    public imageFile2 = '';
    public imageFile2TaxId = '';
    public imageFile2Country = '';
    public passport = '';

    constructor(fields: any) {
        super(fields);
        Object.assign(this, fields);
    }
}

export class Institutions extends UserDetail {
    public name = '';
    public street = '';
    public state = '';
    public city = '';
    public zipCode = '';
    public tax = '';
    public key = '';
    public options = '0';
    public addOptions = '0';
    public addPhoneOrEmail = '';
    public options3 = '1';
    public addOptions1 = false;
    public addOptions2 = false;
    public addOptions3 = false;
    public imageFile = '';
    public indNameFirst = '';
    public indNameLast = '';
    public indTitle = '';
    public indDateBirth = null;
    public indStreet = '';
    public indState = '';
    public indCity = '';
    public indZipCode = '';
    public indImageFile = '';
    public indOptions = '1';
    public extraRepresentative = false;
    public extraNameFirst = '';
    public extraFunction = '';
    public extraNameLast = '';
    public extraPhoneOrEmail = '';
    constructor(fields: any) {
        super(fields);
        Object.assign(this, fields);
    }
}

@Injectable()
export class AuthService {
    public readonly loginState: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public readonly approvedState: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public readonly partialAccessState: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public readonly adminState: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public readonly allowedToView: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public currentUser: BehaviorSubject<User> = new BehaviorSubject(null as User);
    public itemsCollection: AngularFirestoreCollection<UserDetail>;
    public items: Observable<UserDetail[]>;
    public currentUserDetail;
    public confirmationResult: firebase.default.auth.ConfirmationResult;
    // showModalBlockNewUsers = false;
    public showModalBlockNewUsers: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public userInfo$ = new Subject<UserDetail>();
    private subs: Subscription;

    constructor(public afAuth: AngularFireAuth,
        private afs: AngularFirestore,
        private router: Router) {
        this.afAuth.authState.subscribe(res => {
            // console.log('login state: ', res);
            this.loginState.next(!!(res && res.uid));
            this.itemsCollection = afs.collection<UserDetail>('users');
            let currentUser = null;
            if (res) {
                currentUser = new User(
                    res.displayName,
                    res.email,
                    res.photoURL,
                    res.uid,
                    res.emailVerified,
                    res.phoneNumber
                );

                this.currentUser.next(currentUser);

                this.subs = this.itemsCollection
                    .doc<UserDetail>(this.currentUser.value.uid)
                    .valueChanges()
                    .subscribe((usrDetails: UserDetail) => {
                        this.currentUserDetail = usrDetails;
                    });
            }
        });
        this.userInfo$
            .subscribe(u => {
                this.approvedState.next(u ? u.isAdmin || u.verified : undefined);
                this.partialAccessState.next(u ? u.partialAccess && !u.isAdmin : undefined);
                this.adminState.next(u ? u.isAdmin : undefined);
            });
        combineLatest([this.loginState, this.approvedState, this.partialAccessState])
            .pipe(
                map(([loginState, approvedState, partialAccessState]) => loginState && (approvedState || partialAccessState))
            )
            .subscribe(allowedToView => this.allowedToView.next(allowedToView));
    }

    // ---------- UserSettings -------------------------------------

    public setUserSettings(data) {
        // console.log('addUserSettings: ', data);
        if (this.currentUser.value.uid) {
            const id = this.currentUser.value.uid;
            void this.itemsCollection
                .doc(id)
                .collection('settings')
                .doc('filter')
                .set(Object.assign({}, data))
                .then();
        }
    }

    public getCurrentUserSettings() {
        return this.itemsCollection
            .doc(this.currentUser.value.uid)
            .collection('settings')
            .doc<TradeFilterModel>('filter')
            .valueChanges();
    }

    // ---------- UserInstructons -------------------------------------

    public setUserInstructons(data: StandingInstructions) {
        if (this.currentUser.value.uid) {
            const id = this.currentUser.value.uid;
            return this.itemsCollection
                .doc(id)
                .collection('settings')
                .doc('instructions')
                .set(Object.assign({}, data));
        }
    }

    public getCurrentUserInstructons() {
        return this.itemsCollection
            .doc(this.currentUser.value.uid)
            .collection('settings')
            .doc<StandingInstructions>('instructions')
            .valueChanges();
    }

    // ---------- UserDetail -------------------------------------

    public addUserDetail(data: any) {
        // console.log('creating user profile', data);
        const id = this.currentUser.value.uid;
        let userDetail: UserDetail = null;

        if (data.userType === 'Institutions') {
            userDetail = new Institutions(data);
        } else {
            userDetail = new Individuals(data);
        }

        void this.itemsCollection
            .doc(id)
            .set(Object.assign({}, userDetail))
            .then();
    }

    public async changeUserProfile(data: any, block = false) {
        (await this.afAuth.currentUser).updateProfile({
            displayName: '',
            photoURL: data.photoURL
        })
            .then(() => {
                this.initUser();
                if (block) {
                    this.blockNewUser();
                }
            })
            .catch(error => console.log(error));
    }

    public async changeUserDetail(data: any) {
        // console.log('change user profile', data);
        const id = this.currentUser.value.uid;

        (await this.afAuth.currentUser)
            .updateProfile({
                displayName: data.name,
                photoURL: data.photoURL
            })
            .then(() => {
                this.initUser();
            })
            .catch(error => console.log(error));

        void this.itemsCollection
            .doc(id)
            .update(data)
            .then(() => this.fetchCurrentUserDetails());
    }

    public getCurrentUser(): BehaviorSubject<any> {
        return this.currentUser;
    }

    public getCurrentUserDetails(): Observable<Individuals | Institutions> {
        return this.itemsCollection
            .doc<UserDetail>(this.currentUser.value.uid)
            .valueChanges()
            .pipe(
                map((userInfo: UserDetail) => {
                    if (userInfo.userType === 'Institutions') {
                        return userInfo as Institutions;
                    } else {
                        return userInfo as Individuals;
                    }
                })
            );
    }

    public fetchCurrentUserDetails() {
        this.getCurrentUserDetails().pipe(take(1)).subscribe(u => {
            this.approvedState.next(u ? u.isAdmin || u.verified : undefined);
            this.partialAccessState.next(u ? u.partialAccess && !u.isAdmin : undefined);
            this.adminState.next(u ? u.isAdmin : undefined);
        });
    }

    // ---------- phone -------------------------------------

    public sendLoginCode(phone, appVerifier) {
        return new Promise<any>((resolve, reject) => {
            this.afAuth.signInWithPhoneNumber(phone, appVerifier).then(
                res => {
                    this.confirmationResult = res;
                    resolve(res);
                },
                err => {
                    reject(err);
                }
            );
        });
    }

    public doLoginSESSIONPhone(phoneNumber, appVerifier) {
        return new Promise<any>((resolve, reject) => {
            this.afAuth.setPersistence(firebase.default.auth.Auth.Persistence.SESSION).then(
                res => {
                    this.afAuth
                        .signInWithPhoneNumber(phoneNumber, appVerifier)
                        .then(
                            resalt => {
                                this.confirmationResult = resalt;
                                resolve(resalt);
                            },
                            err => {
                                reject(err);
                            }
                        );
                    // this.confirmationResult = res as firebase.auth.ConfirmationResult;
                    resolve(res);
                },
                err => reject(err)
            );
        });
    }

    public verifyLoginCode(data) {
        return new Promise<any>((resolve, reject) => {
            this.confirmationResult.confirm(data.code).then(
                res => {
                    void this.itemsCollection
                        .doc<UserDetail>(res.user.uid)
                        .get().toPromise().then(doc => {
                            const userInfo = doc.data();
                            this.adminState.next(userInfo && userInfo.isAdmin);
                            this.approvedState.next(userInfo && (userInfo.isAdmin || userInfo.verified));
                            this.partialAccessState.next(userInfo && (!userInfo.isAdmin && userInfo.partialAccess));
                            resolve(res);
                        });

                    this.initUser(res.user);
                    if (res.additionalUserInfo.isNewUser) {
                        const detailUser = {
                            bwbTag: data.bwbTag || '',
                            name: data.name || '',
                            nameLast: data.nameLast || '',
                            userType: data.userType || 'Individuals',
                            currency: data.currency || 'USD',
                            email: res.user.email,
                            phoneNumber: res.user.phoneNumber,
                            creationDate: new Date()
                        };
                        this.addUserDetail(detailUser);
                    }
                },
                err => {
                    reject(err);
                    // console.log(err);
                }
            );
        });
    }

    // -----------------------------------------------
    public doRegister(value: {
        email: string;
        password: string;
        firstName: string;
        lastName: string;
        photoURL: string;
        userType: string;
        bwbTag: string;
        currency: string;
    }) {
        return new Promise<any>((resolve, reject) => {
            this.afAuth.createUserWithEmailAndPassword(value.email, value.password).then(
                async res => {
                    resolve(res);
                    // console.log('res', res);
                    this.doSendEmailVerification();

                    (await this.afAuth.currentUser)
                        .updateProfile({
                            displayName: '',
                            photoURL: value.photoURL
                        })
                        .then(async () => {
                            this.initUser(await this.afAuth.currentUser);
                        })
                        .catch(error => console.log(error));
                    this.initUser(res.user);

                    const detailUser = {
                        name: value.firstName || '',
                        nameLast: value.lastName || '',
                        bwbTag: value.bwbTag,
                        userType: value.userType,
                        currency: value.currency || 'USD',
                        email: res.user.email,
                        creationDate: new Date()
                    };
                    this.addUserDetail(detailUser);
                },
                err => reject(err)
            );
        });
    }

    public add(value) {
        this.addUserDetail(value);
    }

    public async doSendEmailVerification() {
        return (await this.afAuth.currentUser)
            .sendEmailVerification({ url: `https://${location.host}/email-verified` })
            .then(function () {
                // Verification email sent.
            })
            .catch(function (_) {
                // Error occurred. Inspect error.code.
            });
    }

    public doUpdatePhoneNumber(item) {
        return new Promise<any>((resolve, reject) => {
            this.afAuth.currentUser.then(user => user.updatePhoneNumber(item).then(
                res => {
                    resolve(res);
                    console.log('Update PhoneNumber successful');
                },
                err => {
                    reject(err);
                    console.log('An error happened');
                }
            ));
        });
    }

    public doUpdateEmail(item) {
        return new Promise<any>((resolve, reject) => {
            this.afAuth.currentUser.then(user => user.updateEmail(item).then(
                res => {
                    resolve(res);
                    // console.log('Update successful');
                    this.doSendEmailVerification();
                },
                err => {
                    reject(err);
                    // console.log('An error happened');
                }
            ));
        });
    }

    public doLogin(value) {
        return new Promise<any>((resolve, reject) => {
            this.afAuth.signInWithEmailAndPassword(value.email, value.password).then(
                res => {
                    void this.itemsCollection
                        .doc<UserDetail>(res.user.uid)
                        .get().toPromise().then(doc => {
                            const userInfo = doc.data();
                            this.adminState.next(userInfo && userInfo.isAdmin);
                            this.approvedState.next(userInfo && (userInfo.isAdmin || userInfo.verified));
                            this.partialAccessState.next(userInfo && (!userInfo.isAdmin && userInfo.partialAccess));
                            resolve(res);
                        });
                },
                err => reject(err)
            );
        });
    }

    public doLoginSESSION(value) {
        return new Promise<any>((resolve, reject) => {
            this.afAuth.setPersistence(firebase.default.auth.Auth.Persistence.SESSION).then(
                res => {
                    this.afAuth.signInWithEmailAndPassword(value.email, value.password).then(
                        resL => {
                            void this.itemsCollection
                                .doc<UserDetail>(resL.user.uid)
                                .get().toPromise().then(doc => {
                                    const userInfo = doc.data();
                                    this.adminState.next(userInfo && userInfo.isAdmin);
                                    this.approvedState.next(userInfo && (userInfo.isAdmin || userInfo.verified));
                                    this.partialAccessState.next(userInfo && (!userInfo.isAdmin && userInfo.partialAccess));
                                    resolve(res);
                                });
                        },
                        err => reject(err)
                    );
                },
                err => reject(err)
            );
        });
    }

    public logOut() {
        if (this.subs) {
            this.subs.unsubscribe();
        }
        return this.afAuth
            .signOut()
            .then(() => {
                console.log('Sign-out successful');
                // this.router.navigate(['/home']);
            })
            .catch(_ => console.log('An error happened'));
    }

    public blockNewUser() {
        const id = this.currentUser.value.uid;
        const data = {
            disable: true,
        };
        void this.itemsCollection
            .doc(id)
            .update(data)
            .then(() => {
                this.showModalBlockNewUsers.next(true);
                // this.logOut();
                setTimeout(() => {
                    this.showModalBlockNewUsers.next(false);
                }, 2000);
            });
    }

    public forgotPassword(email: string) {
        return this.afAuth
            .sendPasswordResetEmail(email)
            .then(() => console.log('email sent'))
            .catch(error => console.log(error));
    }

    public async deleteUser() {
        (await this.afAuth.currentUser).delete().then(function () {
            // this.logOut();
            console.log('User deleted');
            // User deleted.
        }).catch(function (_) {
            // An error happened.
        });
    }

    public async initUser(user?: firebase.default.User) {
        let currentUser = null;
        const userInternal = user || await this.afAuth.currentUser;
        if (userInternal) {
            currentUser = new User(
                userInternal.displayName,
                userInternal.email,
                userInternal.photoURL,
                userInternal.uid,
                userInternal.emailVerified,
                userInternal.phoneNumber
            );
        }
        this.currentUser.next(currentUser);
    }

    public async addEmailProvvider(value) {
        const credential = firebase.default.auth.EmailAuthProvider.credential(value.email, value.password);
        (await this.afAuth.currentUser).linkWithCredential(credential)
            .then(
                function (usercred) {
                    const user = usercred.user;
                    console.log('Account linking success', user);
                },
                function (error) {
                    console.log('Account linking error', error);
                }
            );
    }

    public async addPhoneProvider(code) {
        // console.log('code:', code);
        // console.log(this.confirmationResult.verificationId);
        const credential = firebase.default.auth.PhoneAuthProvider.credential(
            this.confirmationResult.verificationId,
            code
        );
        (await this.afAuth.currentUser).linkWithCredential(credential)
            .then(
                function (usercred) {
                    const user = usercred.user;
                    console.log('Account linking success', user);
                },
                function (error) {
                    console.log('Account linking error', error);
                }
            );
    }
}

