<footer class="footer">
    <div class="container--lg" *ngIf="!mobileQuery.matches && (authService.allowedToView | async)">
        <div fxLayout="row" fxLayoutGap="20px">
            <div fxFlex="15%">
                <h6 class="footer-title">{{solutions.title}}</h6>
                <ul class="footer-nav">
                    <li class="footer-nav__item" *ngFor="let item of solutions.rows">
                        <a class="footer-nav__link" [routerLink]="item.url">{{item.name}}</a>
                    </li>
                </ul>
            </div>
            <div fxFlex="15%">
                <h6 class="footer-title">{{friends.title}}</h6>
                <ul class="footer-nav">
                    <li class="footer-nav__item" *ngFor="let item of friends.rows">
                        <a class="footer-nav__link" [routerLink]="item.url">{{item.name}}</a>
                    </li>
                </ul>
            </div>
            <div fxFlex="25%">
                <h6 class="footer-title">{{news.title}}</h6>
                <ul class="footer-nav">
                    <li class="footer-nav__item" *ngFor="let item of news.rows">
                        <a class="footer-nav__link" [routerLink]="item.url">{{item.name}}</a>
                    </li>
                </ul>
            </div>
            <div fxFlex="15%">
                <h6 class="footer-title">{{info.title}}</h6>
                <ul class="footer-nav">
                    <li class="footer-nav__item" *ngFor="let item of info.rows">
                        <a class="footer-nav__link" [routerLink]="item.url">{{item.name}}</a>
                    </li>
                </ul>
            </div>
            <div fxFlex="30%">
                <h6 class="footer-title">{{learns.title}}</h6>
                <ul class="footer-nav">
                    <li class="footer-nav__item" *ngFor="let learn of learns.rows">
                        <a class="footer-nav__link" *ngIf="!!learn.target" [target]="learn.target"
                            href="{{learn.url}}">{{learn.name}}</a>
                        <a class="footer-nav__link" *ngIf="!learn.target" [routerLink]="learn.url">{{learn.name}}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="copyrighted">
        <div class="container">
            {{copyrighted}}
            <div class="powered-by">
                <span>Powered by</span>
                <a href="https://www.compatibl.com/" target="_blank"><img
                        src="assets/image/svg/logo-compatibl-white.svg" alt="Compatibl" title="Compatibl" /></a>
            </div>
        </div>
    </div>
</footer>