export const environment = {
    production: true,
    firebase: {
        apiKey: 'AIzaSyCVM7F3gVgqDLUGjtpcypQgaBbgo1qEmP8',
        authDomain: 'carbon-virtue-207119.firebaseapp.com',
        databaseURL: 'https://carbon-virtue-207119.firebaseio.com',
        projectId: 'carbon-virtue-207119',
        storageBucket: 'carbon-virtue-207119.appspot.com',
        messagingSenderId: '968189474138'
    },
    feedbackUrl: 'https://docs.google.com/forms/d/e/1FAIpQLSeQp7lZ8H7NrN2jJE2_kbboyFZUaXyrEZVvI7Rq97VYlFWzSg/viewform?embedded=true',
    surveyResultsUrl:
        'https://docs.google.com/forms/d/e/1FAIpQLSdL3FmI2yjgkYBI2_PkeKQMqQbGWDGTagOP50CCNd3pU-vLOg/viewanalytics?embedded=true'
};
