/* eslint-disable @angular-eslint/no-forward-ref */
// tslint:disable: no-use-before-declare
import { Component, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NgForm } from '@angular/forms';
import { createAutoCorrectedDatePipe } from 'text-mask-addons/dist/textMaskAddons';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    useExisting: forwardRef(() => DatepickerOverviewComponent),
    multi: true
};

const noop = () => {
};

@Component({
    selector: 'datepicker-overview',
    templateUrl: './datepicker-overview.component.html',
    styleUrls: ['./datepicker-overview.component.scss'],
    providers: [
        CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR
    ],
})

export class DatepickerOverviewComponent implements ControlValueAccessor {
    @Output() public readonly changeDateBirth = new EventEmitter<any>();
    @ViewChild('f') public dataForm: NgForm;
    @Input() public dataError: boolean;
    public focus = true;

    public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
    public autoCorrectedDatePipe = createAutoCorrectedDatePipe('mm/dd/yyyy HH:MM');
    public innerValue: Date = new Date();

    private onTouchedCallback: () => void = noop;
    private onChangeCallback: (_: any) => void = noop;

    // get accessor
    public get value(): Date {
        return this.innerValue;
    }

    // set accessor including call the onchange callback
    public set value(v: Date) {
        if (v !== this.innerValue) {
            this.innerValue = v;
            this.changeDateBirth.emit(v);
        }

        if (!this.isValidDate(v)) {
            this.innerValue = null;
        }
    }

    public isValidDate(d) {
        return d instanceof Date && !isNaN(d as any);
    }

    public writeValue(value: any): void {
        if (value !== this.innerValue) {
            this.innerValue = value;
        }
    }

    public registerOnChange(fn: any): void {
        this.onChangeCallback = fn;
    }

    public registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    public onChange(event) {
        this.value = event;
        this.onBlur();
    }

    public todate(value) {
        this.value = new Date(value);
    }

    public onBlur() {
        this.focus = true;
        this.onChangeCallback(this.innerValue);
    }
    public onClick() {
        this.focus = false;
    }

}
