import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DisActiveteLoginGuard implements CanActivate, CanActivateChild {

    constructor(private authService: AuthService, private router: Router) { }
    public canActivate(
        _: ActivatedRouteSnapshot,
        _1: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.handleAllowedTrigger();
    }
    public canActivateChild(
        _: ActivatedRouteSnapshot,
        _1: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.handleAllowedTrigger();
    }
    private handleAllowedTrigger() {
        return this.authService.loginState
            .pipe(
                map(loggedIn => {
                    if (!loggedIn) {
                        void this.router.navigate(['/log-in']);
                        return false;
                    } else {
                        return true;
                    }
                })
            );
    }
}
