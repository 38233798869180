import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    AngularFireStorage,
    AngularFireStorageReference,
    AngularFireUploadTask
} from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthService } from '../../../../services/auth.service';

@Component({
    selector: 'app-loader-foto',
    templateUrl: './loader-foto.component.html',
    styleUrls: ['./loader-foto.component.scss']
})
export class LoaderFotoComponent implements OnInit {
    @ViewChild('fileUpload') public fileInput: ElementRef;
    @Input() public imageType: string;
    @Input() public nameDoc: string;
    @Input() public imageFile: string;
    @Output() public readonly changeImageFile = new EventEmitter<any>();

    public ref: AngularFireStorageReference;
    public task: AngularFireUploadTask;
    public uploadProgress: Observable<number>;
    public downloadURL: string;
    public downloadName: string;
    public isLoggedIn = false;
    public currentUid = '';

    constructor(private afStorage: AngularFireStorage, public authService: AuthService) { }

    public ngOnInit() {
        this.downloadURL = this.imageFile;

        this.authService.loginState.subscribe(v => {
            this.isLoggedIn = v;
            if (v) {
                this.authService.getCurrentUser().subscribe(userUnfo => {
                    this.currentUid = userUnfo.uid;
                });
            }
        });
    }

    public upload(event) {
        if (this.imageType === 'doc' || this.imageType === 'document') {
            this.ref = this.afStorage.ref('/users/' + this.currentUid + '/doc/' + this.nameDoc);
        } else if (this.imageType === 'avatar') {
            this.ref = this.afStorage.ref('/users/' + this.currentUid + '/' + this.nameDoc);
        }

        this.task = this.ref.put(event.target.files[0]);
        this.uploadProgress = this.task.percentageChanges();
        this.task
            .snapshotChanges()
            .pipe(
                finalize(() => {
                    this.ref.getDownloadURL().subscribe(res => {
                        this.downloadURL = res;
                        this.changeImageFile.emit(res);
                    });

                    this.ref.getMetadata().subscribe(res => {
                        this.downloadName = res.name;
                    });
                })
            )
            .subscribe();
    }

    public onFileUpload() {
        this.fileInput.nativeElement.click();
    }

    public deleteDoc() {
        // const desertRef = this.afStorage.ref('/users/' + this.currentUid + '/doc/' + this.nameDoc);
        // desertRef.delete();
        this.downloadName = '';
        this.changeImageFile.emit(this.downloadName);
        event.stopPropagation();
    }

    public onFileDonwload() {
        // console.log('onFileDonwload');
    }

    public onDocUpload() {
        if (this.downloadURL) {
            this.onFileDonwload();
        } else {
            this.onFileUpload();
        }
    }
}
