import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { MatStepper } from '@angular/material/stepper';
import { SharedService } from '../../shared.service';
import { debounceTime, finalize, take } from 'rxjs/operators';

import {
    AngularFireStorage,
    AngularFireStorageReference,
    AngularFireUploadTask
} from '@angular/fire/storage';
import { BehaviorSubject, fromEvent, Observable, Subject } from 'rxjs';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { HttpClient } from '@angular/common/http';
import { RefDataLookupService } from '../../../trade-blotter/services/ref-data-lookup.service';
import { AngularFireFunctions } from '@angular/fire/functions';

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit, OnDestroy {
    @ViewChild('stepper', { static: true }) public stepper: MatStepper;
    @ViewChild('containerOfRecapcha', { static: true }) public containerOfRecapcha: ElementRef;
    public ref: AngularFireStorageReference;
    public task: AngularFireUploadTask;
    public uploadProgress: Observable<number>;
    public downloadURL: string;
    public verificationEmailSent = false;
    public isLoggedIn = false;
    public changeEmail: string;

    public valueChange: Subject<any> = new Subject();
    public isPhone = true;

    public firstFormGroup: FormGroup;
    public recaptchaVerifier;

    public codeForm: FormGroup;

    public hide = true;
    public errorMessage = '';
    public currencies: string[] = [];
    public recaptchaValid: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private BASE_URL = 'api/singup/bwbTagExists';

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        public authService: AuthService,
        private sharedService: SharedService,
        private afStorage: AngularFireStorage,
        private zone: NgZone,
        private refDataService: RefDataLookupService,
        private http: HttpClient,
        private el: ElementRef,
        private angularFireFunctions: AngularFireFunctions
    ) {
    }

    public ngOnInit() {
        this.refDataService.getDataSource('Currency').subscribe(currencies => this.currencies = currencies);
        this.authService.loginState.subscribe(v => (this.isLoggedIn = v));

        this.firstFormGroup = this.formBuilder.group({
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            userType: ['Individuals'],
            currency: [''],
            email: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(6)]],
            bwbTag: ['',
                [Validators.pattern(/^[A-z0-9]*$/), Validators.minLength(3)],
                this.forbiddenBwbTag.bind(this)],
            risksWarning: ['', Validators.requiredTrue],
            photo: ['']
        });

        this.codeForm = this.formBuilder.group({
            code: [
                '',
                [Validators.required, Validators.minLength(4)]
            ]
        });

        this.recaptchaVerifier = new firebase.default.auth.RecaptchaVerifier(
            this.containerOfRecapcha.nativeElement,
            {
                size: 'normal',
                callback: _ => {
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                    // console.log('reCAPTCHA solved, allow signInWithPhoneNumber');
                    this.zone.run(() => {
                        this.recaptchaValid.next(true);
                    });
                },
                // Response expired. Ask user to solve reCAPTCHA again.
                'expired-callback': () => {
                    // console.log('Response expired. Ask user to solve reCAPTCHA again.');
                    this.zone.run(() => this.recaptchaValid.next(false));
                }
            }
        );
        this.recaptchaVerifier.verify();

        this.valueChange.pipe(debounceTime(500)).subscribe(() => {
            this.isPhone = !this.firstFormGroup.controls.email.value.includes('@');
            if (this.isPhone) {
                this.firstFormGroup.controls['password'].disable();
            } else {
                this.firstFormGroup.controls['password'].enable();
            }
        });
    }

    public forbiddenBwbTag(control: FormControl): Promise<any> | Observable<any> {
        const promise = new Promise<any>((resolve, _) => {
            if (!!control.value && control.value.length > 2) {
                this.http
                    .get(`${this.BASE_URL}?bwbTag=${control.value as string}`).subscribe(
                        (data) => {
                            if (data) {
                                // console.log('data-', data);
                                resolve({ 'bwbTagIsForbidden': true });
                            } else {
                                // console.log('data+', data);
                                resolve(null);
                            }
                        });
            } else {
                resolve(null);
            }
        });
        return promise;
    }

    public mainSpace() {
        return this.sharedService.mainSpace();
    }

    public phoneE164(num) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return `${num}`;
    }

    public showError(error) {
        this.errorMessage = error.message;

        if (
            error.code === 'auth/invalid-phone-number' ||
            error.code === 'auth/too-many-requests' ||
            error.code === 'auth/user-not-found' ||
            error.code === 'auth/invalid-email' ||
            error.code === 'auth/user-disabled' ||
            error.code === 'auth/captcha-check-failed' ||
            error.code === 'auth/too-many-requests' ||
            error.code === 'auth/captcha-check-failed' ||
            error.code === 'auth/email-already-in-use'
        ) {
            this.firstFormGroup.controls['email'].setErrors({
                'email-error': true
            });
            this.firstFormGroup.markAllAsTouched();
            setTimeout(() => {
                this.scrollToFirstInvalidControl();
            });
        }

        if (error.code === 'auth/wrong-password') {
            this.firstFormGroup.controls['password'].setErrors({
                'email-password': true
            });
            this.firstFormGroup.markAllAsTouched();
            setTimeout(() => {
                this.scrollToFirstInvalidControl();
            });
        }

        if (error.code === 'auth/invalid-verification-code' ||
            error.code === 'auth/code-expired' ||
            error.code === 'auth/user-disabled') {
            this.codeForm.controls['code'].setErrors({
                'code-error': true
            });
        }
    }


    public tryRegister(value) {
        if (this.isPhone) {
            const num = this.phoneE164(value.email);
            this.authService.sendLoginCode(num, this.recaptchaVerifier).then(
                _ => {
                    this.sharedService.scrollToTop();
                    this.stepper.selectedIndex = 1;
                },
                err => this.showError(err)
            );
        } else {
            this.authService.doRegister(value).then(
                res => {
                    this.errorMessage = '';
                    this.changeEmail = this.firstFormGroup.controls['email'].value;
                    if (this.firstFormGroup.controls['photo'].value) {
                        this.downloadPhoto(
                            this.firstFormGroup.controls['photo'].value,
                            res.user.uid
                        );
                    } else {
                        // this.authService.blockNewUser();
                    }
                    setTimeout(() => {
                        this.stepper.selectedIndex = 1;
                        // this.router.navigate(['/log-in']);
                        const func = this.angularFireFunctions.httpsCallable('sendNotificationAboutUserSignUp');
                        func({ userId: res.user.uid }).subscribe(r => console.log(r));
                    });
                },
                err => this.showError(err)
            );
        }
    }

    public onChangeImage(item) {
        this.firstFormGroup.controls['photo'].setValue(item);
    }

    public downloadPhoto(value, uid: string) {
        this.ref = this.afStorage.ref('/users/' + uid + '/avatar');
        this.task = this.ref.put(value);
        this.uploadProgress = this.task.percentageChanges();
        this.task
            .snapshotChanges()
            .pipe(
                finalize(() => {
                    this.ref.getDownloadURL().subscribe(res => {
                        this.downloadURL = res;
                        const detail = {
                            photoURL: this.downloadURL
                        };
                        this.authService.changeUserProfile(detail, true);
                    });
                })
            )
            .subscribe();
    }

    public onValueChange() {
        this.valueChange.next();
    }

    public onVerifyLoginCodeCreateUser(data) {
        const user = {
            code: data.code,
            name: this.firstFormGroup.controls['firstName'].value,
            nameLast: this.firstFormGroup.controls['lastName'].value,
            bwbTag: this.firstFormGroup.controls['bwbTag'].value,
            userType: this.firstFormGroup.controls['userType'].value,
            currency: this.firstFormGroup.controls['currency'].value
        };

        this.authService.verifyLoginCode(user).then(
            res => {
                this.changeEmail = this.firstFormGroup.controls['email'].value;

                if (this.firstFormGroup.controls['photo'].value) {
                    this.downloadPhoto(
                        this.firstFormGroup.controls['photo'].value,
                        res.user.uid
                    );
                } else {
                    // this.authService.blockNewUser();
                }
                this.router.navigate(['/log-in']);
            },
            err => this.showError(err)
        );
    }

    public onSendRequestAgain() {
        this.authService.doSendEmailVerification().then(() => {
            this.verificationEmailSent = true;
            setTimeout(() => {
                this.verificationEmailSent = false;
            }, 5000);
        });
    }

    public onToggleShowCode() {
        this.stepper.selectedIndex = 0;
        this.recaptchaValid.next(false);
    }

    public ngOnDestroy() {
        this.recaptchaVerifier.clear();
    }
    private scrollToFirstInvalidControl() {
        const firstInvalidControl: HTMLElement = this.el.nativeElement.querySelector(
            'form .ng-invalid'
        );

        window.scroll({
            top: this.getTopOffset(firstInvalidControl),
            left: 0,
            behavior: 'smooth'
        });

        fromEvent(window, 'scroll')
            .pipe(
                debounceTime(100),
                take(1)
            )
            .subscribe(() => firstInvalidControl.focus());
    }

    private getTopOffset(controlEl: HTMLElement): number {
        const labelOffset = 150;
        return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
    }
}
