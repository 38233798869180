import { Component, ElementRef, OnInit, ViewChild, NgZone, OnDestroy } from '@angular/core';
import { AuthService } from '../../../../services/auth.service';
import { SharedService } from '../../shared.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { RouterOutletService } from '../../../../services/router-outlet.service';
import { debounceTime } from 'rxjs/operators';

import * as firebase from 'firebase/app';
import 'firebase/auth';

@Component({
    selector: 'app-log-in',
    templateUrl: './log-in.component.html',
    styleUrls: ['./log-in.component.scss']
})
export class LogInComponent implements OnInit, OnDestroy {
    @ViewChild('containerOfRecapcha', { static: true }) public containerOfRecapcha: ElementRef;
    public recaptchaVerifier;
    public valueChange: Subject<any> = new Subject();
    public isPhone = true;

    public hide = true;
    public loginForm: FormGroup;
    public codeForm: FormGroup;
    public returnUrl: string;
    public resetLinkSent = false;
    public errorMessage = '';
    public showCode = false;
    public recaptchaValid: BehaviorSubject<boolean> = new BehaviorSubject(false);
    constructor(
        private authService: AuthService,
        private router: Router,
        private sharedService: SharedService,
        private formBuilder: FormBuilder,
        private routingState: RouterOutletService,
        private zone: NgZone
    ) {
    }

    public ngOnInit() {
        this.returnUrl = this.routingState.getPreviousUrl();
        console.log('this.returnUrl', this.returnUrl);
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.minLength(3)]], //  Validators.email
            password: ['', Validators.required],
            remember: true
        });

        this.codeForm = this.formBuilder.group({
            code: ['', [Validators.required, Validators.minLength(3)]]
        });

        this.recaptchaVerifier = new firebase.default.auth.RecaptchaVerifier(
            this.containerOfRecapcha.nativeElement,
            {
                size: 'normal',
                callback: _ => {
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                    // console.log('reCAPTCHA solved, allow signInWithPhoneNumber');
                    this.zone.run(() => {
                        this.recaptchaValid.next(true);
                        // this.loginForm.controls['email'].setValidators(Validators.required);
                        // this.loginForm.controls['password'].setValidators(Validators.required);
                    });
                },
                // Response expired. Ask user to solve reCAPTCHA again.
                'expired-callback': () => {
                    // console.log('Response expired. Ask user to solve reCAPTCHA again.');
                    this.zone.run(() => this.recaptchaValid.next(false));
                }
            }
        );
        this.recaptchaVerifier.verify();

        this.valueChange.pipe(debounceTime(500)).subscribe(() => {
            this.isPhone = !this.loginForm.controls.email.value.includes('@');
            if (this.isPhone) {
                this.loginForm.controls['password'].disable();
            } else {
                this.loginForm.controls['password'].enable();
            }
        });
    }

    public mainSpace() {
        return this.sharedService.mainSpace();
    }

    public showError(error) {
        this.errorMessage = error.message;
        console.log(error);

        if (
            error.code === 'auth/invalid-phone-number' ||
            error.code === 'auth/too-many-requests' ||
            error.code === 'auth/user-not-found' ||
            error.code === 'auth/invalid-email' ||
            error.code === 'auth/user-disabled' ||
            error.code === 'auth/captcha-check-failed' ||
            error.code === 'auth/too-many-requests' ||
            error.code === 'auth/captcha-check-failed'
        ) {
            this.loginForm.controls['email'].setErrors({
                'email-error': true
            });
        }

        if (error.code === 'auth/wrong-password') {
            this.loginForm.controls['password'].setErrors({
                'email-password': true
            });
        }

        if (error.code === 'forgot-password') {
            this.loginForm.controls['email'].setErrors({
                'forgot-password': true
            });
        }

        if (error.code === 'auth/invalid-verification-code' ||
            error.code === 'auth/code-expired' ||
            error.code === 'auth/user-disabled') {
            this.codeForm.controls['code'].setErrors({
                'code-error': true
            });
        }
    }

    public phoneE164(num) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return `${num}`;
    }

    public onSubmit(value) {
        if (this.isPhone) {
            const num = this.phoneE164(value.email);
            // console.log('phone:', num);
            if (value.remember) {
                this.authService.sendLoginCode(num, this.recaptchaVerifier).then(
                    _ => {
                        this.showCode = true;
                    },
                    err => this.showError(err)
                );
            } else {
                this.authService.doLoginSESSIONPhone(num, this.recaptchaVerifier).then(
                    _ => {
                        this.showCode = true;
                    },
                    err => this.showError(err)
                );
            }
        } else {
            if (value.remember) {
                this.authService.doLogin(value).then(
                    _ => {
                        this.errorMessage = '';
                        if (
                            this.returnUrl === '/sign-up' ||
                            this.returnUrl === '/not-allowed' ||
                            this.returnUrl === '/email-verified'
                        ) {
                            this.returnUrl = '/home';
                        }
                        this.router.navigate([this.returnUrl]);
                    },
                    err => this.showError(err)
                );
            } else {
                this.authService.doLoginSESSION(value).then(
                    _ => {
                        this.errorMessage = '';
                        if (
                            this.returnUrl === '/sign-up' ||
                            this.returnUrl === '/not-allowed' ||
                            this.returnUrl === '/email-verified'
                        ) {
                            this.returnUrl = '/home';
                        }
                        this.router.navigate([this.returnUrl]);
                    },
                    err => this.showError(err)
                );
            }
        }
    }

    public forgotPassword() {
        if (this.isPhone) {
            this.showError({ code: 'forgot-password' });
            this.loginForm.controls['email'].markAsTouched();
        } else {
            this.authService.forgotPassword(this.loginForm.controls['email'].value).then(
                _ => {
                    this.resetLinkSent = true;
                    setTimeout(() => {
                        this.resetLinkSent = false;
                    }, 5000);
                },
                _ => {
                    // console.log(err);
                }
            );
        }
    }

    public onValueChange() {
        this.valueChange.next();
    }

    public onSubmitCode(data) {
        this.authService.verifyLoginCode(data).then(
            res => {
                // console.log('res');
                if (res.additionalUserInfo.isNewUser) {
                    this.authService.blockNewUser();
                }
                if (this.returnUrl === '/sign-up' || this.returnUrl === '/not-allowed') {
                    this.returnUrl = '/home';
                }
                this.router.navigate([this.returnUrl]);
            },
            err => {
                this.showError(err);
            },
        );
    }

    public onToggleShowCode() {
        this.showCode = false;
        this.recaptchaValid.next(false);
    }

    public ngOnDestroy() {
        this.recaptchaVerifier.clear();
    }
}
