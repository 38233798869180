import { Component, OnInit, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { SharedService } from '../../shared.service';

@Component({
    selector: 'email-verified',
    templateUrl: './email-verified.component.html',
    styleUrls: ['./email-verified.component.scss']
})
export class EmailVerifiedComponent implements OnInit {
    @ViewChild('stepper', { static: true }) public stepper: MatStepper;
    public isLoggedIn = false;

    constructor(
        private router: Router,
        public authService: AuthService,
        private sharedService: SharedService
    ) { }

    public ngOnInit() {
        this.stepper.selectedIndex = 1;
        this.authService.loginState.subscribe(v => (this.isLoggedIn = v));
    }

    public mainSpace() {
        return this.sharedService.mainSpace();
    }

    public onSendEmailVerification() {
        this.authService.doSendEmailVerification();
    }
}
