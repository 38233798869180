<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    width="173" height="521" viewBox="0 0 173 521">
    <defs>
        <linearGradient id="linear-gradient-fb" x1="105.89" y1="-32.09" x2="55.43" y2="-32.09"
            gradientTransform="matrix(1.05, 0.06, -0.05, 1, -5.61, 38.15)" gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#512424"></stop>
            <stop offset="1" stop-color="#a85555"></stop>
        </linearGradient>
        <linearGradient id="linear-gradient-fb-2" x1="86.2" y1="521" x2="86.2" y2="488.26"
            gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#ccc"></stop>
            <stop offset="1" stop-color="#e7e7e7"></stop>
        </linearGradient>
        <linearGradient id="linear-gradient-fb-3" x1="25.64" y1="171.35" x2="146.28" y2="171.35"
            gradientUnits="userSpaceOnUse">
            <stop offset="0" stop-color="#005e8a"></stop>
            <stop offset="0.19" stop-color="#06638e"></stop>
            <stop offset="0.45" stop-color="#18719a"></stop>
            <stop offset="0.75" stop-color="#3487ae"></stop>
            <stop offset="0.79" stop-color="#388ab0"></stop>
        </linearGradient>
        <linearGradient id="linear-gradient-fb-4" x1="116.48" y1="137.98" x2="183.87" y2="137.98"
            xlink:href="#linear-gradient-fb-3"></linearGradient>
        <linearGradient id="linear-gradient-fb-5" x1="2.39" y1="137.02" x2="37.25" y2="137.02"
            xlink:href="#linear-gradient-fb-3"></linearGradient>
        <linearGradient id="linear-gradient-fb-6" x1="2.39" y1="193.79" x2="68.56" y2="193.79"
            xlink:href="#linear-gradient-fb-3"></linearGradient>
        <linearGradient id="linear-gradient-fb-7" x1="42.79" y1="207.28" x2="61.8" y2="207.28"
            xlink:href="#linear-gradient-fb-3"></linearGradient>
        <linearGradient id="linear-gradient-fb-8" x1="155.77" y1="189.21" x2="175.62" y2="184.33"
            xlink:href="#linear-gradient-fb-3"></linearGradient>
    </defs>
    <style>
        .cls-1 {
            fill: #d1977d;
        }

        .cls-19,
        .cls-2 {
            fill: #ffcea9;
        }

        .cls-13,
        .cls-2,
        .cls-7,
        .cls-8,
        .cls-9 {
            fill-rule: evenodd;
        }

        .cls-3 {
            fill: #efb38b;
        }

        .cls-4,
        .cls-7 {
            fill: #6b3434;
        }

        .cls-5 {
            fill: #1d1e1c;
        }

        .cls-6 {
            fill: #b07556;
        }

        .cls-8 {
            fill: url({{path}}#linear-gradient-fb);
        }

        .cls-9 {
            fill: url({{path}}#linear-gradient-fb-2);
        }

        .cls-10 {
            fill: #fff;
        }

        .cls-11 {
            fill: #f6f6f6;
        }

        .cls-12 {
            fill: #22292d;
        }

        .cls-13 {
            fill: #005e8a;
        }

        .cls-14 {
            fill: url({{path}}#linear-gradient-fb-3);
        }

        .cls-15 {
            fill: url({{path}}#linear-gradient-fb-4);
        }

        .cls-16 {
            fill: url({{path}}#linear-gradient-fb-5);
        }

        .cls-17 {
            fill: url({{path}}#linear-gradient-fb-6);
        }

        .cls-18 {
            fill: url({{path}}#linear-gradient-fb-7);
        }

        .cls-20 {
            fill: #35302f;
        }

        .cls-21 {
            fill: #54667a;
        }

        .cls-22 {
            fill: #f9bb10;
        }

        .cls-23 {
            fill: url({{path}}#linear-gradient-fb-8);
        }
    </style>
    <title>Artboard 100</title>
    <g>
        <g>
            <path class="cls-1"
                d="M88.25,71.14C90.64,53.86,66.63,46.85,67,58.21c.1,2.94-.54,28.55-10.41,31.13-21.4,6.35-5.62,14.06,16.7,14,3.56,0,19.68-5.89,25.2-13.51-2.11-.11-4.29.69-5.62-.52C87.48,84.44,88.25,71.14,88.25,71.14Z">
            </path>
            <path class="cls-2"
                d="M63.11,23.3S56.5,48.74,59.8,58.6s9.38,17.7,15.21,19c3.58.77,14.07-3.6,19.89-10.21,3-3.4,3.29-11.64,4.62-20.22.89-5.75,5.16-16.08,7.66-19.13C112.45,21.58,72.75-3.23,63.11,23.3Z">
            </path>
            <path class="cls-3"
                d="M86.73,21c-4.62,5.94-1.9,30.32-11.28,31.52,0,0,0,2.16,4.49,2.61,3.21.33.84,24-7.07,22.57C68.41,76.85,62,68.44,59.51,60c-1.91-6.5-1.17-12.36.05-18.25,2.78-13.55,1.52-27.89,8.2-28.61S86.73,21,86.73,21Z">
            </path>
            <path class="cls-4"
                d="M90.19,30.75c2.73-.51,5.79.8,7.76,2.55-.18-.16-.22-1-.31-1.25a4.26,4.26,0,0,0-.6-1.11,4.73,4.73,0,0,0-2.13-1.49c-1.29-.5-3.47-.64-4.5.53C90.36,30,90,30.79,90.19,30.75Z">
            </path>
            <path class="cls-4"
                d="M69.86,29.44c2.56-1.07,5.84-.43,8.13.86-.21-.11-.43-.92-.57-1.15a4.17,4.17,0,0,0-.81-1,4.84,4.84,0,0,0-2.4-1c-1.37-.21-3.54.1-4.3,1.46C69.87,28.71,69.65,29.53,69.86,29.44Z">
            </path>
            <ellipse class="cls-5" cx="73.46" cy="36.91" rx="1.47" ry="1.46"></ellipse>
            <ellipse class="cls-5" cx="91" cy="41.2" rx="1.47" ry="1.46"></ellipse>
            <path class="cls-6"
                d="M76.44,60.59l.61.38a9.71,9.71,0,0,0,1.54.74,9.11,9.11,0,0,0,2.07.47,10.33,10.33,0,0,1,2.34.43l-.5.22a5.79,5.79,0,0,1-1.45.41,4.25,4.25,0,0,1-2.43-.35A4.55,4.55,0,0,1,76.44,60.59Z">
            </path>
            <path class="cls-5"
                d="M89.58,38.85c-.16-.22,1.43.76,2.84,1.15s3.12.51,2.94.57a6.77,6.77,0,0,1-3.1.1A5.7,5.7,0,0,1,89.58,38.85Z">
            </path>
            <path class="cls-5"
                d="M75.87,35.43c.23-.15-1.61.19-3.06,0s-3.09-.64-3-.52a7.09,7.09,0,0,0,2.85,1.21A5.76,5.76,0,0,0,75.87,35.43Z">
            </path>
            <g>
                <path class="cls-7"
                    d="M108.13,29.05c-5.27,5.6-.63-.07-1.65-3.88-.34-1.29-11.08-8.29-12.49-9.46-1.07-.9-4.23-4.72-7.18-5.3-5-1-2.88,7.51-5.34,6.52C72,13.12,65.76,20,63.7,21.81c-1.5,1.34-1.38,5-2.09,9.45-2.21-1.19-3.23-3.49-3.6-6a13.88,13.88,0,0,0,2.29,7.29.23.23,0,0,1-.08.33.26.26,0,0,1-.34-.08,14.69,14.69,0,0,1-2.38-8.49A22,22,0,0,1,58,20.13,21.07,21.07,0,0,1,58.77,16c2.53-6.8,1.18-2.35,2.72-5.49C66.39.48,84.1-.06,96.18,6.45c3.39,1.83,12.19,7.66,13.79,10C111.74,19,112.12,24.83,108.13,29.05Z">
                </path>
                <path class="cls-8"
                    d="M98.08,19.81c-5.75,3.05-15,2.39-27.36-.49a4.18,4.18,0,0,1,4.13-3.09c-11.94.69-20.65-6.88-20.66-8.61,9-3,18.89-11.89,39.71-5.16,4.71,1.52,3,6.83,11.16,5.47C110.76,7,102.82,17.3,98.08,19.81Z">
                </path>
            </g>
        </g>
        <g>
            <path class="cls-9"
                d="M88.1,493.2c46.48.19,48.27,8.51,78.38,8.71,32.07.21-74.54,27.82-123.07,16.27-29-6.91-62.69-24.36-29.95-29.43C28.35,486.44,62.52,493.1,88.1,493.2Z">
            </path>
            <path
                d="M37.24,502.34s11.4,5.21,22.52-1.65c0,0-.66-6.36-3.56-8.93A31.91,31.91,0,0,1,53,488.35c-1.1-1.48-7.95,1.19-7.95,1.19S33.94,497.28,37.24,502.34Z">
            </path>
            <path class="cls-10"
                d="M37.23,499.12a3.31,3.31,0,0,1-.28-.61,4.17,4.17,0,0,0,.28,3.77s11.41,5.21,22.53-1.65a23.05,23.05,0,0,0-.53-2.87C48.28,504.14,37.23,499.12,37.23,499.12Z">
            </path>
            <g>
                <path
                    d="M128.7,488.65l-.15.44a35.51,35.51,0,0,0-1.93,8.62h0a2.19,2.19,0,0,0,1.43,2.26l6.14,2.3c4.75,1.77,9.89,2.92,14.9,2.08s8.9-2,10.55-4.27h0a2.12,2.12,0,0,0-.39-2.89s-1.57-1.65-6.53-3.35c-3.13-1.08-4.81-1.22-8.75-4.13l-2.67-2.21Z">
                </path>
                <path class="cls-11"
                    d="M148.81,501.87c-5.28.91-10.69-.17-15.66-1.9l-6.44-2.22-.08,0h0a2.2,2.2,0,0,0,1.42,2.26l6.15,2.3c4.74,1.77,9.89,2.92,14.9,2.08s8.9-2,10.55-4.27a2.12,2.12,0,0,0,.07-2.34C157.84,499.87,153.94,501,148.81,501.87Z">
                </path>
            </g>
            <g>
                <path class="cls-12"
                    d="M45.82,251.8s51.31,5.54,71.84-8.1c0,0,15.39,63.52,13.26,104.44s8.34,139.36,8.34,139.36l-8.65,2S112.1,415.07,116.8,379.26c0,0,1.71-5.54-3-11.72S94.14,280.36,86.44,292.3s-22.23,80.14-22.23,80.14-3,8.1-.43,15.35S52,487.91,52,487.91a22.25,22.25,0,0,1-3.63,1.6c-.7.08-3.39-1.21-3.39-1.21s-6-73.66,2.52-98.81S43.26,301.67,45.82,251.8Z">
                </path>
                <path
                    d="M114.24,252.23s-33.35,11.51-55.16,8.52c0,0-12.4-7.67-7.27,18.76s-1.25,82.7-1.25,82.7-6.45-101-4.74-110.41c0,0,54.31,5.54,71.84-8.1l1.69,7.44Z">
                </path>
                <path d="M72.76,285.48s23.95,3.41,31.64-3c0,0-6,8.44-15.82,8.7A22.92,22.92,0,0,1,72.76,285.48Z"></path>
                <path
                    d="M95.77,304.69s23.6,55.39,25.31,66-3.26,46.78,0,66.5c0,0-7.27-44.49-4.28-58s-3-2.09-9.09-31.31S95.77,304.69,95.77,304.69Z">
                </path>
            </g>
            <path class="cls-13" d="M45.82,248v6.06S75,267.45,118.55,243.83L116.7,236Z"></path>
        </g>
        <path class="cls-14"
            d="M26.23,93.93l30.38-4.59s16.82,18.15,36.28,0c0,0,25,1.47,33.72,8.75,0,0,.49,24.69,0,57.48-.41,27.23-3.74,80.45-3.74,80.45s-48.08,30.15-84.63,10.92c0,0-4.18-37.34-6.9-78.17C29,134.12,23.91,95.52,26.23,93.93Z">
        </path>
        <path class="cls-15"
            d="M131.65,139.34l21.29,16.83.07,24.15,20,2.35s-13.79-64.79-33.13-83.13c-3.92-3.72-19.85-6-23.39-6.25,0,0,4.38,19.68,8.5,34C126.38,132.09,128.47,132.48,131.65,139.34Z">
        </path>
        <path class="cls-16"
            d="M31.63,175.75l-1.51-23c3.31-23.72-4-58.79-4-58.79-9.63,3-15.29,16.59-16.93,23.82-3.41,15.06-6.78,62.37-6.78,62.37Z">
        </path>
        <path class="cls-17"
            d="M29.22,172.23s-12.71-8.62-25.8,4.43c0,0-4.59,5.4,5.6,13s35.86,28.5,35.86,28.5-5-6,1.84-17.56c1.41-2.38,4.56-7.48,11.17-3.37l-.1-2.14Z">
        </path>
        <g>
            <path class="cls-18"
                d="M44.88,218.14s6.2,4.67,12.36-10.75-8.55-13.07-10.52-6.81c-1.43,4.52-6,10.45-2.86,17"></path>
            <path class="cls-1"
                d="M87.89,214.13a2,2,0,0,1,3,0c1.7,1.66-3.31,6.73-4.92,7.8a19.29,19.29,0,0,1-11.88,3.57c-5.9-.36-11.36,1.69-13.41,1.16s-4.11-3.57-4.11-3.57l26-2.4L85,217.92S86.91,217.46,87.89,214.13Z">
            </path>
            <path class="cls-1" d="M87.8,209.1s2.59-.71,3,1.25c0,0-.42,3-1.16,3s-.63,1.05-.63,1.05l-1.07-.31Z"></path>
            <path class="cls-19"
                d="M58,200.1s.18,4.46,3.3,6,7.16,1.07,8.67,2.59a3.35,3.35,0,0,1,.63,4.36c-.71,1.34-4.92,1.61-9.39,0-.47-.17-.92-.35-1.34-.54-3.52-1.55-5.22-3.45-7.77-3.29-2.86.18-7.07-4.48-7.07-4.48s.85-3.9,3.28-5.92c2.88-2.41,5.22-3.86,9-.92C57.23,197.87,57.62,198.2,58,200.1Z">
            </path>
            <path class="cls-19"
                d="M47.4,208.48s4.2,6.06,4.83,8.37,1.85,8.56,6.38,8.11,9,2.53,12,2.16,10.28-2.6,13.5-5.9-.27-4.41-2.06-3.41-7.43,3.68-13.37,3-9.44-2.51-8.89-8.3S43,199.12,47.4,208.48Z">
            </path>
        </g>
        <path class="cls-1" d="M81.9,156s.52,4.37,5.37,1c0,0-.21,3.49-4.26,2.25"></path>
        <g>
            <rect class="cls-20" x="56.74" y="100.54" width="96.14" height="124.88" rx="15.49" ry="15.49"></rect>
            <rect class="cls-10" x="66.03" y="109.63" width="78.8" height="104.75"></rect>
            <rect class="cls-21" x="72.29" y="138.31" width="12.25" height="12.21"></rect>
            <rect class="cls-21" x="72.29" y="161.21" width="12.25" height="12.21"></rect>
            <rect class="cls-21" x="72.29" y="184.11" width="12.25" height="12.21"></rect>
            <path class="cls-22"
                d="M92.08,105.31h27.35a3.52,3.52,0,0,1,3.52,3.52v7.37a0,0,0,0,1,0,0H88.56a0,0,0,0,1,0,0v-7.37A3.52,3.52,0,0,1,92.08,105.31Z">
            </path>
            <path class="cls-22"
                d="M101.24,92.62h7.94a1.89,1.89,0,0,1,1.89,1.89v11.84a1,1,0,0,1-1,1h-9.74a1,1,0,0,1-1-1V94.5a1.89,1.89,0,0,1,1.89-1.89Z">
            </path>
            <path class="cls-22"
                d="M81.37,108.48H129.5A2.5,2.5,0,0,1,132,111v5.23a0,0,0,0,1,0,0H78.87a0,0,0,0,1,0,0V111A2.5,2.5,0,0,1,81.37,108.48Z">
            </path>
            <rect class="cls-21" x="90.3" y="138.31" width="46.57" height="0.76"></rect>
            <rect class="cls-21" x="90.3" y="144.03" width="46.57" height="0.76"></rect>
            <rect class="cls-21" x="90.3" y="149.76" width="46.57" height="0.76"></rect>
            <rect class="cls-21" x="90.3" y="155.48" width="46.57" height="0.76"></rect>
            <rect class="cls-21" x="90.3" y="161.21" width="46.57" height="0.76"></rect>
            <rect class="cls-21" x="90.3" y="166.93" width="46.57" height="0.76"></rect>
            <rect class="cls-21" x="90.3" y="172.66" width="46.57" height="0.76"></rect>
            <rect class="cls-21" x="90.3" y="178.38" width="46.57" height="0.76"></rect>
            <rect class="cls-21" x="90.3" y="184.11" width="46.57" height="0.76"></rect>
            <rect class="cls-21" x="90.3" y="189.83" width="46.57" height="0.76"></rect>
            <rect class="cls-21" x="90.3" y="195.56" width="46.57" height="0.76"></rect>
            <path class="cls-22"
                d="M72.8,140.35s4.79,2.52,8.64,9.55c0,0,6.35-14.54,13.76-22.45L81.12,143A50.09,50.09,0,0,1,72.8,140.35Z">
            </path>
            <path class="cls-22"
                d="M72.8,162.93s4.79,2.51,8.64,9.54c0,0,6.35-14.54,13.76-22.44L81.12,165.52A50.43,50.43,0,0,1,72.8,162.93Z">
            </path>
            <path class="cls-22"
                d="M72.8,185.5s4.79,2.51,8.64,9.55c0,0,6.35-14.55,13.76-22.45L81.12,188.09A50.43,50.43,0,0,1,72.8,185.5Z">
            </path>
        </g>
        <path class="cls-19"
            d="M59.56,201.32s.18,4.46,3.31,6,7.15,1.07,8.67,2.58a3.37,3.37,0,0,1,.63,4.37c-.72,1.34-4.92,1.61-9.39,0q-.72-.26-1.35-.54c-3.51-1.55-5.21-3.45-7.77-3.29-2.86.18-7.07-4.48-7.07-4.48s.86-3.9,3.29-5.92c2.88-2.41,5.21-3.86,9-.92C58.85,199.09,59.24,199.42,59.56,201.32Z">
        </path>
        <path class="cls-19"
            d="M165.47,192.71s-3.87,7.58-6.26,8.91c0,0-4.84,5.08-10.09,4,0,0-5-.54-3.61-2.84,0,0-5.89-2.31-2.54-5.69,0,0-6.34-2.53-1.39-6.68,0,0-4.14-2.79,1.09-5.07,0,0,1.24-.49,3.63-3.16a6.6,6.6,0,0,1,7.2-1.13,49.31,49.31,0,0,1,6-1.83C160.92,178.86,170.73,188.11,165.47,192.71Z">
        </path>
        <path class="cls-23"
            d="M152.73,180.53l.51-9L173,182.67s-3.19,16.36-13.39,22.55-6.63.25-6.63.25,6.26-2.1,4.71-15.07C157,184.9,152.73,180.53,152.73,180.53Z">
        </path>
    </g>
</svg>