<div class="container yyy1">
    <div class="mr-10 ml-10" ngClass.gt-xs="tc">
        <h2>Your BwB Passport</h2>
        <p class="lead primary-color mb-10">
            To start playing with BwB, fill Entity, Personal and Financial info
        </p>
    </div>
</div>

<div fxShow fxHide.gt-sm class="pt-20 bg-white-smoke mb-20">
    <div class="container--sm">
        <h6>Step {{stepper.selectedIndex+1}} of 3</h6>
        <h3 *ngIf="stepper.selectedIndex === 0">Entity info</h3>
        <h3 *ngIf="stepper.selectedIndex === 1">Personal info</h3>
        <h3 *ngIf="stepper.selectedIndex === 2">Financial info</h3>

        <div *ngIf="stepper.selectedIndex === 0" fxLayout="row" class="small-steps">
            <h6 fxFlex class="small-steps-items" [class.active]="steppersm.selectedIndex === 0">1.1</h6>
            <h6 fxFlex class="small-steps-items" [class.active]="steppersm.selectedIndex === 1">1.2</h6>
            <h6 fxFlex class="small-steps-items" [class.active]="steppersm.selectedIndex === 2">1.3</h6>
        </div>
    </div>
</div>

<div class="container">
    <div fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex.gt-md="840px">
            <mat-horizontal-stepper #stepper class="mat-stepper-horizontal--lg" ngClass.gt-sm="howSteps">
                <mat-step>
                    <ng-template matStepLabel>Entity info</ng-template>
                    <mat-horizontal-stepper class="mat-stepper-horizontal--xs" #steppersm>
                        <mat-step [stepControl]="firstFormGroup">
                            <form [formGroup]="firstFormGroup">
                                <ng-template matStepLabel>
                                    <strong>Step 1.1</strong><br> Legal name and address
                                </ng-template>
                                <div class="container--sm">
                                    <h6 fxShow fxHide.gt-sm class="mb-30">Step 1.1 Legal name and address</h6>
                                    <div class="mb-20">
                                        <mat-form-field>
                                            <input matInput placeholder="Legal name of your entity"
                                                formControlName="name">
                                            <mat-error ngxErrors="firstFormGroup.name">
                                                Enter First name
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <p class="lead primary-color mb-20"><strong>Legal address</strong></p>
                                    <div class="mb-10">
                                        <mat-form-field>
                                            <input matInput placeholder="Street address" formControlName="street">
                                            <mat-error ngxErrors="firstFormGroup.street">
                                                Enter Street address
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="mb-10">
                                        <mat-form-field>
                                            <input matInput placeholder="City" formControlName="city">
                                            <mat-error ngxErrors="firstFormGroup.city">
                                                Enter City
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="mb-20">
                                        <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexFill>
                                            <mat-form-field fxFlex>
                                                <mat-select placeholder="State" formControlName="state">
                                                    <mat-option *ngFor="let state of states" [value]="state.value">
                                                        {{ state.value }}
                                                    </mat-option>
                                                </mat-select>
                                                <mat-error ngxErrors="firstFormGroup.state">
                                                    Choose State
                                                </mat-error>
                                            </mat-form-field>
                                            <mat-form-field fxFlex="70px">
                                                <input matInput placeholder="Zip Code" formControlName="zipCode">
                                                <mat-error ngxErrors="firstFormGroup.zipCode">
                                                    Enter Zip Code
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>

                                    <div class="mb-10">
                                        <mat-form-field>
                                            <input matInput placeholder="Tax ID of your entity" formControlName="tax">
                                            <mat-error ngxErrors="firstFormGroup.tax">
                                                Enter Tax ID
                                            </mat-error>
                                        </mat-form-field>
                                        <div class="text-muted mb-30">
                                            <small>BwB needs your tax ID to offer you the
                                                most comprehensive triple S-Solution (Save, Send or Spend), apply the
                                                appropriate bilateral tax treaties on your global savings and protect
                                                all $BwBTags' cash.
                                            </small>
                                        </div>
                                    </div>

                                    <div class="mb-50">
                                        <p class="secondary-color">Optional</p>
                                        <mat-form-field>
                                            <input matInput
                                                placeholder="If applicable, SEC file number or Central Index Key Number of your entity"
                                                formControlName="key">
                                            <mat-error *ngIf="firstFormGroup.controls['key'].hasError('pattern')">
                                                SEC file number, Index Key Number must contain digits
                                            </mat-error>
                                            <mat-error
                                                *ngIf="!firstFormGroup.controls['key'].hasError('pattern') && firstFormGroup.controls['key'].hasError('minlength')">
                                                Maximum digits for SEC file number: 8 or 10. For Index Key Number: 8
                                            </mat-error>
                                            <mat-error
                                                *ngIf="firstFormGroup.controls['key'].hasError('keyIsForbidden')">
                                                Maximum digits for SEC file number: 8 or 10. For Index Key Number: 8
                                            </mat-error>
                                        </mat-form-field>
                                        <div class="text-muted">
                                            <small>The format for SEC file number are 8 or 10 digits. For Central Index
                                                Key Number are 10 digits.
                                            </small>
                                        </div>
                                    </div>

                                    <div fxLayout.xs="column" fxLayout="row wrap" fxLayoutGap.xs="10px"
                                        fxLayoutGap="20px" fxLayoutAlign="center center" class="mb-10">
                                        <button mat-raised-button color="primary" [disabled]="!firstFormGroup.valid"
                                            (click)="onSubmitStep1(firstFormGroup.value)">Next
                                            - Step 1.2
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step [stepControl]="secondFormGroup">
                            <form [formGroup]="secondFormGroup" (ngSubmit)="onSubmitStep1_2(secondFormGroup.value, 2)">
                                <ng-template matStepLabel>
                                    <strong>Step 1.2</strong><br> The characteristics that describe the entity
                                </ng-template>

                                <div class="container--sm">
                                    <h6 fxShow fxHide.gt-sm class="mb-30">Step 1.2 The characteristics that describe the
                                        entity</h6>
                                    <p class="primary-color lead mb-30"><strong>Select from the list the
                                            characteristics
                                            that best describe the entity for which you are opening a BwB
                                            account</strong>
                                    </p>
                                    <div class="mb-50">
                                        <mat-radio-group fxLayoutGap="20px" fxLayout="column" formControlName="options">
                                            <mat-radio-button color="primary" value="0"><strong>Insurance
                                                    Company</strong> as defined
                                                in
                                                Section 2(13) of the Securities Exchange Act of 1934 (the “Act”);
                                            </mat-radio-button>
                                            <mat-radio-button color="primary" value="1"><strong>Investment
                                                    Company</strong> registered
                                                under
                                                the Investment Company Act of 1940 (“1940 Act or a Business
                                                Development
                                                Company
                                                as defined in Section 2(a)(48) of the 1940 Act;
                                            </mat-radio-button>
                                            <mat-radio-button color="primary" value="2"><strong>Small Business
                                                    Investment
                                                    Company</strong>
                                                - licensed by the U.S. Small Business Administration under
                                                Section
                                                301(c)
                                                or (d) of the Small Business Investment Act of 1958 State
                                                Pension Plan
                                                –
                                                any plan established and maintained by a state, its political
                                                subdivisions,
                                                or any agency or instrumentality of a state or its political
                                                subdivisions
                                                for the benefit of employees;
                                            </mat-radio-button>
                                            <mat-radio-button color="primary" value="3"><strong>Employee
                                                    Benefit
                                                    Plan</strong> - any
                                                employee
                                                benefit plan within the meaning of title I of the Employee
                                                Retirement
                                                Income
                                                Security Act of 1974;
                                            </mat-radio-button>
                                            <mat-radio-button color="primary" value="4"><strong>Trust
                                                    Fund for a
                                                    Pension or Employee
                                                    Benefit
                                                    Plan</strong> - any trust fund whose trustee is a
                                                bank or trust
                                                company
                                                and whose participants are exclusively plans that are a
                                                “State Pension
                                                Plan”
                                                or an “Employee Pension Plan,” described above, except
                                                trust funds that
                                                include
                                                as participants individual retirement accounts or H.R.
                                                10 plans;
                                            </mat-radio-button>
                                            <mat-radio-button color="primary" value="5">
                                                <strong>Business Development
                                                    Company</strong>
                                                as defined in Section 202(a)(22) of the Investment
                                                Advisers Act of 1940;
                                            </mat-radio-button>
                                            <mat-radio-button color="primary" value="6">
                                                <strong>Charity</strong> - Any
                                                organization
                                                described in section 501(c)(3) of the Internal
                                                Revenue Code;
                                            </mat-radio-button>
                                            <mat-radio-button color="primary" value="7">
                                                <strong>Investment
                                                    Advisor</strong> registered
                                                under the Investment Advisers Act of 1940;
                                            </mat-radio-button>
                                            <mat-radio-button color="primary" value="8">
                                                <strong>Corporation;</strong>
                                            </mat-radio-button>
                                            <mat-radio-button color="primary" value="9">
                                                <strong>Partnership;</strong>
                                            </mat-radio-button>
                                            <mat-radio-button color="primary" value="10"><strong>Massachusetts
                                                    or
                                                    similar business
                                                    trust;</strong>
                                            </mat-radio-button>
                                            <mat-radio-button color="primary" value="11">
                                                <strong>U.S. Bank as defined
                                                    in section 3(a)(2)
                                                    of the Act;</strong>
                                            </mat-radio-button>
                                            <mat-radio-button color="primary" value="12"><strong>U.S.
                                                    Savings and Loan
                                                    or Other
                                                    Institution</strong>
                                                – as referenced in
                                                section 3(a)(5)(A) of
                                                the
                                                Act
                                            </mat-radio-button>
                                            <mat-radio-button color="primary" value="13">
                                                <strong>Non-U.S.
                                                    Bank or
                                                    Savings and Loan
                                                    or
                                                    Equivalent
                                                    Institution;
                                                </strong>
                                            </mat-radio-button>
                                            <mat-radio-button color="primary" value="14">
                                                <strong>A Global
                                                    Family
                                                    Office;</strong>
                                            </mat-radio-button>
                                            <mat-radio-button color="primary" value="15">
                                                <strong>A
                                                    Money
                                                    Service
                                                    Business;</strong>
                                            </mat-radio-button>
                                            <mat-radio-button color="primary" value="16">
                                                <strong>Other.</strong>
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                    <p class="primary-color lead mb-30"><strong>Will the entity be acting for</strong>
                                    </p>
                                    <mat-radio-group fxLayoutGap="20px" fxLayout="column" formControlName="addOptions"
                                        (change)="toggleAddOptions(secondFormGroup.controls['addOptions'].value)">
                                        <mat-radio-button color="primary" value="0"><strong>its own account;</strong>
                                        </mat-radio-button>
                                        <mat-radio-button color="primary" value="1"><strong>the account of its own
                                                owners and/or other
                                                individuals or entities; if so, please, provide the BwB electronic
                                                ID
                                                for
                                                each individual or entity concerned.</strong></mat-radio-button>
                                    </mat-radio-group>
                                    <div class="mb-50">
                                        <div fxLayout="row wrap" fxFlexFill>
                                            <mat-form-field fxFlex="grow" fxFlexOffset="26px">
                                                <input matInput placeholder="Email or phone"
                                                    formControlName="addPhoneOrEmail">
                                                <mat-error ngxErrors="secondFormGroup.addPhoneOrEmail">
                                                    Enter email or phone
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div fxLayout.xs="column" fxLayout="row wrap" fxLayoutGap="10px"
                                        fxLayoutAlign.xs="center" fxLayoutAlign="space-between center" class="mb-10">
                                        <button mat-stroked-button color="primary" type="button"
                                            (click)="onSubmitStep1_2(secondFormGroup.value, 0)">Back to Step 1.1
                                        </button>
                                        <button mat-raised-button color="primary" type="submit"
                                            [disabled]="!secondFormGroup.valid">Next - Step 1.3
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </mat-step>
                        <mat-step [stepControl]="thirdFormGroup">
                            <form [formGroup]="thirdFormGroup">
                                <ng-template matStepLabel>
                                    <strong>Step 1.3</strong><br> Legal information
                                </ng-template>
                                <div class="container--sm">
                                    <h6 fxShow fxHide.gt-sm class="mb-30">Step 1.3 Legal information</h6>
                                    <p class="primary-color lead mb-40"><strong>Indicate the amount of securities owned
                                            or invested on a discretionary basis other than securities of affiliates;
                                            bank
                                            deposit notes and certificates of deposit; loan participations; repurchase
                                            agreements; securities owned but subject to a repurchase agreement; and
                                            currency, interest rate and commodity swaps</strong>
                                    </p>
                                    <div class="mb-50">
                                        <mat-radio-group fxLayoutGap="20px" fxLayout="column"
                                            formControlName="options3">
                                            <mat-radio-button color="primary" value="1"><strong>Less than $10
                                                    million;</strong>
                                            </mat-radio-button>
                                            <mat-radio-button color="primary" value="2"><strong>More than $10
                                                    million,
                                                    but less than
                                                    $100 million;</strong>
                                            </mat-radio-button>
                                            <mat-radio-button color="primary" value="3"><strong>More than $100
                                                    million.</strong>
                                            </mat-radio-button>
                                        </mat-radio-group>
                                    </div>

                                    <div class="mb-50">
                                        <section fxLayoutGap="20px" fxLayout="column" fxFlexFill>
                                            <mat-checkbox color="primary" formControlName="addOptions1"><strong>The
                                                    Investment Company
                                                    is part of a “family of investment companies,” which, in the
                                                    aggregate
                                                    owns at least $100 million in securities of issuers, other than
                                                    issuers
                                                    that are affiliated with the Investment Company or are part of such
                                                    a
                                                    family of Investment Companies.
                                                </strong></mat-checkbox>
                                            <mat-checkbox color="primary" formControlName="addOptions2"><strong>It has
                                                    been at least 16
                                                    months that the entity has had ownership of at least $ 100 mm in
                                                    securities as described above.</strong></mat-checkbox>
                                            <mat-checkbox color="primary" formControlName="addOptions3"><strong>The
                                                    entity's audited net
                                                    worth exceed $ 25 mm.</strong>
                                            </mat-checkbox>
                                        </section>
                                    </div>
                                    <p class="mb-20"><strong>Load a copy of the latest audited financial statements not
                                            older than 16 months ago for US entities and 18 months for non-US
                                            entities</strong>
                                    </p>

                                    <app-loader-foto *ngIf="thirdFormGroup.controls['imageFile'].value"
                                        imageType="document" nameDoc="doc3"
                                        [imageFile]="thirdFormGroup.controls['imageFile'].value"
                                        (changeImageFile)="onChangeIndDoc($event)"></app-loader-foto>
                                    <app-loader-foto *ngIf="!thirdFormGroup.controls['imageFile'].value"
                                        imageType="document" nameDoc="doc3" (changeImageFile)="onChangeIndDoc($event)">
                                    </app-loader-foto>

                                    <div class="mb-50"></div>
                                    <div fxLayout.xs="column" fxLayout="row wrap" fxLayoutGap="10px"
                                        fxLayoutAlign.xs="center" fxLayoutAlign="space-between center" class="mb-10">
                                        <button mat-stroked-button color="primary"
                                            (click)="onSubmitStep1_3(thirdFormGroup.value, -1)">Back to Step 1.2
                                        </button>
                                        <button mat-raised-button color="primary"
                                            (click)="onSubmitStep1_3(thirdFormGroup.value, 1)">Next - Personal info
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </mat-step>
                    </mat-horizontal-stepper>
                </mat-step>
                <mat-step [stepControl]="fourthFormGroup">
                    <form [formGroup]="fourthFormGroup">
                        <ng-template matStepLabel>Personal info</ng-template>
                        <div class="container--sm pt-20">
                            <div class="mb-10">
                                <mat-form-field>
                                    <input matInput placeholder="First name " formControlName="indNameFirst">
                                    <mat-error ngxErrors="fourthFormGroup.indNameFirst">
                                        Enter First name
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="mb-10">
                                <mat-form-field>
                                    <input matInput placeholder="Last name " formControlName="indNameLast">
                                    <mat-error ngxErrors="fourthFormGroup.indNameLast">
                                        Enter Last name
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="mb-10">
                                <mat-form-field>
                                    <input matInput placeholder="Title " formControlName="indTitle">
                                    <mat-error ngxErrors="fourthFormGroup.indTitle">
                                        Enter title name
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="mb-10">
                                <datepicker-overview formControlName="indDateBirth"
                                    (changeDateBirth)="onChangeDateBirth($event)"
                                    [dataError]="fourthFormGroup.controls['indDateBirth'].hasError('required')">
                                </datepicker-overview>
                            </div>
                            <div class="mb-10">
                                <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px"
                                    fxLayoutGap.gt-xs="30px">
                                    <app-loader-foto *ngIf="fourthFormGroup.controls['indImageFile'].value"
                                        imageType="doc" nameDoc="doc4" (changeImageFile)="onChangeIndImage($event)"
                                        [imageFile]="fourthFormGroup.controls['indImageFile'].value"></app-loader-foto>
                                    <app-loader-foto *ngIf="!fourthFormGroup.controls['indImageFile'].value"
                                        imageType="doc" nameDoc="doc4" (changeImageFile)="onChangeIndImage($event)">
                                    </app-loader-foto>
                                    <div class="mb-20" fxFlex="grow">
                                        <p><strong>Tax ID 1 (where you reside)</strong></p>
                                        <p>Take a picture of your tax ID proof (e.g. SS card or driver's license) and
                                            load it here. If residing in the UAE, add EID card front and back scans.</p>
                                    </div>
                                </div>
                            </div>
                            <h5>Personal residence address</h5>
                            <div class="mb-10">
                                <mat-form-field>
                                    <input matInput placeholder="Street address" formControlName="indStreet">
                                    <mat-error ngxErrors="fourthFormGroup.indStreet">
                                        Enter Street address
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="mb-10">
                                <mat-form-field>
                                    <input matInput placeholder="City" formControlName="indCity">
                                    <mat-error ngxErrors="fourthFormGroup.indCity">
                                        Enter City
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="mb-20">
                                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexFill>
                                    <mat-form-field fxFlex>
                                        <mat-select placeholder="State" formControlName="indState">
                                            <mat-option *ngFor="let state of states" [value]="state.value">
                                                {{ state.value }}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error ngxErrors="fourthFormGroup.indState">
                                            Choose State
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field fxFlex="70px">
                                        <input matInput placeholder="Zip Code" formControlName="indZipCode">
                                        <mat-error ngxErrors="fourthFormGroup.indZipCode">
                                            Enter Code
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                            <p class="primary-color">
                                <mat-checkbox color="primary" formControlName="extraRepresentative"><strong>Name,
                                        function and electronic ID of an additional
                                        legal representative who will be joint BwB account in addition to <span
                                            style="white-space: nowrap;">you
                                            <span #popoverAncer (mouseleave)="closePopover($event)"
                                                (mouseenter)="openPopoverPanel('For BwB entity account, BwB needs a joint BwB account upon opening the account.', popoverAncer, $event)"
                                                class="cp  ml-4">
                                                <mat-icon class="light-gray-color va-b">info</mat-icon>
                                            </span>
                                        </span></strong>
                                </mat-checkbox>
                            </p>
                            <div class="mb-50">
                                <div class="mb-10">
                                    <div fxLayout="row wrap" fxFlexFill>
                                        <div fxFlex="grow" fxLayout="row wrap" class="mb-10">
                                            <mat-form-field fxFlexOffset="28px">
                                                <input matInput placeholder="First name "
                                                    [disableControl]="!fourthFormGroup.get('extraRepresentative').value"
                                                    formControlName="extraNameFirst">
                                                <mat-error ngxErrors="fourthFormGroup.extraNameFirst">
                                                    Enter first name
                                                </mat-error>
                                            </mat-form-field>
                                            <mat-form-field fxFlexOffset="28px">
                                                <input matInput placeholder="Last name "
                                                    [disableControl]="!fourthFormGroup.get('extraRepresentative').value"
                                                    formControlName="extraNameLast">
                                                <mat-error ngxErrors="fourthFormGroup.extraNameLast">
                                                    Enter last name
                                                </mat-error>
                                            </mat-form-field>
                                            <mat-form-field fxFlexOffset="28px">
                                                <input matInput placeholder="Function" formControlName="extraFunction"
                                                    [disableControl]="!fourthFormGroup.get('extraRepresentative').value">
                                                <mat-error ngxErrors="fourthFormGroup.extraFunction">
                                                    Enter Function
                                                </mat-error>
                                            </mat-form-field>
                                            <div fxFlexOffset="28px" style="width: 100%">
                                                <strong>Email or phone of the joint BwB saver<span #popoverAncer2
                                                        (mouseleave)="closePopover($event)"
                                                        (mouseenter)="openPopoverPanel('He(she) will receive a link to create or confirm the BwB saver joint account.', popoverAncer2, $event)"
                                                        class="cp  ml-4">
                                                        <mat-icon class="light-gray-color va-b">info</mat-icon>
                                                    </span></strong>

                                                <div class="mb-10">
                                                    <div fxLayout="row wrap" fxFlexFill>
                                                        <div fxFlex="grow" class="mb-10">
                                                            <mat-form-field>
                                                                <input matInput placeholder="Email or phone"
                                                                    [disableControl]="!fourthFormGroup.get('extraRepresentative').value"
                                                                    formControlName="extraPhoneOrEmail">
                                                                <mat-error
                                                                    ngxErrors="fourthFormGroup.extraPhoneOrEmail">
                                                                    Enter email or phone
                                                                </mat-error>
                                                            </mat-form-field>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div fxLayout.xs="column" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign.xs="center"
                                fxLayoutAlign="space-between center" class="mb-10">
                                <button mat-stroked-button color="primary"
                                    (click)="onSubmitStep2(fourthFormGroup.value, 0)">Back
                                    to Entity info
                                </button>
                                <button mat-raised-button [disabled]="!fourthFormGroup.valid" color="primary"
                                    (click)="onSubmitStep2(fourthFormGroup.value, 2)">Go
                                    to Financial info
                                </button>
                            </div>
                        </div>
                    </form>
                </mat-step>
                <mat-step [stepControl]="fifthFormGroup">
                    <form [formGroup]="fifthFormGroup">
                        <ng-template matStepLabel>Financial info</ng-template>
                        <div class="container--sm pt-50">
                            <div class="mb-10">
                                <mat-radio-group fxLayout="row" fxLayoutGap="20px" formControlName="isBank">
                                    <mat-radio-button color="primary" [value]="true">Specify your bank
                                    </mat-radio-button>
                                    <mat-radio-button color="primary" [value]="false">
                                        <div fxLayoutGap="5px" fxLayout="row"><span style="white-space: nowrap;">Let BwB
                                                create an account for
                                                you</span><span #popoverAncerBank (mouseleave)="closePopover($event)"
                                                (mouseenter)="openPopoverPanel('BwB create a set of such twin (cash and securities custody) accounts in your base currency', popoverAncerBank, $event)"
                                                class="cp">
                                                <mat-icon class="light-gray-color">info</mat-icon>
                                            </span>
                                        </div>

                                    </mat-radio-button>
                                </mat-radio-group>
                            </div>
                            <ng-container *ngIf="IsBank">
                                <div class="mb-50">
                                    <mat-form-field>
                                        <input matInput placeholder="Bank name" formControlName="bankName">
                                        <mat-error ngxErrors="fifthFormGroup.bankName">
                                            Enter Bank name
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <h5>Bank physical adress</h5>

                                <div class="mb-10">
                                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexFill>
                                        <mat-form-field>
                                            <input matInput placeholder="Street" formControlName="bankStreet">
                                            <mat-error ngxErrors="fifthFormGroup.bankStreet">
                                                Enter Street address
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field fxFlex="75px">
                                            <input matInput placeholder="Number" type="text"
                                                formControlName="bankStreetNumber">
                                            <mat-error ngxErrors="fifthFormGroup.bankStreetNumber">
                                                Enter Street Number
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="mb-10">
                                    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px" fxFlexFill>
                                        <mat-form-field>
                                            <input matInput placeholder="City" formControlName="bankCity">
                                            <mat-error ngxErrors="fifthFormGroup.bankCity">
                                                Enter City
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field fxFlex="75px">
                                            <input matInput placeholder="Post Code" type="text"
                                                formControlName="bankPostCode">
                                            <mat-error ngxErrors="fifthFormGroup.bankPostCode">
                                                Enter Post Code
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="mb-50">
                                    <mat-form-field>
                                        <mat-select placeholder="Country" formControlName="bankCountry">
                                            <mat-option *ngFor="let country of countries" [value]="country.name">
                                                {{ country.name }}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error ngxErrors="fifthFormGroup.bankCountry">
                                            Choose Country
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                                <div class="mb-10">
                                    <mat-form-field>
                                        <input matInput placeholder="IBAN cash account number"
                                            formControlName="bankIBANCash">
                                        <mat-error ngxErrors="fifthFormGroup.bankIBANCash">
                                            Enter IBAN cash account number
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="mb-50">
                                    <mat-form-field>
                                        <input matInput placeholder="IBAN securities custody account number"
                                            formControlName="bankIBANCustody">
                                        <mat-error ngxErrors="fifthFormGroup.bankIBANCustody">
                                            Enter IBAN securities custody account number
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </ng-container>
                            <mat-form-field>
                                <mat-select placeholder="What is your Base Currency" formControlName="currency">
                                    <mat-option *ngFor="let currency of currencies" [value]="currency">
                                        {{ currency }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="text-muted mb-40">
                                <small>
                                    Your Base Currency is the original currency associated with the means of payment
                                    specified above. For example, if you entered a US credit card info above, your Base
                                    Currency is the USD, that is the US Dollar.
                                </small>
                            </div>
                            <div fxLayout.xs="column" fxLayout="row wrap" fxLayoutGap="10px" fxLayoutAlign.xs="center"
                                fxLayoutAlign="space-between center" class="mb-10">
                                <button mat-stroked-button color="primary" (click)="onStep(1)">Back to Personal info
                                </button>
                                <button mat-raised-button color="primary"
                                    (click)="onSubmitStep3(fifthFormGroup.value)">Start Trading</button>
                            </div>
                        </div>
                    </form>
                </mat-step>
                <ng-template matStepperIcon="edit" let-index="index"> {{index + 1}}</ng-template>
            </mat-horizontal-stepper>
        </div>
    </div>
</div>