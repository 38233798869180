// eslint-disable @typescript-eslint/no-unsafe-member-access
// eslint-disable @typescript-eslint/no-unsafe-return
import { Component, OnInit, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackDialogComponent } from '../feedback-dialog/feedback-dialog.component';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
    selector: 'feedback-button',
    templateUrl: './feedback-button.component.html',
    styleUrls: ['./feedback-button.component.scss']
})
export class FeedbackButtonComponent implements OnInit {
    public isVisible = true;
    public get IsOnFooter(): boolean {
        const el = this.element.nativeElement as HTMLElement;
        const val = document.body.offsetHeight -
            (el.offsetHeight + window.pageYOffset + el.offsetTop);
        const footer = document.querySelector<HTMLElement>('app-footer');
        return footer && val < footer.offsetHeight;
    }
    constructor(
        private dialog: MatDialog,
        private router: Router,
        private element: ElementRef,
        private route: ActivatedRoute
    ) { }

    public openDialog() {
        this.dialog.open(FeedbackDialogComponent, {
            backdropClass: 'transparent-dialog',
            panelClass: 'feedback-dialog',
            width: '1000px',
            maxWidth: '100%'
        });
    }
    public ngOnInit() {
        this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd),
                map(() => this.route.snapshot['_routerState'].url),
                map((url: string) => url && url.includes('trade-blotter'))
            )
            .subscribe((isTradeBlotter) => {
                this.isVisible = !isTradeBlotter;
            });
    }

}
