<div class="container">
  <div fxFill
       fxLayout="row"
       fxLayoutAlign="space-between center">
    <input #input class="input-search" fxFlex placeholder="Search">
    <button fxFlex="40px" mat-icon-button color="primary" (click)="onToggleSearch()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
