import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../shared.service';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../../services/auth.service';


@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    public mobileQuery;

    public solutions = {
        title: 'Triple-S Solution',
        rows: [
            { name: 'Saving', url: '/trade-blotter' },
            { name: 'Spending', url: '/triples/spending' },
            { name: 'Sending', url: '/triples/sending' }
        ]
    };
    public friends = {
        title: 'Invite, Search, Scan Friends',
        rows: [
            { name: 'Invite', url: '/friends/invite' },
            { name: 'Search', url: '/friends/search' },
            { name: 'Scan', url: '/friends/scan' }
        ]
    };

    public news = {
        title: 'Global News',
        rows: [
            /*{ name: 'Top global economic news', url: '/news/topeconomic' },
            { name: 'Top global financial news', url: '/news/topfinancial' },
            { name: 'Top global political news', url: '/news/toppolitical' },*/
            { name: 'Most Read Global News', url: '/news/mostread' },
            { name: 'News By Country', url: '/news/country/us' }
        ]
    };

    public info = {
        title: '',
        rows: [
            { name: 'Get Help', url: '/pages/help' },
            { name: 'Contact Us', url: '/pages/under-construction/Contacts' },
            { name: 'Settings', url: '/settings' }
        ]
    };
    public learns = {
        title: 'Learn More',
        rows: [
            { name: 'Bonds 101', url: '/pages/under-construction/Bonds 101' },
            { name: 'What Other $BwBtags Think', url: environment.surveyResultsUrl, target: '_blank' },
            { name: 'What Governments Do with my Cash', url: '/pages/what-governments-do' },
            { name: 'Risks Warning', url: '/pages/risks-warning' }
        ]
    };

    public socials = {
        title: 'Get in touch',
        rows: [
            { alt: 'in', url: '#', src: 'assets/image/svg/social/in.svg' },
            { alt: 'f', url: '#', src: 'assets/image/svg/social/f.svg' },
            { alt: 'tw', url: '#', src: 'assets/image/svg/social/tw.svg' },
        ]
    };
    public year = new Date().getFullYear();

    public copyrighted = `Copyright © Bonds without Borders® ${this.year} - BwB, the platform for Bondcoins®.`;
    constructor(private sharedService: SharedService, public authService: AuthService) {
    }

    public ngOnInit() {
        this.mobileQuery = this.sharedService.mobileQuery;
    }
}
