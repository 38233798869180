import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { RouterOutletService } from './router-outlet.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CanActivateLoginGuard implements CanActivate {
    public returnUrl: string;

    constructor(
        private authService: AuthService,
        private router: Router,
        private routingState: RouterOutletService
    ) {
        this.returnUrl = this.routingState.getThisUrl();
    }

    public canActivate(
        _: ActivatedRouteSnapshot,
        _1: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.authService.loginState.pipe(map(v => {
            if (v) {
                if (
                    this.returnUrl === '/sign-up' ||
                    this.returnUrl === '/errors' ||
                    this.returnUrl === '/email-verified'
                ) {
                    this.returnUrl = '/home';
                }
                this.router.navigate([this.returnUrl]);
                return false;
            } else {
                return true;
            }
        }));
    }
}
