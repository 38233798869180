import { Injectable } from '@angular/core';
import { ReferenceData } from './ReferenceData';
import { of, Observable } from 'rxjs';
import { ReferenceDataModel } from './ReferenceDataModel';
import { RefDataService } from './ref-data.service';

@Injectable()
export class RefDataLookupService {
    public referenceData: ReferenceData = new ReferenceData();
    constructor(private client: RefDataService) {
        for (const key in ReferenceDataModel) {
            if (ReferenceDataModel.hasOwnProperty(key)) {
                this.client.getRefData(key).subscribe(data => this.referenceData[key].next(data));
            }
        }
    }
    public getDataSource(field: string): Observable<any> {
        const source = this.referenceData[field];
        if (!source) {
            // tslint:disable-next-line:no-console
            console.debug(`Datasource for key ${field} not found!`);
        }
        return source == null ? of([]) : source.asObservable();
    }
}
