import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';
import { SharedService } from '../../shared.service';
import { environment } from '../../../../../environments/environment';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { RefDataLookupService } from '../../../trade-blotter/services/ref-data-lookup.service';

@Component({
    selector: 'settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy {
    @ViewChild('containerOfRecapcha', { static: true }) public containerOfRecapcha: ElementRef;
    public recaptchaVerifier;
    public verificationCode: string;

    public isLoggedIn = false;
    public settingsFormGroup: FormGroup;
    public emailProviderFormGroup: FormGroup;
    public phoneProviderFormGroup: FormGroup;
    public addPhoneProviderFormGroup: FormGroup;

    public errorMessage = '';
    public isProd: boolean;

    public userTypeFormGroup: FormGroup;

    public avatarFormGroup: FormGroup;

    public userDetailsFormGroup: FormGroup;
    public oneClickSettingsFormGroup: FormGroup;
    public currencies: string[] = [];

    public editBwbTag = false;
    public editCurrency = false;
    private BASE_URL = 'api/singup/bwbTagExists';

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        public authService: AuthService,
        private sharedService: SharedService,
        private refDataService: RefDataLookupService,
        private http: HttpClient
    ) {
    }

    public ngOnInit() {
        this.refDataService.getDataSource('Currency').subscribe(currencies => this.currencies = currencies);
        this.authService.loginState.subscribe(v => (this.isLoggedIn = v));
        this.isProd = environment.production;

        this.userTypeFormGroup = this.formBuilder.group({
            userType: ['Individuals'],
        });

        this.avatarFormGroup = this.formBuilder.group({
            avatar: ['']
        });
        this.oneClickSettingsFormGroup = this.formBuilder.group({
            tripleSInOneClick: ['']
        });

        this.userDetailsFormGroup = this.formBuilder.group({
            showCountriesToFriends: [''],
            showSurveyResultsToFriends: [''],
            bwbTag: ['',
                [Validators.required, Validators.pattern(/^[A-z0-9]*$/), Validators.minLength(3)],
                this.forbiddenBwbTag.bind(this)],
            currency: ['']
        });


        this.settingsFormGroup = this.formBuilder.group({
            email: [''],
            phone: ['']
        });

        this.emailProviderFormGroup = this.formBuilder.group({
            email: [''],
            password: ['']
        });

        this.phoneProviderFormGroup = this.formBuilder.group({
            phone: ['']
        });

        this.addPhoneProviderFormGroup = this.formBuilder.group({
            code: ['']
        });

        this.recaptchaVerifier = new firebase.default.auth.RecaptchaVerifier(
            this.containerOfRecapcha.nativeElement
        );
        this.recaptchaVerifier.render();

        this.authService.loginState.subscribe(v => {
            this.isLoggedIn = v;

            if (v) {
                this.authService.getCurrentUser().subscribe(user => {
                    // console.log(user);
                    this.settingsFormGroup.controls['email'].setValue(user.email);
                    this.settingsFormGroup.controls['phone'].setValue(user.phoneNumber);
                    this.avatarFormGroup.controls['avatar'].setValue(user.photoUrl);
                });

                this.authService.getCurrentUserDetails().subscribe(userInfo => {
                    this.userDetailsFormGroup.controls['bwbTag'].setValue(userInfo.bwbTag);
                    this.userDetailsFormGroup.controls['currency'].setValue(userInfo.currency);
                    this.userDetailsFormGroup.controls['showCountriesToFriends'].setValue(userInfo.showCountriesToFriends);
                    this.userDetailsFormGroup.controls['showSurveyResultsToFriends'].setValue(userInfo.showSurveyResultsToFriends);
                    this.oneClickSettingsFormGroup.controls['tripleSInOneClick'].setValue(userInfo.tripleSInOneClick);
                });
            }
        });
    }

    public mainSpace() {
        return this.sharedService.mainSpace();
    }

    // temp
    public onChange(data) {
        const item = {
            userType: data.userType,
            bwbTag: data.bwbTag
        };
        this.authService.add(item);
    }

    // onChangeImage(item) {
    //     this.settingsFormGroup.controls['photoURL'].setValue(item);
    // }

    public onSubmit(_) {
        // console.log(item);
    }

    public onUpdatePhoneNumber() {
        this.authService.doUpdatePhoneNumber(this.settingsFormGroup.controls['phone'].value).then(
            res => {
                console.log(res);
            },
            err => {
                console.log(err);
            }
        );
    }

    public onUpdateEmail() {
        console.log(this.settingsFormGroup.controls['email'].value);

        this.authService.doUpdateEmail(this.settingsFormGroup.controls['email'].value).then(
            res => {
                console.log('1: ', res);
            },
            err => {
                console.log('2: ', err);
            }
        );
    }

    public onSubmitEmailProvider(data) {
        console.log(data);
        this.authService.addEmailProvvider(data);
    }

    public phoneE164(num) {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return `+${num}`;
    }

    public tryRegister(value) {
        const num = this.phoneE164(value.email);
        console.log('phone:', num);

        this.authService.sendLoginCode(num, this.recaptchaVerifier).then(
            res => {
                console.log(res);
            },
            err => {
                this.errorMessage = err.message;
                if (
                    err.code === 'auth/invalid-phone-number' ||
                    err.code === 'auth/too-many-requests'
                ) {
                    this.phoneProviderFormGroup.controls['phone'].setErrors({
                        'phone-error': true
                    });
                }
                console.log(err);
            }
        );
    }

    public onSubmitPhoneProvider(data) {
        console.log(data);
        this.authService.addPhoneProvider(data.code);
    }
    // ----------------------------------------------------------

    public onChangeUserType(item) {
        console.log(item);
        this.authService.addUserDetail(item);
    }

    public onChangeImage(item) {
        this.avatarFormGroup.controls['avatar'].setValue(item);
        const data = {
            photoURL: item,
        };
        this.authService.changeUserDetail(data);
    }

    public forbiddenBwbTag(control: FormControl): Promise<any> | Observable<any> {
        const promise = new Promise<any>((resolve, _) => {
            this.http
                .get(`${this.BASE_URL}?bwbTag=${control.value as string}`).subscribe(
                    (data) => {
                        if (data) {
                            // console.log('data-', data);
                            resolve({ 'bwbTagIsForbidden': true });
                        } else {
                            // console.log('data+', data);
                            resolve(null);
                        }
                    });
        });
        return promise;
    }

    public changeBwbTag() {
        this.changeUserDetail('bwbTag');
        this.onChangeBwbTag();
    }
    public changeCurrency() {
        this.changeUserDetail('currency');
        this.onChangeCurrency();
    }
    public changeTripleSInOneClick() {
        const detailUser = {
            tripleSInOneClick: this.oneClickSettingsFormGroup.controls['tripleSInOneClick'].value
        };
        this.authService.changeUserDetail(detailUser);
    }
    public changeUserDetail(key: string) {
        const detailUser = {
            [key]: this.userDetailsFormGroup.controls[key].value
        };
        this.authService.changeUserDetail(detailUser);
    }
    public onChangeBwbTag() {
        this.editBwbTag = !this.editBwbTag;
    }
    public onChangeCurrency() {
        this.editCurrency = !this.editCurrency;
    }

    // onDelete() {
    //     this.authService.deleteUser();
    // }

    public ngOnDestroy() {
        this.recaptchaVerifier.clear();
    }
}
