import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'feedback',
    templateUrl: './feedback.component.html',
    styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit {
    @Output() public readonly skip = new EventEmitter<void>();
    @Input() public showSkip = true;
    public feedbackUrl = environment.feedbackUrl;
    constructor() { }
    public ngOnInit() {
    }
}
