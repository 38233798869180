<div fxFlex="36px" fxFlex.gt-xs="136px">
    <div class="loaderFotoButton mat-elevation-z3" ngClass.gt-xs="loaderFotoButton-sm" (click)="onFileUpload()">
        <input type="file"
               #fileUpload
               (change)="onFileChange($event)"
               [(ngModel)]="imageFile2"
               accept="image/*">
        <input class="uk-input uk-form-width-medium"
               [(ngModel)]="imageFile"
               type="text" placeholder="Select file" disabled>
        <div *ngIf="fileToShow" class="loaderFotoButton_changeFile">
            <img *ngIf="fileToShow" [src]="fileToShow" alt="">
            <div class="loaderFotoButton_text">
                <div ngClass.gt-xs="mb-20" fxLayout="row" fxLayoutAlign="center center" >
                    <mat-icon ngClass.xs="md-18" ngClass.gt-xs="md-24">edit</mat-icon>
                    <div fxHide fxShow.gt-xs>Change</div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center" matTooltip="Delete" (click)="clearImageChoose()">
                    <mat-icon fxHide fxShow.gt-xs ngClass.xs="md-18" ngClass.gt-xs="md-24">delete</mat-icon>
                    <div fxHide fxShow.gt-xs>Delete</div>
                </div>
            </div>
        </div>
        <div *ngIf="!fileToShow" class="loaderFotoButton_notFile">
            <mat-icon ngClass.xs="md-16" ngClass.gt-xs="md-36 mb-10">camera_alt</mat-icon>
            <div fxHide fxShow.gt-xs>Add photo</div>
        </div>
    </div>

    <div *ngIf="fileToShow" fxShow fxHide.gt-xs (click)="clearImageChoose()" class="cp tc primary-color">
        Delete
    </div>

</div>
