// tslint:disable:variable-name

import { BehaviorSubject } from 'rxjs';
import { Country } from '../models/Country';
import { Bondcoin } from '../models/Bondcoin';

export class ReferenceData {
    public BondType: BehaviorSubject<string[]> = new BehaviorSubject([]);
    public Currency: BehaviorSubject<string[]> = new BehaviorSubject([]);
    public Rating: BehaviorSubject<string[]> = new BehaviorSubject([]);
    public Bondcoins: BehaviorSubject<Bondcoin[]> = new BehaviorSubject([]);
    public IssuerName: BehaviorSubject<string[]> = new BehaviorSubject([]);
    public CountryOfRisk: BehaviorSubject<Country[]> = new BehaviorSubject([]);
    constructor() {}
}
