import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class WindowService {

    constructor() {
    }

    public get windowRef() {
        return window;
    }
}
