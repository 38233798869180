<form #f="ngForm">
    <mat-form-field class="suffix-24" [class.mat-form-field-invalid]="dataError && focus && data.touched">
        <input type="hidden" [matDatepicker]="picker" [(ngModel)]='value'  name="data0">
        <input matInput placeholder="Date of Birth (MM/DD/YYYY)"
               [textMask]="{mask: mask, keepCharPositions: true, pipe: autoCorrectedDatePipe}"
               [ngModel]="value | date:'MM/dd/yyyy'"
               (click)="onClick()"
               (blur)="onBlur()"
               (change)='todate($event.target.value)'
               name="data"
               #data = "ngModel">
        <mat-datepicker-toggle matSuffix [for]="picker" class="primary-color"></mat-datepicker-toggle>
        <mat-datepicker (click)="onChange($event)" #picker></mat-datepicker>
        <mat-hint *ngIf="dataError && focus && data.touched" class="error">Enter Date of birth</mat-hint>
    </mat-form-field>
</form>

