import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { MatDialog } from '@angular/material/dialog';
import { SharedService } from '../../shared.service';
import { AuthService, Individuals } from '../../../../services/auth.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter, switchMap, map, take } from 'rxjs/operators';
import { STATES } from '../../models/State';
import { PopoverComponent } from '../popover-view/popover/popover.component';
import { PopoverViewService } from '../popover-view/popover-view.service';
import { PopoverOverlayRef } from '../popover-view/popover-view-ref';
import { Country } from '../../../trade-blotter/models/Country';
import { RefDataLookupService } from '../../../trade-blotter/services/ref-data-lookup.service';
import { StartTradingDialogComponent } from '../start-trading-dialog/start-trading-dialog.component';
import { AngularFireFunctions } from '@angular/fire/functions';

export function updateTreeValidity(group: FormGroup): void {
    Object.keys(group.controls).forEach((key: string) => {
        const abstractControl = group.controls[key];
        if (abstractControl instanceof FormGroup) {
            updateTreeValidity(abstractControl);
        } else {
            abstractControl.updateValueAndValidity({ onlySelf: true, emitEvent: false });
        }
    });
}
export function requiredIfValidator(predicate) {
    return (formControl => {
        if (!formControl.parent) {
            return null;
        }
        if (predicate()) {
            return Validators.required(formControl);
        }
        return null;
    });
}
@Component({
    selector: 'app-individuals',
    templateUrl: './individuals.component.html',
    styleUrls: ['./individuals.component.scss']
})
export class IndividualsComponent implements OnInit {
    @ViewChild('stepper', { static: true }) public stepper: MatStepper;

    public submitAddSaver: BehaviorSubject<boolean> = new BehaviorSubject(true);
    public states = STATES;
    public countries: Country[];
    public currencies: string[];

    public firstFormGroup: FormGroup;
    public secondFormGroup: FormGroup;

    public dialogRef: PopoverOverlayRef = null;
    public get IsBank() {
        return this.secondFormGroup && this.secondFormGroup.get('isBank').value;
    }
    private previewState = true;

    constructor(
        private formBuilder: FormBuilder,
        private sharedService: SharedService,
        private authService: AuthService,
        private router: Router,
        private refDataService: RefDataLookupService,
        private popoverService: PopoverViewService,
        private angularFireFunctions: AngularFireFunctions,
        private dialog: MatDialog
    ) { }

    public ngOnInit() {
        this.refDataService.getDataSource('Currency').subscribe(currencies => this.currencies = currencies);
        this.refDataService.getDataSource('CountryOfRisk')
            .subscribe(countries => this.countries = countries);
        this.firstFormGroup = this.formBuilder.group({
            name: ['', Validators.required],
            nameLast: ['', Validators.required],
            dateBirth: [null, Validators.required],
            citizenUS: true,
            state: ['', Validators.required],
            street: ['', Validators.required],
            city: ['', Validators.required],
            zipCode: ['', [Validators.required, Validators.minLength(4)]],
            addSaver: false,
            addPhoneOrEmail: ['', Validators.required],
            imageFile1: [''],
            imageFile1TaxId: [''],
            imageFile1Country: [''],
            imageFile2: [''],
            imageFile2TaxId: [''],
            imageFile2Country: [''],
            passport: [''],
        });
        this.secondFormGroup = this.formBuilder.group({
            isBank: [true],
            bankName: ['', [requiredIfValidator(() => this.secondFormGroup.get('isBank').value)]],
            bankStreet: ['', [requiredIfValidator(() => this.secondFormGroup.get('isBank').value)]],
            bankStreetNumber: ['', [requiredIfValidator(() => this.secondFormGroup.get('isBank').value)]],
            bankCity: ['', [requiredIfValidator(() => this.secondFormGroup.get('isBank').value)]],
            bankCountry: ['', [requiredIfValidator(() => this.secondFormGroup.get('isBank').value)]],
            bankPostCode: ['', [requiredIfValidator(() => this.secondFormGroup.get('isBank').value)]],
            bankIBANCash: ['', [requiredIfValidator(() => this.secondFormGroup.get('isBank').value)]],
            bankIBANCustody: ['', [requiredIfValidator(() => this.secondFormGroup.get('isBank').value)]],
            currency: [''],
            agreement: [false, Validators.requiredTrue]
        });

        this.authService.loginState
            .pipe(
                filter(v => v),
                switchMap(_ => this.authService.getCurrentUserDetails()),
                map(userInfo => userInfo as Individuals)
            )
            .subscribe(userInfo => {
                this.firstFormGroup.controls['name'].setValue(userInfo.name);
                this.firstFormGroup.controls['nameLast'].setValue(userInfo.nameLast);
                if (userInfo.dateBirth) {
                    this.firstFormGroup.controls['dateBirth'].setValue(userInfo.dateBirth.toDate());
                }
                this.firstFormGroup.controls['citizenUS'].setValue(userInfo.citizenUS);
                this.firstFormGroup.controls['state'].setValue(userInfo.state);
                this.firstFormGroup.controls['street'].setValue(userInfo.street);
                this.firstFormGroup.controls['city'].setValue(userInfo.city);
                this.firstFormGroup.controls['zipCode'].setValue(userInfo.zipCode);
                this.firstFormGroup.controls['addSaver'].setValue(userInfo.addSaver);
                this.firstFormGroup.controls['addPhoneOrEmail'].setValue(userInfo.addPhoneOrEmail);
                this.firstFormGroup.controls['imageFile1'].setValue(userInfo.imageFile1);
                this.firstFormGroup.controls['imageFile1TaxId'].setValue(userInfo.imageFile1TaxId);
                this.firstFormGroup.controls['imageFile1Country'].setValue(userInfo.imageFile1Country);
                this.firstFormGroup.controls['imageFile2'].setValue(userInfo.imageFile2);
                this.firstFormGroup.controls['imageFile2TaxId'].setValue(userInfo.imageFile2TaxId);
                this.firstFormGroup.controls['imageFile2Country'].setValue(userInfo.imageFile2Country);
                this.firstFormGroup.controls['passport'].setValue(userInfo.passport);

                this.secondFormGroup.controls['currency'].setValue(userInfo.currency);
                this.secondFormGroup.controls['isBank'].setValue(userInfo.isBank === undefined ? true : userInfo.isBank);
                if (userInfo.bankName) {
                    this.secondFormGroup.controls['bankName'].setValue(userInfo.bankName);
                }
                if (userInfo.bankStreet) {
                    this.secondFormGroup.controls['bankStreet'].setValue(userInfo.bankStreet);
                }
                if (userInfo.bankStreetNumber) {
                    this.secondFormGroup.controls['bankStreetNumber'].setValue(userInfo.bankStreetNumber);
                }
                if (userInfo.bankCity) {
                    this.secondFormGroup.controls['bankCity'].setValue(userInfo.bankCity);
                }
                if (userInfo.bankCountry) {
                    this.secondFormGroup.controls['bankCountry'].setValue(userInfo.bankCountry);
                }
                if (userInfo.bankPostCode) {
                    this.secondFormGroup.controls['bankPostCode'].setValue(userInfo.bankPostCode);
                }
                if (userInfo.bankIBANCash) {
                    this.secondFormGroup.controls['bankIBANCash'].setValue(userInfo.bankIBANCash);
                }
                if (userInfo.bankIBANCustody) {
                    this.secondFormGroup.controls['bankIBANCustody'].setValue(userInfo.bankIBANCustody);
                }
                this.previewState = userInfo.firstPreview;
                this.submitAddSaver.next(!userInfo.addSaver);
            });
        this.submitAddSaver.subscribe(v => {
            if (v) {
                this.firstFormGroup.controls['addPhoneOrEmail'].disable();
            } else {
                this.firstFormGroup.controls['addPhoneOrEmail'].enable();
            }
        });
        this.secondFormGroup.get('isBank').valueChanges
            .subscribe(_ => {
                updateTreeValidity(this.secondFormGroup);
            });
    }
    public onStep(item) {
        this.sharedService.scrollToTop();
        this.stepper.selectedIndex = item;
    }

    public addDetail(data) {
        this.onStep(1);
        const detail = {
            name: data.name,
            nameLast: data.nameLast,
            dateBirth: data.dateBirth,
            citizenUS: data.citizenUS,
            state: data.state,
            street: data.street,
            city: data.city,
            zipCode: data.zipCode,
            addSaver: data.addSaver,
            addPhoneOrEmail: data.addPhoneOrEmail || '',
            imageFile1: data.imageFile1 || '',
            imageFile1TaxId: data.imageFile1TaxId || '',
            imageFile1Country: data.imageFile1Country || '',
            imageFile2: data.imageFile2 || '',
            imageFile2TaxId: data.imageFile2TaxId || '',
            imageFile2Country: data.imageFile2Country || '',
            passport: data.passport || '',
            partialAccess: true
        };
        this.authService.changeUserDetail(detail);
    }

    public onChangeImage1(item) {
        this.firstFormGroup.controls['imageFile1'].setValue(item);
    }

    public onChangeImage2(item) {
        this.firstFormGroup.controls['imageFile2'].setValue(item);
    }
    public onChangePassport(item) {
        this.firstFormGroup.controls['passport'].setValue(item);
    }

    public onChangeDateBirth(item) {
        this.firstFormGroup.controls['dateBirth'].setValue(item);
    }

    public onSubmitStep2(data: any) {
        if (!this.previewState) {
            const dialogRef = this.dialog.open(StartTradingDialogComponent, { width: '700px', disableClose: true });
            dialogRef.afterClosed().pipe(take(1)).subscribe(result => {
                if (result) {
                    this.submitUserData(data);
                }
            });
        } else {
            this.submitUserData(data);
        }
    }

    public toggleAddSaver(i) {
        this.submitAddSaver.next(!i);
    }

    public openPopoverPanel(data, popoverAnker, _) {
        if (!this.dialogRef) {
            this.dialogRef = this.popoverService.openPopoverPanel(popoverAnker,
                {
                    text: data
                }, PopoverComponent);
        }
    }

    public closePopover(_) {
        if (this.dialogRef) {
            this.dialogRef.close();
            this.dialogRef = null;
        }
    }
    private submitUserData(data: any) {
        const detail = {
            firstPreview: true,
            currency: data.currency,
            isBank: data.isBank,
            bankName: data.bankName,
            bankStreet: data.bankStreet,
            bankStreetNumber: data.bankStreetNumber,
            bankCity: data.bankCity,
            bankCountry: data.bankCountry,
            bankPostCode: data.bankPostCode,
            bankIBANCash: data.bankIBANCash,
            bankIBANCustody: data.bankIBANCustody,
            partialAccess: true
        };
        this.authService.changeUserDetail(detail);
        this.router.navigate(['/trade-blotter']);
        const userUid = this.authService.currentUser?.value?.uid;
        if (userUid) {
            const func = this.angularFireFunctions.httpsCallable('sendNotificationAboutUserPassport');
            func({ userId: this.authService.currentUser.value.uid }).subscribe(r => console.log(r));
        }
    }
    private validateIfIsBank = (_1: FormGroup) => {
        return (_: AbstractControl): { [key: string]: any } | null => {
            const isBank = this.secondFormGroup.get('isBank').value;
            return isBank ? { isRequired: true } : null;
        };
    };
}
