import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class RepositoryService {
    constructor(private http: HttpClient) {}

    public getData(route: string, urlAddress: string) {
        return this.http.get(this.createCompleteRoute(route, urlAddress));
    }

    private createCompleteRoute(route: string, envAddress: string) {
        return `${envAddress}${route}`;
    }
}
