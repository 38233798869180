import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'start-trading-dialog',
    templateUrl: './start-trading-dialog.component.html',
    styleUrls: ['./start-trading-dialog.component.scss']
})
export class StartTradingDialogComponent implements OnInit {
    constructor() { }

    public ngOnInit() {
    }

}
