import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    EventEmitter,
    Output,
    ViewChildren
} from '@angular/core';

@Component({
    selector: 'app-header-search',
    templateUrl: './header-search.component.html',
    styleUrls: ['./header-search.component.scss']
})
export class HeaderSearchComponent implements AfterViewInit, AfterViewChecked {
    @Output() public readonly toggleSearch: EventEmitter<any> = new EventEmitter();
    @ViewChildren('input') public vc;

    constructor() { }

    public ngAfterViewInit() {
        this.vc.first.nativeElement.focus();
    }

    public onToggleSearch() {
        this.toggleSearch.emit();
    }

    public ngAfterViewChecked(): void { }
}
