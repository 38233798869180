<div class="container" [ngClass]="mainSpace()">
    <div class="container--md">
        <div class="tc">
            <h1>Sign Up</h1>
            <p class="lead mb-50">Already have an account?
                <strong><a routerLink="/log-in" class="primary-color">Log In</a></strong>
            </p>
        </div>

        <mat-horizontal-stepper #stepper class="mat-stepper-horizontal--lg" ngClass.gt-sm="howSteps">

            <!-- 1 step ----------------------------------------------------------------------------->

            <mat-step [stepControl]="firstFormGroup">
                <form [formGroup]="firstFormGroup" (ngSubmit)="tryRegister(firstFormGroup.value)">
                    <ng-template matStepLabel>Create account</ng-template>
                    <div class="container--xs">
                        <div class="mb-10"></div>
                        <div class="mb-10">
                            <mat-radio-group fxLayoutGap="30px" fxLayout="row wrap" formControlName="userType">
                                <mat-radio-button color="primary" value="Individuals">Individuals</mat-radio-button>
                                <mat-radio-button color="primary" value="Institutions">Institutions </mat-radio-button>
                            </mat-radio-group>
                        </div>

                        <div class="mb-10">
                            <mat-form-field>
                                <input matInput placeholder="First Name" formControlName="firstName">
                                <mat-error *ngIf="firstFormGroup.controls['firstName'].hasError('required')">
                                    Enter your first name
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="mb-10">
                            <mat-form-field>
                                <input matInput placeholder="Last Name" formControlName="lastName">
                                <mat-error *ngIf="firstFormGroup.controls['lastName'].hasError('required')">
                                    Enter your last name
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="mb-10">
                            <mat-form-field>
                                <input matInput placeholder="Email or phone (+112345678901)" formControlName="email"
                                    (keydown)="onValueChange()">
                                <mat-error *ngIf="firstFormGroup.controls['email'].hasError('required')">
                                    Enter email or phone number
                                </mat-error>
                                <mat-error *ngIf="firstFormGroup.controls['email'].hasError('email')">
                                    Enter email or phone number
                                </mat-error>
                                <mat-error *ngIf="firstFormGroup.controls['email'].hasError('email-error')">
                                    {{errorMessage}}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div #containerOfRecapcha class="mb-10"></div>
                        <p class="secondary-color"><small>Optional</small></p>
                        <div class="mb-10">
                            <mat-form-field>
                                <input matInput placeholder="Create $BwBTag" type="text" formControlName="bwbTag">
                                <mat-error *ngIf="firstFormGroup.controls['bwbTag'].hasError('pattern')">
                                    $BwBTag must contain letters and numbers
                                </mat-error>
                                <mat-error
                                    *ngIf="!firstFormGroup.controls['bwbTag'].hasError('pattern') && firstFormGroup.controls['bwbTag'].hasError('minlength')">
                                    Enter $BwBTag with combination of at least 3 characters
                                </mat-error>
                                <mat-error *ngIf="firstFormGroup.controls['bwbTag'].hasError('bwbTagIsForbidden')">
                                    This $BwBTag is already existed
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="mb-10" *ngIf="!isPhone">
                            <mat-form-field>
                                <input matInput placeholder="Create password" [type]="hide ? 'password' : 'text'"
                                    formControlName="password">
                                <mat-icon matSuffix color="primary" (click)="hide = !hide" class="cp">{{hide ?
                                    'visibility'
                                    : 'visibility_off'}}
                                </mat-icon>
                                <mat-error ngxErrors="firstFormGroup.password">
                                    Enter a combination of at least 6 numbers and letters
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="mb-20"></div>
                        <div class="mb-20">
                            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="20px" fxLayoutGap.gt-xs="30px">
                                <prelooder-photo (changeImageFile)="onChangeImage($event)"></prelooder-photo>
                                <div class="mb-20" fxFlex="grow">
                                    <p><strong>Take a picture of yourself</strong></p>
                                    <p>We use it for extra biometric ID check. It helps keep BwB safe and
                                        sound.</p>
                                </div>
                            </div>
                        </div>
                        <div class="mb-20">
                            <mat-form-field>
                                <mat-label>Base currency</mat-label>
                                <mat-select formControlName="currency">
                                    <mat-option *ngFor="let curr of currencies" [value]="curr">
                                        {{curr}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="mb-50">
                            <mat-checkbox color="primary" formControlName="risksWarning">
                                I accept the <a class="primary-color" target="_blank" href="/pages/risks-warning">Risks
                                    Warning</a>
                            </mat-checkbox>
                        </div>
                        <div class="tc mb-10">
                            <button mat-raised-button color="primary" type="submit"
                                [disabled]="!firstFormGroup.valid || !(recaptchaValid | async)">
                                Create account
                            </button>
                        </div>
                    </div>
                </form>
            </mat-step>

            <!-- 2 step ----------------------------------------------------------------------------->

            <mat-step [stepControl]="codeForm">
                <form *ngIf="isPhone" [formGroup]="codeForm" (ngSubmit)="onVerifyLoginCodeCreateUser(codeForm.value)">
                    <ng-template matStepLabel>Verify electronic ID</ng-template>
                    <div class="container--xs">
                        <p class="tc text-muted mb-30">The code was sent to the number
                            {{firstFormGroup.controls['email'].value}} </p>
                        <mat-form-field class="mb-40">
                            <input formControlName="code" matInput placeholder="Code">
                            <mat-error
                                *ngIf="codeForm.controls['code'].hasError('required') || codeForm.controls['code'].hasError('minlength')">
                                Enter code
                            </mat-error>
                            <mat-error *ngIf="codeForm.controls['code'].hasError('code-error')">
                                {{errorMessage}}
                            </mat-error>
                            <mat-hint>
                                In a few seconds you will receive a unique code to your chosen identifier (email or
                                phone number).
                            </mat-hint>
                        </mat-form-field>
                        <div class="tc mb-30">
                            <button mat-raised-button color="primary" type="submit" [disabled]="!codeForm.valid">
                                Activate account
                            </button>
                        </div>
                        <div class="tc mb-20">
                            <a class="primary-color" (click)="onToggleShowCode()">
                                <strong>Back and try again</strong>
                            </a>
                        </div>
                    </div>
                </form>
                <ng-container *ngIf="!isPhone">
                    <ng-template matStepLabel>Verify electronic ID</ng-template>
                    <div class="container--xs">
                        <p class="mb-30">Please confirm your email address by clicking the link which we
                            send to <strong>{{firstFormGroup.controls['email'].value}}</strong> </p>
                        <p class="mb-30">If you have not received the confirmation email, check spam folder or send
                            letter again</p>
                        <div class="tc mb-20">
                            <button mat-stroked-button color="primary" class="mb-20" (click)="onSendRequestAgain()">
                                Send request again
                            </button>
                            <p class="success-color" *ngIf="verificationEmailSent">Confirmation email sent</p>
                        </div>
                    </div>
                </ng-container>

            </mat-step>
            <ng-template matStepperIcon="edit" let-index="index"> {{index + 1}}</ng-template>
        </mat-horizontal-stepper>
    </div>
</div>