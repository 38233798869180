import { ApplicationRef, Injectable } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { WindowService } from '../../services/window.service';

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    public mobileQuery: MediaQueryList;
    public mobileQueryListener: () => void;

    constructor(media: MediaMatcher, private ref: ApplicationRef, private win: WindowService) {
        this.mobileQuery = media.matchMedia('(max-width: 959px)');
        this.mobileQueryListener = () => this.ref.tick();
        this.mobileQuery.addEventListener('change', this.mobileQueryListener);
    }

    public scrollToTop() {
        this.win.windowRef.scrollTo(0, 0);
    }

    public bottomSpace() {
        return this.mobileQuery.matches ? 'mb-40' : 'mb-60';
    }

    public mainSpace() {
        return this.mobileQuery.matches ? 'pt-40 mb-40' : 'pt-80 mb-80';
    }
}
