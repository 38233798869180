import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RouterOutletService {
    private history: string[] = [];

    constructor(private router: Router) { }

    public loadRouting(): void {
        this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
                this.history = [...this.history, urlAfterRedirects];
            });
    }

    public getHistory(): string[] {
        return this.history;
    }

    public getPreviousUrl(): string {
        return this.history[this.history.length - 2] || '/home';
    }

    public getThisUrl(): string {
        return this.history[this.history.length - 1] || '/home';
    }
    public addToHistory(url: string) {
        this.history = [...this.history, url];
    }
}
