import { ComponentRef, ElementRef, Injectable, Injector } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { PopoverOverlayRef } from './popover-view-ref';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { POPOVER_VIEW_DATA } from './popover-view.token';

@Injectable({
    providedIn: 'root'
})
export class PopoverViewService {

    constructor(private overlay: Overlay,
        private injector: Injector) {
    }

    public openPopoverPanel(elementConnect: ElementRef, filterData: any, componentContainer: any): PopoverOverlayRef {
        const strategy = this.overlay.position()
            .flexibleConnectedTo(elementConnect)
            .withPositions([
                {
                    overlayX: 'start',
                    overlayY: 'top',
                    originX: 'start',
                    originY: 'bottom',
                    offsetY: 0
                },
                {
                    originX: 'start',
                    originY: 'top',
                    overlayX: 'start',
                    overlayY: 'bottom',
                    offsetY: 0
                },
                {
                    originX: 'start',
                    originY: 'bottom',
                    overlayX: 'start',
                    overlayY: 'top'
                }
            ]);

        const config = new OverlayConfig({
            hasBackdrop: false,
            backdropClass: 'cdk-overlay-transparent-backdrop',
            positionStrategy: strategy,
            scrollStrategy: this.overlay.scrollStrategies.close()
        });

        const overlayRef = this.overlay.create(config);

        const dialogRef = new PopoverOverlayRef(overlayRef);

        const overlayComponent = this.attachDialogContainer(overlayRef, filterData, dialogRef, componentContainer);

        dialogRef.componentInstance = overlayComponent;

        overlayRef.backdropClick().subscribe(_ => dialogRef.close());

        return dialogRef;
    }

    private attachDialogContainer(overlayRef: OverlayRef, config: any, dialogRef: PopoverOverlayRef, componentContainer: any) {
        const injector = this.createInjector(config, dialogRef);

        const containerPortal = new ComponentPortal(componentContainer, null, injector);

        const containerRef: ComponentRef<any> = overlayRef.attach(containerPortal);

        return containerRef.instance;
    }

    private createInjector(config: any, dialogRef: PopoverOverlayRef): PortalInjector {
        const injectionTokens = new WeakMap();

        injectionTokens.set(PopoverOverlayRef, dialogRef);
        injectionTokens.set(POPOVER_VIEW_DATA, config);

        return new PortalInjector(this.injector, injectionTokens);
    }
}
