import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';
import { RouterOutletService } from './router-outlet.service';

@Injectable({
    providedIn: 'root'
})
export class IsUserAdminGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router, private routerOutletService: RouterOutletService) { }
    public canActivate(
        _: ActivatedRouteSnapshot,
        route: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return combineLatest([this.authService.adminState, this.authService.loginState])
            .pipe(
                map(([isAdmin, loginState]) => {
                    if (loginState) {
                        if (isAdmin) {
                            return true;
                        } else {
                            this.routerOutletService.addToHistory(route.url);
                            void this.router.navigate(['/errors']);
                        }
                    } else {
                        this.routerOutletService.addToHistory(route.url);
                        void this.router.navigate(['/log-in']);
                    }
                })
            );
    }
}
