import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

@Component({
    selector: 'prelooder-photo',
    templateUrl: './prelooder-photo.component.html',
    styleUrls: ['./prelooder-photo.component.scss']
})
export class PrelooderPhotoComponent implements OnInit {
    @ViewChild('fileUpload') public fileInput: ElementRef;

    @Output() public readonly changeImageFile = new EventEmitter<any>();

    public fileToShow: string;
    public file: any = null;
    public imageFile2 = '';
    public imageFile = '';

    constructor() { }

    public ngOnInit() { }

    public onFileUpload() {
        this.fileInput.nativeElement.click();
    }

    public onFileChange(e) {
        const upFile = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

        if (!upFile) {
            return;
        }

        const pattern = /image-*/;
        const reader = new FileReader();

        if (!upFile.type.match(pattern)) {
            alert('invalid format');
            this.clearImageChoose();
            return;
        }

        if (upFile.size / 1024 / 1024 > 5) {
            alert('invalid size, image should not be more then 5Mb');
            this.clearImageChoose();
            return;
        }

        reader.onload = this.handleReaderLoaded.bind(this);

        reader.readAsDataURL(upFile);
        this.file = upFile;
        this.changeImageFile.emit(upFile);
        console.log('1', upFile);
    }

    public handleReaderLoaded(e) {
        const reader = e.target;

        const image = new Image();
        image.addEventListener('load', () => {
            // console.log('imageSize' + image.width + '×' + image.height);

            // if (image.width > 300 || image.height > 300) {
            //     alert('invalid size, image should not be more then 300x300');
            //     this.clearImageChoose();
            // } else {
            //     this.fileToShow = reader.result;
            // }
            this.fileToShow = reader.result;
        });

        image.src = reader.result;
    }

    public clearImageChoose() {
        this.imageFile = '';
        this.imageFile2 = '';
        this.fileToShow = '';
    }
}
