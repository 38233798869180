<div *ngIf="imageType === 'doc' || imageType === 'avatar'" fxFlex="36px" fxFlex.gt-xs="136px" class="mb-20">
    <div class="loaderFotoButton mat-elevation-z3" ngClass.gt-xs="loaderFotoButton-sm" (click)="onFileUpload()">
        <input type="file" #fileUpload (change)="upload($event)" accept="image/*">
        <input class="uk-input uk-form-width-medium" [(ngModel)]="imageFile" type="text" placeholder="Select file"
            disabled>
        <div *ngIf="downloadURL" class="loaderFotoButton_changeFile">
            <img *ngIf="downloadURL; let downloadSrc" [src]="downloadSrc" alt="">
            <div class="loaderFotoButton_text">
                <div ngClass.gt-xs="mb-20" fxLayout="row" fxLayoutAlign="center center">
                    <mat-icon ngClass.xs="md-18" ngClass.gt-xs="md-24">edit</mat-icon>
                    <div fxHide fxShow.gt-xs>Change</div>
                </div>
                <div fxLayout="row" fxLayoutAlign="center center" matTooltip="Delete" (click)="deleteDoc()">
                    <mat-icon fxHide fxShow.gt-xs ngClass.xs="md-18" ngClass.gt-xs="md-24">delete</mat-icon>
                    <div fxHide fxShow.gt-xs>Delete</div>
                </div>
            </div>
        </div>
        <div *ngIf="!downloadURL" class="loaderFotoButton_notFile">
            <mat-icon ngClass.xs="md-16" ngClass.gt-xs="md-36 mb-10">camera_alt</mat-icon>
            <div fxHide fxShow.gt-xs>Add photo</div>
        </div>
    </div>

    <div *ngIf="downloadURL" fxShow fxHide.gt-xs (click)="deleteDoc()" class="cp tc primary-color">
        Delete
    </div>
</div>

<div *ngIf="imageType === 'document'">
    <div class="mat-elevation-z2 loaderDocButton mb-10" (click)="onDocUpload()">
        <input type="file" #fileUpload (change)="upload($event)" accept="image/*">
        <input [(ngModel)]="imageFile" type="text" placeholder="Select file" disabled>
        <div *ngIf="downloadURL" fxLayout="row" fxLayoutAlign="space-between center" fxFlexFill>
            <a [href]="downloadURL" download="downloadURL" target="_blank" fxFlex="grow" class="loaderlink primary-color tc fwb">Download</a>
            <mat-icon class="primary-color mr-20" matTooltip="Delete" (click)="deleteDoc()">close</mat-icon>
        </div>
        <div *ngIf="!downloadURL" fxLayout="row" fxLayoutAlign="center center" fxFlexFill class="loaderBlock">
            <mat-icon class="primary-color">attach_file</mat-icon>
            <strong class="primary-color">Attached file</strong>
        </div>
    </div>
    <p class="text-muted"><small>File format: jpeg, png, pdf. </small></p>
</div>
