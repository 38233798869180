import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class CanActivateNotAllowedGuard implements CanActivate {
    constructor(private authService: AuthService, private router: Router) { }
    public canActivate(
        _: ActivatedRouteSnapshot,
        _1: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.authService.approvedState
            .pipe(
                map(approved => {
                    if (approved) {
                        this.router.navigate(['/home']);
                    }
                    return true;
                })
            );
    }
}
