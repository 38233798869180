import { ChangeDetectionStrategy, Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { POPOVER_VIEW_DATA } from '../popover-view.token';
import { PopoverOverlayRef } from '../popover-view-ref';


@Component({
    selector: 'app-popover',
    templateUrl: './popover.component.html',
    styleUrls: ['./popover.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PopoverComponent implements OnInit, OnDestroy {

    private destroy$ = new Subject<any>();
    constructor(@Inject(POPOVER_VIEW_DATA) public data: { text: string }, public dialogRef: PopoverOverlayRef) {
    }
    public ngOnInit() {
    }

    public ngOnDestroy(): void {
        this.destroy$.next();
    }
    @HostListener('mouseleave', ['$event']) private handleKeyDown(_: KeyboardEvent) {
        this.dialogRef.close();
    }


}
